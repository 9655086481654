import RecordType from '@sqs/enums/RecordType';
import { ACTION, ACTOR, OBJECT, PAGES } from './constants';
import CMSV7Events from '.';
import WorkflowStates from '@sqs/enums/WorkflowStates';

const RECORD_TYPE_TO_ITEM_TYPENAME: Record<number, string> = {
  [RecordType.AUDIO]: 'album-track',
  [RecordType.EVENT]: 'event-item',
  [RecordType.IMAGE]: 'gallery-image',
  [RecordType.PORTFOLIO_ITEM]: 'portfolio-item',
  [RecordType.TEXT]: 'blog-post',
  [RecordType.VIDEO]: 'gallery-video',
};

interface ContentItem {
  id: string;
  recordType: RecordType;
}

const contentItemIndirectObject = (item: ContentItem) => ({
  indirect_object_type: 'contentitem',
  indirect_object_id: item.id,
  indirect_object_identifier: RECORD_TYPE_TO_ITEM_TYPENAME[item.recordType],
});

const COLLECTION_EDITOR = 'collection-editor';

const userClickButton = {
  actor: ACTOR.USER,
  action: ACTION.CLICK,
  object_type: OBJECT.BUTTON,
};
const userClickButtonEditor = {
  ...userClickButton,
  product_page: COLLECTION_EDITOR,
};

/** Collection Items */

export const editCollectionItem = ({
  itemId,
  collectionType,
  collectionId,
}: Record<string, any>) => {
  CMSV7Events.track({
    ...userClickButtonEditor,
    object_identifier: 'edit-contentitem',
    object_id: itemId,
    indirect_object_identifier: collectionType,
    indirect_object_id: collectionId,
    product_section: 'panel',
  });
};

export const saveCollectionItem = ({
  itemId,
  collectionType,
  collectionId,
  hasThumbnail
}: Record<string, any>) => {

  // For now, we're only saving conclusive thumbnail values for Blogs.
  //
  // Blogs have the new "explicit thumbnail only" logic we added in CMS-41566, which we'll be rolling
  // out to other relevant Collections. Having an 'undefined' value alllows us to track only the true
  // and false values that match the final logic.
  let object_value = 'thumbnail-image-undefined';
  if (collectionType.includes('blog') && (typeof hasThumbnail === 'boolean')) {
    object_value = `thumbnail-image-${hasThumbnail}`;
  }

  CMSV7Events.track({
    ...userClickButtonEditor,
    object_identifier: 'save-item-edit',
    object_id: itemId,
    event_name: 'UserSavesCollectionSettings',
    object_value,
    indirect_object_identifier: collectionType,
    indirect_object_id: collectionId,
  });
};

export const cancelEditCollectionItem = ({
  itemId,
  collectionType,
  collectionId,
}: Record<string, any>) => {
  CMSV7Events.track({
    ...userClickButtonEditor,
    object_identifier: 'cancel-item-edit',
    object_id: itemId,
    indirect_object_identifier: collectionType,
    indirect_object_id: collectionId,
  });
};

export const duplicateCollectionItem = ({
  itemId,
  collectionType,
  collectionId,
}: Record<string, any>) => {
  CMSV7Events.track({
    ...userClickButtonEditor,
    object_identifier: 'duplicate-item',
    object_id: itemId,
    indirect_object_identifier: collectionType,
    indirect_object_id: collectionId,
  });
};

export const _deleteCollectionItem = ({
  itemId,
  collectionType,
  collectionId,
  area,
}: Record<string, any>) => {
  CMSV7Events.track({
    actor: ACTOR.USER,
    action: ACTION.DELETE,
    object_type: 'contentitem',
    object_id: itemId,
    indirect_object_identifier: collectionType,
    indirect_object_id: collectionId,
    product_page: COLLECTION_EDITOR,
    product_section: area,
  });
};

export const changePageEnabled = ({
  collectionType,
  collectionId,
  isEnabled
}: Record<string, any>) => {
  CMSV7Events.track({
    actor: ACTOR.USER,
    action: ACTION.UPDATE,
    object_type: 'toggle',
    object_identifier: 'collection-settings',
    object_display_name: 'enable_page',
    object_value: isEnabled ? 'enabled' : 'disabled',
    indirect_object_identifier: collectionType,
    indirect_object_id: collectionId,
    event_name: 'UserUpdatesCollectionSettingsToggle',
  });
};

export const changeItemVisibility = ({
  itemId,
  itemType,
  collectionType,
  collectionId,
  visibilityState
}: Record<string, any>) => {

  let workflowState;
  switch (visibilityState) {
  case WorkflowStates.DRAFT:
    workflowState = 'draft';
    break;
  case WorkflowStates.PUBLISHED:
    workflowState = 'published';
    break;
  case WorkflowStates.NEEDS_REVIEW:
    workflowState = 'needs_review';
    break;
  case WorkflowStates.SCHEDULED:
    workflowState = 'scheduled';
    break;
  default:
    return;
  }

  CMSV7Events.track({
    actor: ACTOR.USER,
    action: ACTION.CHANGE_VISIBILITY,
    object_type: 'contentitem',
    object_identifier: itemType,
    object_id: itemId,
    object_value: workflowState,
    indirect_object_identifier: collectionType,
    indirect_object_id: collectionId,
    event_name: 'UserChangesItemVisibility',
  });
};

export const deleteCollectionItemFromPanel = ({
  itemId,
  collectionType,
  collectionId,
}: Record<string, any>) => {
  _deleteCollectionItem({
    itemId,
    collectionType,
    collectionId,
    area: 'panel',
  });
};

export const deleteCollectionItemFromModal = ({
  itemId,
  collectionType,
  collectionId,
}: Record<string, any>) => {
  _deleteCollectionItem({
    itemId,
    collectionType,
    collectionId,
    area: 'settings-modal',
  });
};

export const viewBlogPublishedModal = () => {
  CMSV7Events.track({
    actor: ACTOR.USER,
    action: ACTION.VIEW,
    event_name: 'UserViewBlogPublishedModal',
    object_type: 'modal',
    object_identifier: 'site-published-modal',
    indirect_object_identifier: 'blog',
    product_page: COLLECTION_EDITOR,
  });
};

export const clickBlogPublishedModalCopyLink = () => {
  CMSV7Events.track({
    ...userClickButton,
    event_name: 'UserCopiesContentLink',
    object_display_name: 'Copy',
    object_identifier: 'copy-site-link',
    indirect_object_identifier: 'blog',
    product_page: COLLECTION_EDITOR,
  });
};

export const clickBlogPublishedModalSocialIcon = ({ socialNetwork }: Record<string, any>) => {
  CMSV7Events.track({
    ...userClickButton,
    event_name: 'UserClicksShareToSocial',
    object_identifier: 'social-link-share',
    object_value: socialNetwork,
    indirect_object_identifier: 'blog',
    product_page: COLLECTION_EDITOR,
  });
};

export const clickBlogPublishedModalCreateCampaignsDraft = () => {
  CMSV7Events.track({
    ...userClickButton,
    event_name: 'UserCreatesCampaignDraftFromBlog',
    object_display_name: 'Create a Draft in Email Campaigns',
    object_identifier: 'create-a-draft-in-email-campaigns',
    indirect_object_identifier: 'blog',
    product_page: COLLECTION_EDITOR,
  });
};

/** Collection Settings */

export const addTag = ({ isCategoriesInput, item, newTag, allTags }: Record<string, any>) => {
  CMSV7Events.track({
    actor: ACTOR.USER,
    action: ACTION.ADD,
    object_type: isCategoriesInput ? 'category' : 'tag',
    object_identifier: newTag,
    object_value: allTags,
    product_page: COLLECTION_EDITOR,
    product_section: 'settings-modal',
    ...contentItemIndirectObject(item),
  });
};

export const hoverBlockAlert = () => {
  CMSV7Events.track({
    actor: ACTOR.USER,
    action: ACTION.HOVER,
    object_type: 'button',
    object_identifier: 'tooltip-alert',
  });
};

export const changeMainImage = (item: any) => {
  CMSV7Events.track({
    actor: ACTOR.USER,
    action: 'change',
    event_name: 'UserChangesBlogThumbnail',
    object_type: 'imageuploader',
    object_identifier: 'main-image',
    product_page: 'collection-editor',
    product_section: 'settings-modal',
    ...contentItemIndirectObject(item),
  });
};

export const deleteMainImage = (item: any) => {
  CMSV7Events.track({
    actor: ACTOR.USER,
    action: 'delete',
    event_name: 'UserRemovesBlogThumbnail',
    object_type: 'imageuploader',
    object_identifier: 'main-image',
    product_page: COLLECTION_EDITOR,
    product_section: 'settings-modal',
    ...contentItemIndirectObject(item),
  });
};

/**
 * @param {string} collectionType e.g. 'page', 'blog', 'portfolio'...
 * @param {string} productSection e.g. 'main_navigation', 'member_areas', 'not_linked'
 * @param {string} collectionId e.g. '611be8d8c70ece61c2561343'
 */
export const dropsCollectionIntoNav = (
  collectionType: string,
  productSection: string,
  collectionId: string | null,
  memberAreaId: string | null
) => {
  CMSV7Events.track({
    actor: ACTOR.USER,
    action: ACTION.MOVE,
    event_name: 'UserMovesCollection',
    object_type: 'contentcollection',
    object_id: collectionId || '',
    object_identifier: collectionType,
    product_section: productSection,
    member_area_id: memberAreaId || undefined,
  });
};

export const addItemToCollection = (categoryName: string, itemId: string, collectionId: string) => {
  CMSV7Events.track({
    actor: ACTOR.USER,
    action: ACTION.ADD,
    object_type: 'contentitem',
    object_identifier: categoryName,
    object_id: itemId,
    indirect_object_id: collectionId,
    product_area: PAGES,
    product_page: 'item-catalog',
    product_section: 'category-list',
  });
};

export const moveItemBetweenCollections = (
  itemType: string,
  collectionType: string,
  itemId: string,
  destinationCollectionId: string) => {
  CMSV7Events.track({
    actor: ACTOR.USER,
    action: ACTION.MOVE,
    object_type: 'contentitem',
    object_identifier: itemType,
    object_id: itemId,
    event_name: 'UserMovesCollectionItem',
    indirect_object_id: destinationCollectionId,
    indirect_object_identifier: collectionType,
    product_area: PAGES,
    product_page: COLLECTION_EDITOR,
    product_section: 'panel',
  });
};

export const clickManageCollectionInSection = (type: string, id: string) => {
  CMSV7Events.track({
    ...userClickButton,
    object_identifier: 'manage-contentitem',
    indirect_object_identifier: type,
    indirect_object_id: id,
    product_page: 'section-editor',
  });
};

export const viewManageCollection = (type: string, id: string) => {
  CMSV7Events.track({
    actor: ACTOR.USER,
    action: ACTION.VIEW,
    object_type: OBJECT.PANEL,
    object_identifier: 'manage-contentitem',
    indirect_object_identifier: type,
    indirect_object_id: id,
    product_area: COLLECTION_EDITOR,
  });
};

export const clickCollectionSettingsGear = (type: string, id: string, source: string) => {
  CMSV7Events.track({
    ...userClickButton,
    object_identifier: 'collection-settings',
    indirect_object_identifier: type,
    indirect_object_id: id,
    product_section: source,
  });
};

export const clickItemSettingsCog = (item: ContentItem) => {
  CMSV7Events.track({
    ...userClickButton,
    object_identifier: 'collection-item-settings-cog',
    product_page: 'collection-settings',
    product_section: 'frame-toolbar',
    ...contentItemIndirectObject(item),
  });
};

export const clickMetadata = (item: ContentItem, metadataType: string) => {
  CMSV7Events.track({
    ...userClickButton,
    object_identifier: 'item-metadata',
    object_value: metadataType,
    product_page: 'collection-settings',
    product_section: 'section-editor',
    ...contentItemIndirectObject(item),
  });
};

/** Demo Collection */

export const cloneDemoCollectionModalShown = (collectionType: string) => {
  CMSV7Events.track({
    product_area: PAGES,
    actor: ACTOR.USER,
    action: ACTION.VIEW,
    object_type: OBJECT.MODAL,
    object_identifier: 'clone-collection-prompt',
    collection_typename: collectionType,
  });
};

export const userCloneDemoCollection = (collectionType: string) => {
  CMSV7Events.track({
    actor: ACTOR.USER,
    action: ACTION.DUPLICATE,
    object_type: 'contentcollection',
    object_identifier: collectionType,
    object_display_name: 'demo',
  });
};

export const deleteDemoCollectionErrorModalShown = (collectionType: string) => {
  CMSV7Events.track({
    actor: ACTOR.USER,
    action: ACTION.VIEW,
    product_area: PAGES,
    object_type: OBJECT.MODAL,
    object_identifier: 'action-not-allowed',
    object_display_name: 'delete-homepage',
    collection_typename: collectionType,
  });
};

export const userDeleteDemoCollection = (collectionType: string) => {
  CMSV7Events.track({
    product_area: PAGES,
    actor: ACTOR.USER,
    action: ACTION.DELETE,
    object_type: 'contentcollection',
    object_identifier: collectionType,
    collection_typename: collectionType,
    object_display_name: 'demo',
  });
};
