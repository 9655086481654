/**
 * Squarespace's Core Dialog System Fields
 * @module squarespace-dialog-fields
 */
YUI.add('squarespace-dialog-fields', function (Y) {


  Y.namespace('Squarespace');

  /**
   * Base class for all dialog fields. Constructor is only ever called by a dialog.
   * @class DialogField
   * @namespace Squarespace
   * @extends Squarespace.Gizmo
   * @constructor
   * @param config {Object} A map of configuration paramters passed to the dialog field (from the dialog)
   * @param initialData {Object} A map of initialData passed into the parent dialog that the field instance will use
   * @param panel {Object} The dialog this form field is embedded in

   */
  Y.Squarespace.DialogField = Class.extend(Y.Squarespace.Gizmo, {

    _name: 'DialogField',

    initialize: function (config, initialData, dialog) {

      this._super();

      var defaultConfig = {
        defaultHidden: false
      };

      // an attempt at avoiding modifying the passed in initial data and configuration in place.
      this.config = Y.merge(defaultConfig, config);
      this.panel = dialog; // We should phase out this.panel since its actually the dialog
      this.dialog = dialog;
      this.initialData = Y.merge({}, initialData);
      this.className = '';
      this.inActiveFrame = true;

      // setting initial value based on initial data (does not update ui)
      if (this.config && (typeof this.config.name !== 'undefined') && (this.config.name in initialData)) {
        this.value = initialData[this.config.name];
      }

    },

    /**
      @method hide
     */
    hide: function (noAnimation) {
      this.temporaryHide(noAnimation);
    },

    getTakenHeight: function () {
      return this.html.get('offsetHeight');
    },

    /**
      @method show
     */
    show: function (noAnimation) {
      this.temporaryShow(noAnimation);
    },

    /**
      Gets the name of the dialog field as provided as part of the configuration
      passed into the dialog
      @method getName
      @return {String} This dialog field's name
     */
    getName: function () {
      return this.config.name;
    },

    /**
      Get what type of dialog field this is. The dialog sets the name when it
      renders (.show()) for the first time. This name is the fields key in the
      DialogFieldGenerators object
      @method getType
      @return {String} Dialog field type
     */
    getType: function () {
      return this.type;
    },

    /**
      Returns the dialog this dialog field belongs to
     */
    getDialog: function () {
      return this.dialog;
    },

    /**
      Will append this dialog field's html to a dom element
      This makes DialogFields interplay nicely with Gizmo
      @param node {Node} A YUI node to append this dialog field's html node to
     */
    append: function (node) {
      node.append(this.html);

      if (this.config.defaultHidden) {this.temporaryHide(false);}
    },

    /**
      Currently does nothing. Who knows why its here, can it be
      removed?
      @method resize
     */
    resize: function () {

    },

    isValid: function () {
      return this.getErrors().length > 0;
    },

    getErrors: function () {

      // Allow dialogs to define custom validators
      if (this.config.validator && this.config.validationErrorMsg && !this.config.validator.call(this, this.dialog)) {
        return [this.config.validationErrorMsg];
      }

      return [];
    },

    /**
      If a YUI node is set as this.control, this method shows an error message by that
      control, sliding and color changing included. Useful for form validation errors.
      @method showError
      @param message {String} The error message to show by the field
     */
    showError: function (message) {

      if (!message) {
        return;
      }
      // Allow dialog fields to use something special for errorFlyoutAnchor without having to
      // go through and factor out this.control throughout old code.
      var errorEl = this.errorFlyoutAnchor || this.control;

      if (!errorEl) {
        console.error('dialog-field: [DialogField] No control or error flyout anchor set to throw form field error ' +
                      'on. Set this.control or this.errorFlyoutAnchor to a node.');
      }

      // plug in the flyout functionality if its not there already
      if (!errorEl.hasPlugin('flyoutPlugin')) {
        errorEl.plug(Y.Squarespace.Animations.Flyout, {
          duration: 0.3
        });
      }

      var flyout = errorEl.flyoutPlugin;
      if (flyout.get('visible')) {

        if (this._showErrorSub) {
          this._showErrorSub.detach();
        }

        this._showErrorSub = flyout.once(this.getId() + '|hidden', function () {
          this._showErrorSub = null;
          this._doShowErrorFlyout(message);
        }, this);

        flyout.hide();
      } else {
        this._doShowErrorFlyout(message);
      }

    },

    _doShowErrorFlyout: function (message) {

      var errorAnchor = (this.errorFlyoutAnchor || this.control);
      var flyout = errorAnchor.flyoutPlugin;

      this.html.addClass('error');

      //message = message.replace(new RegExp(" ", "g"), "&nbsp;");

      if (!this._errorEl) {
        this._errorEl = Y.Node.create('<div class="flyout-error-message">' + message + '</div>');
        this._errorEl.setStyle('zIndex', this.dialog.zIndex + 10);

        // trap clicks on it
        this._errorEl.on(this.getId() + '|click', function (e) {
          e.halt();
        }, this);
      }

      this._errorEl.setContent(message);

      // FIX ME: LEFT DON"T MAKE SENSE (- - 10)
      var width = Y.Squarespace.NodeUtils.measureNode(this._errorEl).width,
        left = (errorAnchor.getX() + errorAnchor.get('offsetWidth') - (this.html.hasClass('thin') ? -10 : 1)),
        msgRightEdgeX = left + width,
        windowRightEdgeX = Y.one(window).get('region').right,
        outFromLeft = msgRightEdgeX > windowRightEdgeX;

      if (outFromLeft) {
        this._errorEl.addClass('out-from-left');
      }

      flyout.setAttrs({
        node: this._errorEl,
        alignment: outFromLeft ? 'lt' : 'rt'
      });

      this._clearErrorSub = Y.on(this.getId() + '|click', this.clearError, this.errorEl, this);

      flyout.show();
    },

    /**
      Remove's a field error put on screen by showError
      This just updates the ui, use this.clearError to clear an error
      from the field and update the ui at once
      @method hideError
      @private
     */
    hideError: function () {

      // stop if no error is showing
      var errorEl = this.errorFlyoutAnchor || this.control;

      if (
        !errorEl ||
        !errorEl.hasPlugin('flyoutPlugin') ||
        !errorEl.flyoutPlugin.get('visible')
      ) {
        return;
      }

      if (this._clearErrorSub) {
        this._clearErrorSub.detach();
      }

      var flyout = errorEl.flyoutPlugin;

      this._subscribeOnce(flyout, this.getId() + '|hidden', function(e) {
        this.html.removeClass('error');
      }, this);

      flyout.hide();

    },

    /**
      Clears an error from this dialog field, returning it to
      its default
      state.
      @method clearError
     */
    clearError: function () {

      this.dialog.clearError(this); // remove error data
      this.hideError(); // visually hide error

    },

    /**
      Scrolls this field into view. Generally used for when validation
      on long dialogs requires you to scroll to the offending field.
    */
    scrollIntoView: function() {
      this.html.scrollIntoView();
    },

    /**
      Currently does nothing. Who knows why its here, can it be
      removed?
      @method updateInlineTitle
     */
    updateInlineTitle: function () {

    },

    /**
      Sets the height of the element set as this.control
      @method setHeight
      @param h {Number} The new height
     */
    setHeight: function (h) {

      if (this.control) {
        this.control.setStyle('height', h + 'px');
      }

    },


    /**
      Gets the current value of this dialog field
      @method getValue
      @return {Mixed} this field's value
     */
    getValue: function () {
      return this.value;
    },

    /**
      Sets the current value of this dialog field
      @method setValue
      @param v {Mixed} The new value
     */
    setValue: function (v) {

      var oldValue = this.value;
      this.value = v;

      if ('config' in this && 'name' in this.config) {

        /**
          Notifies users that this dialog field's value has changed
          @event value-changed
          @param e.name {String} The name of the field that changed
          @param e.value {Mixed} The new value
          @param e.oldValue {Mixed} The previous value
          @param e.field {Squarespace.DialogField} The dialog field instance
         */
        this.fire('value-changed', {
          name: this.config.name,
          value: v,
          oldValue: oldValue,
          field: this
        });

      }

    },

    /**
      Hides the field from view, setting its display to none when it
      is gone from view.
      @method temporaryHide
     */
    temporaryHide: function (noAnimation) {

      /**
        Fires when a dialog field begins to hide
        @event hide
        @param this {DialogField} the dialog field that was hidden
       */
      this.fire('hide', this);
      this.hidden = true;

      if (this.hideAnim) {this.hideAnim.stop();}

      if (noAnimation) {
        this.html.setStyle('display', 'none');
        this.fire('hidden', this);
      } else {

        this.hideAnim = this._anim({
          node: this.html,
          to: { opacity: 0 },
          duration: 0.35,
          easing: Y.Easing.easeOutStrong
        });

        this.hideAnim.on('end', function () {

          this.html.setStyle('display', 'none');

          /**
            Fires when a dialog field is finished hiding
            @event hidden
            @param this {DialogField} the dialog field that was hidden
           */
          this.fire('hidden', this);

        }, this);

        this.hideAnim.run();
      }
    },

    /**
      Shows a dialog field after it has been hidden (with .temporaryHide())
      @method temporaryShow
     */
    temporaryShow: function () {

      /**
        Fires when a dialog field begins to show
        @event show
        @param this {DialogField} the dialog field that was hidden
       */
      this.fire('show', this);
      this.hidden = false;

      if (this.hideAnim) {this.hideAnim.stop();}

      this.html.setStyle('display', 'block');

      this.hideAnim = this._anim({
        node: this.html,
        to: { opacity: 1 },
        duration: 0.35,
        easing: Y.Easing.easeOutStrong
      });

      this.hideAnim.on('end', function () {

        /**
          Fires when a dialog field is finished reshowing itself
          @event shown
          @param this {DialogField} the dialog field that was hidden
         */
        Y.fire('shown', this);
        Y.fire('showing', this);

        this.fire('shown');

      }, this);

      this.hideAnim.run();
    },

    /**
      Get the node this field is wrapped in (specifically this.html, which)
      all dialog fields should define as part of the render process
      @method methodName
     */
    getNode: function () {
      return this.html;
    },

    /**
      Tells you if the field has changed based on a comparison to the initial data
      passed into the dialog
      @return {Boolean} Whether the current data is different than the initial data
     */
    didDataChange: function () {

      if (this.config.ignoreChanges) {
        return false;
      }

      var changed;

      if (
        !this.initialData ||
        (Y.Lang.isUndefined(this.initialData[this.getName()]) && this.getValue() === '')
      ) {
        changed = false;
      } else if (Y.Lang.isArray(this.initialData[this.getName()])) {
        changed = Y.JSON.stringify(this.initialData[this.getName()]) !== Y.JSON.stringify(this.getValue());
      } else {
        changed = this.getValue() !== this.initialData[ this.getName() ];
      }

      return changed;

    }

  });

}, '1.0', { requires: [
  'anim',
  'json',
  'squarespace-gizmo',
  'squarespace-node-flyout',
  'squarespace-node-utils',
  'squarespace-util',
  'widget'
] });
