export const PAGES = 'pages';
export const ANIMATIONS = 'animations';

export enum PRODUCT {
  AREA_DESIGN_EDITOR = 'design-editor',
  AREA_LAYOUT_ENGINE = 'layout-engine',
  AREA_FLUID_ENGINE = 'fluid-engine',
  PAGE_FONTS = 'fonts',
  PAGE_COLORS = 'colors',
  PAGE_BUTTONS = 'buttons',
  PAGE_SITE_STYLES = 'site-styles',
  PAGE_INLINE_EDITOR = 'inline-editor',
  PAGE_BLOCK_EDITOR = 'block-editor',
  SECTION_TEMPLATE_SWITCHING_TEST = 'template-switching-test',
  SECTION_FONT_STYLE_DETAILS = 'font-style-details',
  SECTION_BODY = 'body',
}

// See @sqs/event-schemas/schemas/enums.avdl for valid tokens
export enum ACTOR {
  USER = 'user',
  SYSTEM = 'system',
}

export enum ACTION {
  VIEW = 'view',
  CLICK = 'click',
  SELECT = 'select',
  SAVE = 'save',
  OPEN = 'open',
  INPUT = 'input',
  LOAD = 'load',
  CLOSE = 'close',
  HOVER = 'hover',
  DUPLICATE = 'duplicate',
  ADD = 'add',
  MOVE = 'move',
  DELETE = 'delete',
  CHANGE = 'change',
  CONNECT = 'connect',
  RESIZE = 'resize',
  TYPE = 'type',
  CHANGE_VISIBILITY = 'change_visibility',
  UPDATE = 'update'
}

export enum OBJECT {
  ACCOUNT = 'account',
  BUTTON = 'button',
  CARD = 'card',
  CAROUSEL = 'carousel',
  CUSTOM = 'custom',
  DESIGNTWEAK = 'designtweak',
  MENU = 'menu',
  MENU_ITEM = 'menuitem',
  PALETTE = 'palette',
  SLIDE = 'slide',
  TABS = 'tabs',
  TOGGLE = 'toggle',
  URLPARSE = 'urlparse',
  IMAGE = 'image',
  TEXTAREA = 'textarea',
  TEXTEDITOR = 'texteditor',
  LINK = 'link',
  MODAL = 'modal',
  NUMBER = 'number',
  PANEL = 'panel'
}

export enum IDENTIFIER {
  BLOCK_OPTIONS = 'block-level-custom-options',
}
