
const isAppleiPadOS13 = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const safariIdx = userAgent.indexOf('safari/');
  const touchDevice = 'ontouchstart' in window;
  // if not safari or a touch device, bail.
  if (safariIdx <= -1 || !touchDevice) {
    return false;
  }

  if (window.screen.width < 700) {
    return false;
  }

  // check for native PointerEvent support, not polyfill
  const pointerEventsSupported = (
    typeof window !== 'undefined' &&
    'PointerEvent' in window &&
    window.PointerEvent.prototype.constructor.length === 1
  );

  return pointerEventsSupported;
};

export default isAppleiPadOS13;
