import stylesheet from './transitions.less';

export default {
  slideIn: {
    enter: {
      from: {
        opacity: 0,
        transform: 'translateX(-100%)'
      },
      to: {
        opacity: 1,
        transform: 'translateX(0)'
      },
      className: stylesheet.dissolve
    },
    leave: {
      from: {
        opacity: 1,
        transform: 'translateX(0)'
      },
      to: {
        opacity: 0,
        transform: 'translateX(-100%)'
      },
      className: stylesheet.dissolve
    }
  }
};
