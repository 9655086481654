YUI.add('squarespace-node-utils', function(Y) {

  Y.namespace('Squarespace.NodeUtils');

  Y.Squarespace.NodeUtils = {
    /**
      Measures the size of a node, which may or may not be in the DOM. This method
      is capable of finding the size a node will be once its in the dom, without
      affecting the current flow of the document
      @method measureNode
      @return {Object} An object with height and width properties
    */
    measureNode: function (node) {

      var inFlow = node.inDoc();

      // how was this working before?
      // node = node.cloneNode(true);

      // original node must be in the dom for this
      // to work
      if (!inFlow) {
        node.setStyle('visibility', 'hidden');
        Y.one('body').appendChild(node);
      }

      // measure an absolute copy
      var proxy = node.cloneNode(true);
      proxy.setStyles({
        'visibility': 'hidden',
        'display': 'block',
        'position': 'absolute'
      });
      node.insert(proxy, 'after');

      var height = proxy.get('offsetHeight');
      var width = proxy.get('offsetWidth');

      proxy.remove(true);

      // remove the node if we added it to the dom
      if (!inFlow) {
        node.remove();
        node.setStyle('visibility', null);
      }

      return {
        height: height,
        width: width
      };

    },

    /**
     * Get the closest scrolling ancestor of the node, including itself.
     *
     * @method _getClosestScrollingAncestor
     * @param {Node} node The node to start search from
     * @return {Node} The youngest scrolling ancestor node.
     */
    getClosestScrollingAncestor: function (node) {

      return node.ancestor(Y.Squarespace.NodeUtils.isScrollable, true, 'body');

    },

    /**
     * Whether or not the node is scrollable
     *
     * @method isScrollable
     * @param  {Node} node
     * @return {Boolean}
     */
    isScrollable: function (node) {

      var overflowY = getComputedStyle(node.getDOMNode()).overflowY;

      return (overflowY === 'auto' || overflowY === 'scroll');

    }

  };

}, '1.0', {
  requires: []
});
