import EventTracker from '@sqs/track-events';
import AccessPermissions from '@sqs/enums/AccessPermissions';
import getI18nEventData from 'shared/i18n/eventsData';

const BROADCAST_CHANNEL_NAME = 'SquarespaceAdminEvents';

/* eslint-disable camelcase */

interface EventTrackerConstructorArgs {
  customSchemaName: string;
  eventOwnerTeam: string;
}

class EventTrackerBase {
  protected bc?: BroadcastChannel;
  protected tracker: EventTracker;

  constructor({ customSchemaName, eventOwnerTeam }: EventTrackerConstructorArgs) {
    // commerce checkout does not inject the SQUARESPACE_CONTEXT
    const context = window?.Static?.SQUARESPACE_CONTEXT;
    const trackerOpts = {
      event_owner_team: eventOwnerTeam,
      event_source: 'web',
    };
    if (context) {
      Object.assign(trackerOpts, {
        user_member_account_id: context?.authenticatedAccount?.id,
        user_website_id: context?.website?.id,
        ...getI18nEventData(),
      });

      if (window.BroadcastChannel) {
        const accessPermissions = context?.accessPermissions;
        if (accessPermissions && accessPermissions.includes(AccessPermissions.ADMIN)) {
          this.bc = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
        }
      }
    }

    this.tracker = new EventTracker(
      {
        customSchemaName,
        logging: __DEV__ === 'true',
      },
      trackerOpts
    );
  }

  track(payload: Record<string, string | number | boolean | undefined>) {
    if (!this.tracker) {
      console.warn('Event is firing before the environment is initialized!');
      return;
    }

    this.tracker.track(payload);

    if (this.bc) {
      this.bc.postMessage({ squarespaceEventPayload: payload });
    }
  }
}

export default EventTrackerBase;
