/*
This file, like all /CMSV7Events/ files, should not directly import the redux store.
The imports for the redux store and selectors are heavy!
They in turn import all reducers and many utils, including ContentBrowserAPI.
So, we mirror the relevant redux fields, for CMSV7Events to import without issue.

If all CMS events need a redux field, add it here.
If only a few events need a redux field, instead pass it in for each event call.
*/

type EventReduxMirror = {
  frameDeviceView?: 'phone' | 'tablet' | 'desktop',
};

type EventReduxMeta = {
  display_mode?: 'mobile' | 'tablet' | 'desktop',
};

let value: EventReduxMirror = {};

export const setEventReduxMirror = (reduxState: EventReduxMirror) => {
  value = { ...value, ...reduxState };
};

export const getEventMetaFromReduxMirror = () => {
  const eventMeta: EventReduxMeta = {};
  if (value.frameDeviceView) {
    eventMeta.display_mode = value.frameDeviceView === 'phone' ? 'mobile' : value.frameDeviceView;
  }
  return eventMeta as Record<string, string>;
};