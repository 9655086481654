/**
  Contains a mixin that will make a widget interface with dialog as an
  old dialog field would
  @module squarespace-dialog-field-legacy-interface
*/
YUI.add('squarespace-dialog-field-legacy-interface', function(Y) {

  /**
    A mixin that provides the new dialog field widget base class the legacy
    interface, for separation's sake. This can be done away with upon the advent
    of dialog2.
    @class DialogFieldLegacyInterface
    @namespace Squarespace
    @constructor
  */
  var DialogFieldLegacyInterface =
  Y.namespace('Squarespace').DialogFieldLegacyInterface = function(config) {

    // Because dialog, dialog fields (1), and multiframe fields all know about
    // each other's internals, we need to do this here, the same way the
    // original dialog field generators do. Multiframe and dialog check for it
    // without this, local validation doesn't run properly.
    this.inActiveFrame = true;

    /*
      Old man dialog relies on dialog fields to call clear error on it with
      itself when hiding an error. The right way to do this is to listen for
      the error hidden event and respond.
      Y.Do.after is used here because this interface is a mixin and not a sub
      -class, so one can't just call the superclass method in an override and
      then do this
    */
    // we have to check for this function, since not all widgets will have it
    if (Y.Lang.isFunction(this.hideError)) {
      Y.Do.after(function() {
        var dialog = config.dialog;
        if (dialog) {
          dialog.clearError(this); // remove error data from the dialog
        }
      }, this, 'hideError', this);
    }

    Y.Do.after(function() {
      // legacy event firing support, this event
      // is deprecated, so don't use it! Use the attribute change
      // event instead ("dataChange")
      this.after(this.get('id') + '|dataChange', function(e) {
        var dialog = config.dialog;
        if (dialog && !e.silent) {
          dialog.fire('datachange', this);
        }

        var fieldName = this.get('name');
        if (fieldName) {
          this.fire('value-changed', {
            name: fieldName,
            value: e.newVal,
            oldValue: e.prevVal,
            field: this
          });
        }
      }, this);
    }, this, 'bindUI', this);

    Y.Do.after(function() {
      if (config && config.defaultHidden) {
        this.hide(true);
      }
    }, this, 'syncUI', this);
  };

  DialogFieldLegacyInterface.NAME = 'dialogFieldLegacyInterface';

  DialogFieldLegacyInterface.prototype = {
    temporaryHide: function() {
      this.hide();
    },

    temporaryShow: function() {
      this.show();
    },

    getName: function() {
      return this.get('name');
    },

    getType: function() {
      return this.name;
    },

    getValue: function() {
      return this.get('data');
    },

    getErrors: function() {
      return this.get('errors');
    },

    getNode: function() {
      return this.get('boundingBox');
    },

    setValue: function(val, options) {
      this.set('data', val, options);
    },

    clearError: function() {
      this.hideError();
    }
  };

}, '1.0', {
  requires: []
});