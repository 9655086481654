import { getWebsiteLocaleFromContext, getResolvedMemberLocale } from './resolvedLocales';

const memberAccountLocale = getResolvedMemberLocale();
/**
 * Some entrypoints don't have access to SQUARESPACE_CONTEXT.website.language.
 * We need to find a way to guarantee the website locale.
 */
const websiteLocale = getWebsiteLocaleFromContext() || memberAccountLocale;

export {
  websiteLocale,
  memberAccountLocale
};

export default function getI18nEventData() {
  return {
    website_locale: websiteLocale,
    member_account_locale: memberAccountLocale
  };
}
