import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import stylesheet from './TabbedHeader.less';
import { CSSTransitionGroup } from '@sqs/transitions';
import transitions from './transitions';
import scrollTo from '@sqs/utils/scroll/scrollTo';

/**
 * TabbedHeader to be used for dialog headers
 *
 * Usage:
 * <TabbedHeader
 *  tabs={[
 *  {
 *    tabTitle: 'First Tab'
 *  }
 *  ]}
 * />
 */
class TabbedHeader extends React.PureComponent {
  static propTypes = {
    /**
     * Default selected tab
     */
    defaultTabIndex: PropTypes.number,
    /**
     * Tab content
     *  ex: [{
     *    tabTitle: 'First Tab',
     *    onClick: setSelectedIndex(),
     *    error: false
     * }]
     */
    tabs: PropTypes.array.isRequired,
    /**
     * Constrain tab width for long text and scroll calculation
     */
    tabWidth: PropTypes.number,
    /**
     * The duration for the scroll animation in milliseconds
     */
    scrollDurationMS: PropTypes.number
  };

  static defaultProps = {
    defaultTabIndex: 0,
    scrollDurationMS: 200,
    tabWidth: 140,
    tabs: []
  };

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();

    this.state = {
      active: Math.min(props.defaultTabIndex, props.tabs.length - 1),
      leftIndicatorVisible: false,
      rightIndicatorVisible: false
    };
  }

  componentDidMount() {
    if (this.getScrollable()) {
      this.scrollRef.current.addEventListener('scroll', this.handleScroll, {
        passive: true
      });
      this.scrollTo(this.state.active);
    }
  }

  componentWillUnmount() {
    if (this.getScrollable()) {
      this.scrollRef.current.removeEventListener('scroll', this.handleScroll);
    }
  }

  scrollTo(index) {
    const { scrollDurationMS, tabWidth } = this.props;
    const scrollToPosition = (tabWidth * Math.floor(index)) - (tabWidth * 0.25);

    scrollTo(this.scrollRef.current, {
      left: scrollToPosition
    }, {
      minDuration: scrollDurationMS,
      maxDuration: scrollDurationMS,
      easing: 'easeInOutSine'
    });
  }

  handleScroll = e => {
    const { tabWidth } = this.props;
    const { scrollLeft, scrollWidth, clientWidth } = e.target;

    this.setState({
      leftIndicatorVisible: scrollLeft > tabWidth * 0.5,
      rightIndicatorVisible: scrollLeft + clientWidth + tabWidth * 0.5 < scrollWidth
    });
  };

  handleTabClick = ({ currentTab, index }) => {
    this.setState({
      active: index
    });

    this.scrollTo(index);

    if (currentTab.onClick) {
      currentTab.onClick();
    }
  };

  prevTab = () => {
    const index = this.scrollRef.current.scrollLeft / this.props.tabWidth;
    this.scrollTo(index);
  }

  nextTab = () => {
    const { tabWidth } = this.props;
    const index = (this.scrollRef.current.scrollLeft + tabWidth * 1.5) / tabWidth;
    this.scrollTo(index);
  }

  getScrollable() {
    const { tabWidth, tabs } = this.props;
    const width = this.scrollRef.current && this.scrollRef.current.clientWidth;
    const scrollableWidth = tabWidth * tabs.length;

    return (width ? scrollableWidth > width : false);
  }

  renderTabs() {
    const { tabs, tabWidth } = this.props;
    const { active } = this.state;

    return tabs.map((currentTab, index) => {
      const { className = '', error, tabTitle } = currentTab;
      const classes = classnames(
        stylesheet.tab,
        {
          [stylesheet.active]: active === index,
          [stylesheet.error]: error
        },
        className
      );

      return (
        // @TODO consider using a button
        // eslint-disable-next-line
        <div
          className={classes}
          data-test={`tab-${index}`}
          data-tab={`tab-${index}`}
          key={`tab-${tabTitle}`}
          onClick={this.handleTabClick.bind(this, { currentTab, index })}
          style={{
            width: tabWidth
          }}
        >
          {tabTitle}
        </div>
      );
    });
  }

  renderIndicatorMask(key) {
    const indicatorWidth = this.props.tabWidth / 2;
    return this.getScrollable() && (
      <CSSTransitionGroup
        key={`${key}TransitionGroup`}
        wrapChildWithTag={false}
        className={stylesheet[`${key}TransitionGroup`]}
        transitions={transitions.slideIn}
      >
        {this.state[`${key}IndicatorVisible`] && ([
          <div
            key={`${key}Mask`}
            className={stylesheet.scrollIndicatorMask}
            style={{ width: indicatorWidth }}
          />,
          // @TODO consider using a button
          // eslint-disable-next-line
          <div
            key={`${key}Icon`}
            className={stylesheet.scrollIndicatorIcon}
            style={{ width: indicatorWidth / 2 }}
            onClick={ key === 'left' ? this.prevTab : this.nextTab }
          />
        ])}
      </CSSTransitionGroup>
    );
  }

  renderScrollableTabs() {
    const { tabWidth, tabs } = this.props;
    return (
      <div style={{ width: tabWidth * tabs.length }}>
        {this.renderTabs()}
      </div>
    );
  }

  render() {
    return (
      <div className={stylesheet.container}>
        <div
          className={stylesheet.flexTabContainer}
          ref={this.scrollRef}
        >
          {this.renderScrollableTabs()}
        </div>
        {[
          this.renderIndicatorMask('left'),
          this.renderIndicatorMask('right')
        ]}
      </div>
    );
  }
}

export default TabbedHeader;
