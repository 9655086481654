/**
 * @module squarespace-widgets-data-widget
 */
YUI.add('squarespace-widgets-data-widget', function(Y) {

  Y.namespace('Squarespace.Widgets');

  var DATA_STATE_PREFIX = 'data-state-';
  /**
   * An SS-Widget variant wiht an additional attribute for storing data ('data')
   * and one for labeling the widgets data state ('dataState')
   *
   * @class DataWidget
   * @namespace Squarespace.Widgets
   * @constructor
   * @extends Squarespace.Widget.SSWidget
   */
  var DataWidget =
  Y.Squarespace.Widgets.DataWidget = Y.Base.create('dataWidget', Y.Squarespace.Widgets.SSWidget, [],
    // prototype
    {
      initializer: function(config) {
        if (!config.dataState) {
          // set data state to initialized or loaded if its data loading is not async
          if (this.getProperty('ASYNC_DATA')) {
            this.set('dataState', this.getProperty('DATA_STATES').INITIALIZED);
          } else {
            this.set('dataState', this.getProperty('DATA_STATES').LOADED);
          }
        }
      },

      renderUI: function() {
        DataWidget.superclass.renderUI.call(this);
        this._updateDataStateClassName();
      },

      bindUI: function() {
        DataWidget.superclass.bindUI.call(this);

        var id = this.get('id');
        this.after(id + '|dataChange', function(e) {
          if (!e.noSyncUI) {
            this.syncUI();
          }
        }, this);

        this.after(id + '|dataStateChange', this._updateDataStateClassName, this);
      },

      /**
        Updates the bounding box with a class for the current data-state

        @method _updateDataStateClassName
        @private
      */
      _updateDataStateClassName: function() {
        // update bounding box class
        var boundingBox = this.get('boundingBox'),
          currentState = this.get('dataState');

        Y.Object.each(this.getProperty('DATA_STATES'), function(dataState) {
          boundingBox.removeClass(DATA_STATE_PREFIX + dataState);
        });

        boundingBox.addClass(DATA_STATE_PREFIX + currentState);
      },

      // sugar methods
      /**
        Convenience method for settings the data state to loading

        @method setLoadingState
      */
      setLoadingState: function() {
        return this.set('dataState', this.getProperty('DATA_STATES').LOADING);
      },

      /**
        Convenience method for settings the data state to loaded

        @method setLoadedState
      */
      setLoadedState: function() {
        return this.set('dataState', this.getProperty('DATA_STATES').LOADED);
      },

      /**
        Convenience method for settings the data state to load failed

        @method setLoadFailedState
      */
      setLoadFailedState: function() {
        return this.set('dataState', this.getProperty('DATA_STATES').LOAD_FAILED);
      },

      /**
        Pretty convenience method for checking if this widget completed loading
        successfully

        @method loadedSuccessfully
      */
      loadedSuccessfully: function() {
        return this.get('dataState') === this.getProperty('DATA_STATES').LOADED;
      },

      /**
        Pretty convenience method for checking if this widget is loading

        @method isLoading
      */
      isLoading: function() {
        return this.get('dataState') === this.getProperty('DATA_STATES').LOADING;
      },

      /**
        Pretty convenience method for checking if loading failed

        @method loadFailed
      */
      loadFailed: function() {
        return this.get('dataState') === this.getProperty('DATA_STATES').LOAD_FAILED;
      }
    },
    {
      /**
       * The prefix for all CSS classes generated by the widget.
       *
       * @property CSS_PREFIX
       * @static
       * @final
       * @type String
       */
      CSS_PREFIX: 'sqs-data-widget',

      /**
        @property ASYNC_DATA
        @description Whether or not this dialog field loads it's own data
          asynchronously, likely from a server through an api call. If your sub
          class loads its own data asynchronously, override this property to set
          its default to true
        @type Boolean
      */
      ASYNC_DATA: false,

      /**
        @property DATA_STATES
        @description Constants representing the state of the field data
      */
      DATA_STATES: {
        INITIALIZED: 'initialized', // has not attempted to load data yet
        LOADING: 'loading',
        LOADED: 'loaded',
        LOAD_FAILED: 'load-failed'
      },

      ATTRS: {
        /**
          @attribute data
          @description This field's data
          @type Mixed
          @default null
        */
        data: {
          value: null,
          validator: function(val) {
            if (Y.Lang.isUndefined(val)) {
              console.warn(this.name + ': Will not set data to undefined.');
              return false;
            }
            return true;

          }
        },

        /**
          @attribute dataState
          @description The loading state of this field's data
          @type String
          @default this.constructor.INITIALIZED
        */
        dataState: {
          valueFn: function() {
            return this.getProperty('DATA_STATES').INITIALIZED;
          }
        },

        /**
         * Prevent the template from being rendered in renderUI.
         * Implementations of SSWidget that set this to false must then render
         * the template on their own. This is useful when the template rendering
         * is needed in syncUI rather than renderUI.
         *
         * @attribute preventRenderTemplate
         * @type Boolean
         * @default false
         * @writeOnce
         */
        preventRenderTemplate: {
          value: false,
          validator: Y.Squarespace.AttrValidators.isBoolean
        }
      }
    }
  );

}, '1.0', { requires: [
  'base',
  'node',
  'squarespace-attr-validators',
  'squarespace-ss-widget',
  'widget'
] });
