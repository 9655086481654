import { mediaQueries } from '@sqs/universal-utils';
import { GRID_PX } from '@sqs/styles/variables/baseDamask';
/**
  Including this module will add Flyout to Squarespace.Animations,
  which is a node plugin allowing you to make another node fly out
  of it, based on a given alignment.
  @module squarespace-node-flyout
*/
YUI.add('squarespace-node-flyout', function (Y) {

  /**
    Simply plug this into a node, and then you can make any other node fly in and out
    of it with showFlyout and hideFlyout. See attributes for a list of options. Currently
    only top right (rt) alignment is implemented, but the others should be easy to add.
    Convention for the constants used to specify the alignment is the side the flyout
    should come out of followed by the alignment on that side (left, center, right
    for horizontal edges and top, center, bottom for vertical edges). Its built for only
    one flyout at a time.
  *
    This plugin uses the flyout node size in its calculations, so visual problems may
    be due to the size of that node being smaller than you think due to floating, positioning, etc.
    @class Flyout
    @namespace Squarespace.Animations
    @extends Plugin.Base
  */
  Y.namespace('Squarespace.Animations').Flyout = Y.Base.create('flyoutPlugin', Y.Plugin.Base, [], {

    /**
      Fires on the plugin when the flyout has finished the
      showing process. Includes the flyout node under e.flyout.
      @event shown
    */

    /**
      Fires on the host when the flyout has finished the
      showing process. Includes the flyout node under e.flyout.
      @event flyout-shown
    */

    /**
      Fires on the plugin when the flyout has finished the
      hiding process. Includes the flyout node under e.flyout.
      @event hidden
    */

    /**
      Fires on the host when the flyout has finished the
      hiding process. Includes the flyout node under e.flyout.
      @event flyout-hidden
    */

    /**
      Sets up initial state
      @method initializer
      @private
    */
    initializer: function (config) {
      this._mask = Y.Node.create('<div class="flyout-animation-wrapper sqs-flyout-mask"></div>');
      this._mask.setStyles({
        position: 'fixed',
        overflow: this.get('overflow')
      });
      this._mask.setStyle('z-index', '300001');
      this._mask.setStyle('zIndex', '300001');
      this._isShowing = false;
      this._isHiding = false;
    },

    /**
      Clean up after ourselves. Leave nothing in the DOM and
      no running animations. This doesn't destroy the flyout node

      @method destructor
      @private
    */
    destructor: function () {
      if (this._anim) {
        this._anim.stop().destroy();
      }
      this._mask.remove(true);
      Y.detachAll(this.get('id') + '|*');
    },

    /**
      Handles the scroll event, updating the position of the flyout
      if its open
      @method onScroll
      @private
      @param e {Event} The browser scroll event
    */
    _onScroll: function (e) {
      var host = this.get('host');

      // Event is bound to the window so...
      if (e.target.contains(host) || e.currentTarget.contains(host)) {
        this._updateMaskPosition();
      }
    },

    /**
      Handles the browser resize event, updating the position of the flyout
      if its open
      @method _onResize
      @private
    */
    _onResize: function () {
      this._updateMaskPosition();
    },

    /**
      Shows the flyout, according to the init options (attributes) for
      this plugin
      @method show
    */
    show: function () {
      if (!this.get('visible') && !this._isShowing) {
        this._animateFlyout(true);
      }
    },

    /**
      Shows the flyout, according to the init options (attributes) for
      this plugin
      @method hide
    */
    hide: function () {
      if (this.get('visible') && !this._isHiding) {
        this._animateFlyout(false);
      }
    },

    /**
      Measures the size of a node, which may or may not be in the DOM
      @method _measureNode
      @return Object An object with width and height properties
    */
    _measureNode: function (node) {
      return Y.Squarespace.NodeUtils.measureNode(node);
    },

    /**
      Properly positions the mask and animated the wrapper in
      @method _showFlyout
      @private
      @param showing {Boolean} True to show the flyout, false to hide it
    */
    _animateFlyout: function (showing) {

      var mask = this._mask,
        flyout = this.get('node'),
        flyoutSize = this._measureNode(flyout),
        initialFlyoutOffset = this._getInitialFlyoutOffset();

      this._updateMaskPosition();

      // kill running animations to force hidden/shown state
      if (this._anim) {
        this._anim.stop(true);
        this._anim = null;
      }

      // set initial mask and node positions and append to dom
      if (showing) {
        mask.setStyles({
          height: flyoutSize.height,
          width: flyoutSize.width + 1 // Fudge factor for Firefox...general safety.
        });
        flyout.setStyles({
          position: 'absolute',
          top: initialFlyoutOffset.yOffset,
          left: initialFlyoutOffset.xOffset
        });
        mask.appendChild(flyout);

        var target = this.get('renderTarget') || Y.one('body');
        target.appendChild(mask);

      }

      // setup animation
      this._anim = new Y.Anim({
        duration: this.get('duration'),
        easing: this.get('easing'),
        node: flyout,

        to: {
          top: showing ? 0 : initialFlyoutOffset.yOffset,
          left: showing ? 0 : initialFlyoutOffset.xOffset
        }
      });
      if (this.get('animateOpacity')) {
        this._anim.set('from.opacity', showing ? 0 : 1);
        this._anim.set('to.opacity', showing ? 1 : 0);
      }

      // cleanup when animation is over
      this._anim.on(this._yuid + '|end', function () {

        this._anim = null;

        if (showing) {

          this._isShowing = false;

          // bind scroll events
          this._mousewheelEvent = Y.on(this.get('id') + '|mousewheel', this._onScroll, this);

          // bind resize events
          this._resizeEvent = Y.one(window).on(this.get('id') + '|resize', this._onResize, this);

        } else {

          this._isHiding = false;
          flyout.remove();
          mask.remove();

          // detach events
          if (this._mousewheelEvent) {
            this._mousewheelEvent.detach();
            this._mousewheelEvent = null;
          }
          if (this._resizeEvent) {
            this._resizeEvent.detach();
            this._resizeEvent = null;
          }

        }

        this.set('visible', showing);

        // fire event on the plugin
        this.fire(showing ? 'shown' : 'hidden', {
          flyout: flyout
        });

        // fire same event with nice name on the host
        this.get('host').fire('flyout-' + showing ? 'shown' : 'hidden', {
          flyout: flyout
        });

      }, this);

      if (showing) {
        this._isShowing = true;
      } else {
        this._isHiding = true;
      }

      // run time, baby.
      if (flyout.inDoc()) {
        this._anim.run();
      }
    },

    // other private methods
    /**
      Updates the position the flyout (and its mask)
      @method _updateMaskPosition
      @return Object An object with the x and y coordinates of the
        top left corner of the mask
    */
    _updateMaskPosition: function () {

      if (!this._mask) {
        return;
      }

      var mask = this._mask,
        maskPosition = this._getIntendedMaskPosition();

      if (mediaQueries.isSubDesktop() && window.innerHeight < maskPosition.y) {
        mask.setStyles({ // forces flyout to show on the bottom of the screen when on mobile and out of viewport
          left: maskPosition,
          top: null,
          bottom: (GRID_PX / 2) + 'px'
        });
      } else {
        mask.setStyles({
          left: maskPosition.x,
          top: maskPosition.y
        });
      }

      return maskPosition;
    },

    /**
      Based on the alignment, this method returns the initial off-screen
      coordinates of the top left corner of the flyoutNode. Coordinates
      based on the top left corner of the browser window as (0, 0)
      @method _getInitialFlyoutOffset
      @private
      @return Object An object with x and y properties
    */
    _getInitialFlyoutOffset: function () {

      var thisClass = Y.Squarespace.Animations.Flyout,
        alignment = this.get('alignment'),
        flyoutSize = this._measureNode(this.get('node'));

      var x,
        y;

      // x position
      switch (alignment) {
      case thisClass.LT:
      case thisClass.LC:
      case thisClass.LR:
        x = flyoutSize.width;
        break;

      case thisClass.TL:
      case thisClass.TC:
      case thisClass.TR:
      case thisClass.BL:
      case thisClass.BC:
        x = 0;
        break;

      case thisClass.RT:
      case thisClass.RC:
      case thisClass.RB:
        x = flyoutSize.width * -1;
        break;

      default:
        throw new Error('Flyout: This should never happened, check your alignment settings');
      }

      // y position
      switch (alignment) {
      case thisClass.LT:
      case thisClass.LC:
      case thisClass.LB:
      case thisClass.RT:
      case thisClass.RC:
      case thisClass.RB:
        y = 0;
        break;

      case thisClass.BL:
      case thisClass.BC:
      case thisClass.BR:
        y = flyoutSize.height * -1;
        break;

      case thisClass.TL:
      case thisClass.TC:
      case thisClass.TR:
        y = flyoutSize.height;
        break;

      default:
        throw new Error('Flyout: This should never happened, check your alignment settings');
      }

      return {
        xOffset: x,
        yOffset: y
      };
    },

    /**
      Based on the alignment, this method returns the final coordinates
      of the top left corner of the flyoutNode, which are the same coordiantes,
      as for the top left corner of the masking element. Coordinates based on the top
      left corner of the browser window as (0, 0)
      @method _getIntendedMaskPosition
      @private
      @return Object An object with x and y properties
    */
    _getIntendedMaskPosition: function () {

      var thisClass = Y.Squarespace.Animations.Flyout,
        alignment = this.get('alignment'),
        hostRegion = this.get('host').get('region'),
        flyoutSize = this._measureNode(this.get('node'));

      var x,
        y;

      // host region doesn't take into account the body element's scrolling,
      // so lets add it. I wonder why YUI would make that choice?....
      var scrollOffsetTop = Y.DOM.docScrollY(),
        scrollOffsetLeft = Y.DOM.docScrollX();

      hostRegion.top -= scrollOffsetTop;
      hostRegion.bottom -= scrollOffsetTop;
      hostRegion.left -= scrollOffsetLeft;
      hostRegion.right -= scrollOffsetLeft;

      // x position
      switch (alignment) {
      case thisClass.RT:
      case thisClass.RC:
      case thisClass.RB:
        x = hostRegion.right;
        break;

      case thisClass.LT:
      case thisClass.LC:
      case thisClass.LB:
        x = hostRegion.left - flyoutSize.width;
        break;

      case thisClass.TL:
      case thisClass.BL:
        x = hostRegion.left;
        break;

      case thisClass.TC:
      case thisClass.BC:
        x = hostRegion.left + hostRegion.width / 2 - flyoutSize.width / 2;
        break;

      case thisClass.TR:
      case thisClass.BR:
        x = hostRegion.right - flyoutSize.width;
        break;

      default:
        throw new Error('Flyout: This should never happened, check your alignment settings');
      }

      // y position
      switch (alignment) {
      case thisClass.TL:
      case thisClass.TC:
      case thisClass.TR:
        y = hostRegion.top - flyoutSize.height;
        break;

      case thisClass.LT:
      case thisClass.RT:
        y = hostRegion.top;
        break;

      case thisClass.LC:
      case thisClass.RC:
        y = hostRegion.top + hostRegion.height / 2 - flyoutSize.height / 2;
        break;

      case thisClass.LB:
      case thisClass.RB:
        y = hostRegion.bottom - flyoutSize.height;
        break;

      case thisClass.BL:
      case thisClass.BC:
      case thisClass.BR:
        y = hostRegion.bottom;
        break;

      default:
        throw new Error('Flyout: This should never happened, check your alignment settings');
      }

      return {
        x: x,
        y: y
      };
    }

  },
  // Static properties
  {
    NS: 'flyoutPlugin',
    // top edge alignments
    /**
      @property TL
      @description Constant used to specify the top edge, left corner for alignment
      @type String
      @value 'tl'
      @static
    */
    TL: 'tl',

    /**
      @property TC
      @description Constant used to specify the top edge, center point for alignment
      @type String
      @value 'tc'
      @static
    */
    TC: 'tc',

    /**
      @property TR
      @description Constant used to specify the top edge, right corner for alignment
      @type String
      @value 'tr'
      @static
    */
    TR: 'tr',

    // right edge alignments
    /**
      @property RT
      @description Constant used to specify the right edge, top point for alignment
      @type String
      @value 'rt'
      @static
    */
    RT: 'rt',

    /**
      @property RC
      @description Constant used to specify the right edge, center point for alignment
      @type String
      @value 'rc'
      @static
    */
    RC: 'rc',

    /**
      @property RB
      @description Constant used to specify the right edge, bottom point for alignment
      @type String
      @value 'rb'
      @static
    */
    RB: 'rb',

    // bottom edge alignments
    /**
      @property BC
      @description Constant used to specify the bottom edge, center point for alignment
      @type String
      @value 'bc'
      @static
    */
    BC: 'bc',

    /**
      @property BL
      @description Constant used to specify the bottom-left corner for alignment
      @type String
      @value 'bl'
      @static
    */
    BL: 'bl',

    /**
      @property BR
      @description Constant used to specify the bottom-right corner for alignment
      @type String
      @value 'br'
      @static
    */
    BR: 'br',

    // left edge alignments
    /**
      @property LT
      @description Constant used to specify the left edge, top point for alignment
      @type String
      @value 'lt'
      @static
    */
    LT: 'lt',

    /**
      @property LC
      @description Constant used to specify the left edge, center point for alignment
      @type String
      @value 'lc'
      @static
    */
    LC: 'lc',

    /**
      @property LB
      @description Constant used to specify the left edge, center point for alignment
      @type String
      @value 'lb'
      @static
    */
    Lb: 'lb',

    // YUI attributes
    ATTRS: {
      /**
        @attribute duration
        @description Duration of the open and close animations.
        @type Number
        @default 0.3
      */
      duration: {
        value: 0.3,
        validator: Y.Lang.isNumber
      },

      /**
        @attribute easing
        @description YUI easing function to use for the animation.
        @type Object
        @default Y.Easing.easeOutStrong
      */
      easing: {
        value: Y.Easing.easeOutStrong
      },

      /**
        @attribute alignment
        @description Where the flyout should fly out of. See the static
          properties for all the possible options.
        @type String
        @default 'rt'
      */
      alignment: {
        value: 'rt',
        validator: function (newVal) {
          var thisClass = Y.Squarespace.Animations.Flyout;
          switch (newVal) {
          case thisClass.TL:
          case thisClass.TC:
          case thisClass.TR:
          case thisClass.LT:
          case thisClass.RT:
          case thisClass.LC:
          case thisClass.RC:
          case thisClass.LB:
          case thisClass.RB:
          case thisClass.BL:
          case thisClass.BC:
          case thisClass.BR:
            return true;
          default:
            if (__DEV__) {
              console.warn(this.name + ': Invalid alignment value (' + newVal + ')');

            }
            return false;
          }
        }
      },

      /**
        @attribute node
        @description What node should be flying out
        @type Y.Node
        @default null
      */
      node: {
        value: null
      },

      /**
        @attribute animateOpacity
        @description By default, we like to animate the opacity as
          we wipe because it looks great. If you must turn that behavior
          off, set this to false
        @type Boolean
        @default true
      */
      animateOpacity: {
        value: true
      },

      /**
       * @description The node onto which we are going to render the node flyout.
       * By default the host's body element.
       * @type {Node}
       * @attribute renderTarget
       * @default Body Element
       */
      renderTarget: {
        valueFn: function () {
          var host = this.get('host');

          if ((host instanceof Y.Node) && host.ancestor('body')) {
            return host.ancestor('body');
          }

          return Y.one('body');
        }
      },

      /**
       * @attribute overflow
       * @description Defines the overflow value for the flyout's mask.
       * Set to 'visible' if flyout has a shadow.
       * @type String
       * @default 'hidden'
       */
      overflow: {
        value: 'hidden'
      },

      /**
        @attribute visible
        @description Whether or not the flyout is currently visible
        @type Boolean
        @default false
        @readOnly
      */
      visible: {
        value: false
      }
    }
  });

}, '1.0', { requires: [
  'base',
  'node',
  'plugin',
  'squarespace-node-utils'
] });
