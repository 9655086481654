import { t } from 'shared/i18n';
/**
* Home of the infamous Gizmo base class, what you should be using for any js object that
* renders to the page, and some that don't, if they need facilities for cleaning up timers,
* events, callbacks, and subscriptions.
* @module squarespace-gizmo
*/
YUI.add('squarespace-gizmo', function (Y) {

  Y.namespace('Squarespace');

  // Constants used by Gizmo
  var CLASS_PREFIX = 'squarespace-';
  var BBOX_SUFFIX = '-bbox';
  var SPACE_RE = new RegExp('[ ]+', 'g');
  var CHAR_RE = new RegExp('[^a-zA-Z0-9\\-]', 'g');

  var _evWalker_ = function (array, fn, keys, vals, reject) {
    var walker = reject ? Y.Array.reject : Y.Array.filter;
    return walker(array, function (i) {
      for (var k = 0; k < keys.length; ++k) {
        var key = keys[k];
        var val = vals[k];
        if (i[key] !== val) {
          return false;
        }
      }
      if (fn) {
        fn(i);
      }
      return true;
    });
  };

  // Internally used by _subscribe and _subcribeOnce to perform the actual
  // subscription
  var _sub_ = function (subFn, obj, event, callback, context) {// ಠ_ಠ
    if (this._destroyed) {
      return;
    }
    if (Y.Lang.isArray(obj)) {
      return Y.Array.map(obj, function (o) {
        return _sub_.apply(this, [subFn].concat(Y.Array(o)));
      }, this);
    }
    if (Y.Lang.isObject(obj) && Y.Lang.isFunction(obj[subFn])) {
      var args = [event, callback, context || this];
      args = args.concat(Array.prototype.slice.call(arguments, 5));
      var h = obj[subFn].apply(obj, args);
      this._eventSubList.push({ object: obj, event: event, eventSub: h });
      return h;
    }

    throw new Error('Gizmo[' + this._name + ']: Could not subscribe to event: ' + event);

  };

  /**
  * Gizmo is the new boilerplate.
  *
  * This is a base class implementation for objects that render themselves to HTML.
  * It provides basic functionality and a consistent interface for these objects.
  * It manages the object lifecycle, including event subscriptions and active animations, along
  * with timers and callbacks. It can also set up parent/child relationships, destroying its children
  * automatically when it gets destroyed.
  * Every Gizmo renders into a bounding box. The bounding box is a div with a class generated from
  * the Gizmo's name, eg "squarespace-gizmo-bbox".
  *
  * Events:
  * "render" - fired when rendering starts
  * "rendered" - fired when rendering is finished
  * "destroy" - fired when destroy starts
  * "destroyed" - fired after gizmo is destroyed
  *
  * Using Gizmo Checklist
  *
  * -  Extend Gizmo - Class.extend(Y.Squarespace.Gizmo, {});
  * -  Define _name
  * -  Define _events
  * -  In initialize(params) call this._super(params);
  * -  Define _render(). It should return a Y.Node
  * -  Define _destroy() if you need to have custom clean-up code
  * -  Use this._subscribe to subscribe to events
  * -  Use this._anim to create animations
  * -  Use this._removeAnim to remove animations
  *
  * @class Gizmo
  * @namespace Squarespace
  */
  Y.Squarespace.Gizmo = Class.create({
    /**
    * The name of this Gizmo. Override this.
    * @property _name
    * @protected
    */
    _name: 'Gizmo',

    /**
    * Events published by this Gizmo. Override this.
    * Format is "eventName": { config } where config specifies configuration keys
    * that are valid for Y.EventTarget.Publish
    *   (http://yuilibrary.com/yui/docs/api/classes/EventTarget.html#method_publish)
    * If an event's config has broadcast set to a truthful value, the event type will automatically be prefixed
    * with the Gizmo's name. For example, a class named "Gizmo" with an event "myevent" will be "Gizmo:myevent".
    * @property _events
    * @type Object
    * @protected
    */
    _events: {
      'render': {},
      'rendered': {},
      'destroy': {},
      'destroyed': {} },


    /**
    * Called at object construction. You should call this from your initialize function, like this:
    * this._super(params);
    * @constructor
    * @method initialize
    * @param params {Object} Stored in the object, use it later! this.params
    * @protected
    */
    initialize: function (params) {
      // Augment this object to be an event target.
      // Set prefix to be the class name
      Y.augment(this, Y.EventTarget, true, null, {
        prefix: this._name });


      this.params = Y.merge(params);
      this._initState();

      // Publish any custom events going up the prototype chain
      var p = this;
      var pub = function (config, event) {
        this.publish(event, config);
      };

      while (p !== null && p !== undefined) {
        Y.Object.each(p._events, pub, this);
        p = p.superclass;
      }
    },

    /**
     * Returns an array of class names in the hierarchy for this Gizmo.
     * The array is ordered from child to parent.
     *
     * @method getClassNames
     * @return the class names in this objects inheritance hierarchy
     */
    getClassNames: function () {
      var currentClass = this;
      var results = [];
      for (;; currentClass = currentClass.superclass) {// ಠ_ಠ
        if (!currentClass) {
          return results;
        }
        results.push(currentClass.getClassName());
      }
    },

    /**
    * Used internally to initialize state tracked by this Gizmo
    * @method _initState
    * @protected
    */
    _initState: function () {
      this._el = null;
      this._parentEl = null;
      // Stores a list of {object, event, eventSub} for all existing event subscriptions
      this._eventSubList = [];
      this._anims = [];
      this._timers = [];

      this._destroyed = false;
      this.destroyed = false; // @TODO remove this. -nh & ja

      // List of children Gizmos (or any object with a .destroy() method)
      this._children = [];

      this._guid = Y.guid();

      if (!this._name) {
        this._name = t("No Name");


      }
    },

    /*
    * Get the name of this class
    * @method getClassName
    * @return {String} The name of this class
    * @protected
    */
    getClassName: function () {
      return this._name;
    },

    /*
    * Gets the unique id for this instance.
    * @method getId
    * @return {String} An id for this class, unique to all yui instances
    * @protected
    */
    getId: function () {
      return this._guid;
    },

    /*
    * Return a unique CSS class name generated
    * from the name of this Gizmo
    * @method getCssClassName
    * @return {String} The CSS class for this Gizmo
    */
    getCssClassName: function () {
      var name = this._name;
      name = name.trim().
      replace(SPACE_RE, '-').
      replace(CHAR_RE, '').
      toLowerCase();
      return CLASS_PREFIX + name;
    },

    /**
    * Returns the wrapper node for this gizmo
    * @method getElement
    * @protected
    * @return {Y.Node} The YUI node that wraps the html for this element
    */
    getElement: function () {
      return this._el;
    },

    /*
    * Call to render this object. If parent exists and is a YUI Node, the rendered
    * object will be appended to parent. The parent node is stored in this._parentEl.
    * Subsequent calls to render without the parent argument will attempt to reuse
    * this._parentEl as the parent node.
    * @method render
    * @protected
    * @param parent {Object} A parent object (probably should be a Gizmo sub class) that implements append(),
    * which will append this Gizmo to it.
    */
    render: function (parent) {
      this.fire('render');

      parent = parent || this._parentEl;

      if (this.params.noBoundingBox) {
        this._el = this._render();
      } else {
        // Create a bounding box that will wrap the content of this Gizmo
        this._el = Y.Node.create('<div></div>');
        this._el.addClass(this.getCssClassName() + BBOX_SUFFIX);
        this._el.append(this._render());
      }

      if (parent && Y.Lang.isFunction(parent.append)) {
        parent.append(this._el);
      }

      this._parentEl = parent;

      this.fire('rendered');
    },

    /*
    * Override. Should return the rendered content for this Gizmo.
    * @method _render
    * @protected
    * @return {Y.Node} The YUI Node wrapping the rendered content for this Gizmo
    */
    _render: function () {
    },

    /*
    * Subscribe this Gizmo to an event returning a handle to the event subscription.
    * obj should be an EventTarget, or the global Y
    * event is the event name as a string
    * callback is a method on this Gizmo (callback context will be 'this')
    * context specifies the context of the callback. Optional (defaults to this)
    * any additional arguments will be passed to the callback
    *
    * Can also subscribe to multiple events at the same time by passing a list of arguments in
    * the same format, i.e.
    * <pre>
    * this._subscribe([
    *   [obj, event, callback, context],
    *   [obj, event, callback, context, addArg1, addArg2]
    * ]);
    * </pre>
    *
    * @method _subscribe
    * @protected
    * @param eventTargetObject {Object} An object that implements event target, so .on() can be called on it
    * @param eventName {String} The event name
    * @param callback {Function} The function to call when the event fires
    * @param context {Object} The value of this in the callback
    * @param args* {Mixed*} Any number of additional arguments to pass to the callback
    * @return {EventHandle} An event handle you can use to detach the event later
    */
    _subscribe: function (obj, event, callback, context) {
      var args = ['on'].concat(Y.Array(arguments));
      return _sub_.apply(this, args);
    },

    /**
    * Same as _subscribe but will only respond one firing of an event
    *
    * Can also subscribe to multiple events at the same time by passing a list of arguments in
    * the same format, i.e.
    * this._subscribe([
    *   [obj, event, callback, context],
    *   [obj, event, callback, context, addArg1, addArg2]
    * ]);
    *
    * @method _subscribeOnce
    * @protected
    * @param eventTargetObject {Object} An object that implements event target, so .on() can be called on it
    * @param eventName {String} The event name
    * @param callback {Function} The function to call when the event fires
    * @param context {Object} The value of this in the callback
    * @param args* Any number of additional arguments to pass to the callback
    * @return {EventHandle} An event handle you can use to detach the event later
    */
    _subscribeOnce: function (obj, event, callback, context) {
      var args = ['once'].concat(Y.Array(arguments));
      return _sub_.apply(this, args);
    },

    /**
    * Same as _subscribe but will respond before the invocation of the defaultFn for the event, if there is one.
    *
    * Can also subscribe to multiple events at the same time by passing a list of arguments in
    * the same format, i.e.
    * this._subscribe([
    *   [obj, event, callback, context],
    *   [obj, event, callback, context, addArg1, addArg2]
    * ]);
    *
    * @method _subscribeBefore
    * @protected
    * @param eventTargetObject {Object} An object that implements event target, so .on() can be called on it
    * @param eventName {String} The event name
    * @param callback {Function} The function to call when the event fires
    * @param context {Object} The value of this in the callback
    * @param args* Any number of additional arguments to pass to the callback
    * @return {EventHandle} An event handle you can use to detach the event later
    */
    _subscribeBefore: function (obj, event, callback, context) {
      var args = ['before'].concat(Y.Array(arguments));
      return _sub_.apply(this, args);
    },

    /**
    * Same as _subscribe but will respond after the invocation of the defaultFn for the event, if there is one.
    *
    * Can also subscribe to multiple events at the same time by passing a list of arguments in
    * the same format, i.e.
    * this._subscribe([
    *   [obj, event, callback, context],
    *   [obj, event, callback, context, addArg1, addArg2]
    * ]);
    *
    * @method _subscribeAfter
    * @protected
    * @param eventTargetObject {Object} An object that implements event target, so .on() can be called on it
    * @param eventName {String} The event name
    * @param callback {Function} The function to call when the event fires
    * @param context {Object} The value of this in the callback
    * @param args* Any number of additional arguments to pass to the callback
    * @return {EventHandle} An event handle you can use to detach the event later
    */
    _subscribeAfter: function (obj, event, callback, context) {
      var args = ['after'].concat(Y.Array(arguments));
      return _sub_.apply(this, args);
    },

    /*
    * Remove every subscription this Gizmo has to event on object obj.
    *
    * @method _unsubscribe
    * @protected
    * @param obj {Object} The object to unsubscribe
    * @param event {String} The name of the event to unsubscribe this object from
    */
    _unsubscribe: function (obj, event) {
      var keys = event ? ['object', 'event'] : ['object'];
      this._eventSubList = _evWalker_(this._eventSubList, function (e) {
        e.eventSub.detach();
      }, keys, Y.Array(arguments), true);
    },

    /*
    * Unsubscribe from a specific event.
    * Detach from eventSub which should be a handle returned.
    * by _subscribe.
    * @method _detach
    * @protected
    * @param eventSubscriptionHandle {EventHandle} A handle returned from _subscribe or _subscribeOnce
    */
    _detach: function (eventSub) {
      this._eventSubList = _evWalker_(this._eventSubList, function (e) {
        e.eventSub.detach();
      }, ['eventSub'], [eventSub], true);
    },

    /**
    * Clears all events on this Gizmo
    * @method _clearEvents
    * @protected
    */
    _clearEvents: function () {
      var es = this._eventSubList;
      for (var i = 0; i < es.length; ++i) {
        es[i].eventSub.detach();
      }
      this._eventSubList = [];
    },

    /**
    * Call to destroy this Gizmo. Cleans up events, timers, subscriptions,
    * animations, and callbacks.
    *
    * @method destroy
    * @protected
    */
    destroy: function () {
      if (this._destroyed) {
        if (__DEV__) {
          console.warn('Gizmo[' + this._name + '] already destroyed.');
        }
        return;
      }
      if (!this._eventSubList) {
        if (__DEV__) {
          console.error('Gizmo not initialized for...', this);
        }
        throw new Error('Gizmo[' + this._name + '] was never initialzed.  Missing _super?');
      }

      this.fire('destroy');
      this._destroy();

      var i = 0;

      this._clearEvents();
      this._eventSubList = [];

      // shallow copy of the _anims
      var toRemove = [];
      Y.Array.each(this._anims, function (anim) {
        toRemove.push(anim);
      }, this);

      // stop all animations
      Y.Array.each(toRemove, function (anim) {
        if (anim.get('running')) {
          anim.stop(false); // don't complete the animation, doing that causes flicker
        }
        anim.destroy();
      });
      this._anims = null;

      var cs = this._children;
      while (cs.length > 0) {
        var c = cs[0];
        c._removeParent();
        c.destroy();
      }
      this._children = null;

      var ts = this._timers;
      for (i = 0; i < ts.length; ++i) {
        var timer = ts[i];
        timer.cancel();
      }
      this._timers = null;

      if (this._el && Y.Lang.isFunction(this._el.remove)) {
        this._el.remove();
        this._el = null;
      }

      this._destroyed = true;
      this.destroyed = true;

      this.fire('destroyed');
    },

    /**
    * Optionally override. Called at start of destroy(). An easy way to perform
    * additional cleanup logic from your subclass
    * @method _destroy
    * @protected
    */
    _destroy: function () {
    },


    /**
    * Returns true if this Gizmo has been destroyed.
    * @method isDestroyed
    * @protected
    * @return {Boolean} Whether or not this Gizmo has been destroyed
    */
    isDestroyed: function () {
      return this._destroyed;
    },

    /**
    * Set the parent of this gizmo
    * @method _setParent
    * @protected
    * @param parent
    */
    _setParent: function (parent) {
      this._parentGizmo = parent;
    },

    _removeParent: function () {
      if (this._parentGizmo) {
        this._parentGizmo._removeChild(this);
        this._parentGizmo = null;
      }
    },

    /**
    * Add a child that will be owned by this Gizmo.
    * Child will be destroyed (using child.destroy()) when this
    * Gizmo is destroyed
    * @method _addChild
    * @protected
    * @param child {Object} An object that properly implements destroy to add as a child (ideally a Gizmo)
    */
    _addChild: function (child) {
      if (!this._children) {return;}
      if (child._setParent) {child._setParent(this);}
      this._children.push(child);
    },

    _removeChild: function (child) {
      this._children.splice(this._children.indexOf(child), 1);
    },

    _getChildren: function () {
      return this._children;
    },

    /**
    * Create a new Y.Anim and record its handle. Causes animation to be cleaned up when
    * this Gizmo is destroyed. Always use this instead of new Y.Anim
    * @method _anim
    * @protected
    * @param animationConfiguration {Object} The same animation config object you would have passed to Y.Anim
    * @return {Y.Anim} An animation instance
    */
    _anim: function (animation) {

      if (this._destroyed) {
        return;
      }
      if (!animation.node) {
        throw new Error('Gizmo[' + this._name + ']: Animation must specify a node!');
      }

      // NOT A HACK: If the animation node doesn't exist or isn't in the DOM, return
      // an empty animation so that it doesn't break when it tries to be used & run().
      // Love, Dio, Naz & Tony.
      var a;
      if (!animation.node.ancestor('body', true)) {// node not in the dom

        if (__DEV__) {
          console.warn('Gizmo[' + this._name +
          ']: _anim passed a YUI node not in the DOM! Returning an empty animation.');
        }
        // console.trace();
        a = new Y.Anim();

      } else if (!animation.node._node) {// yui node isn't pointing to an actual DOM node
        if (__DEV__) {
          console.warn('Gizmo[' + this._name +
          ']: _anim passed a YUI node with _node = null! Returning an empty animation.');
          console.trace();
        }

        a = new Y.Anim();

      } else {

        a = new Y.Anim(animation);
        a.on('end', function (e) {
          this._removeAnim(a);
        }, this);
        this._anims.push(a);

      }
      return a;
    },

    /**
    * Remove a handle to an animation.
    * @method _removeAnim
    * @protected
    * @param animation {Y.Anim} The animation to remove
    */
    _removeAnim: function (animation) {
      var i = this._anims.indexOf(animation);
      if (i !== -1) {
        this._anims.splice(i, 1);
      }
    },

    /**
    * For debugging events. To be used in place of .fire(), so this._trace("event")
    * Prints to the console
    * @method _trace
    * @protected
    * @param event {String} The name of the event to fire and trace
    */
    _trace: function (event) {
      var e = this.getEvent(event);
      var subs = e.getSubs();
      var cnames = [];
      var nameBuilder = function (v, k) {
        if (v.context && v.context.getName) {
          cnames.push(v.context.getName());
        } else {
          var o = {};
          o[v.fn.name] = v.fn.toString();
          cnames.push(o);
        }
      };
      for (var i = 0; i < subs.length; ++i) {
        var s = subs[i];
        Y.Object.each(s, nameBuilder);
      }
      if (__DEV__) {
        console.log('[trace] Event', event, 'is notifying the following:', cnames);
      }
      this.fire(event);
    },

    /**
    * Creates a new timer to execute 'fn' when milliseconds later.
    * when milliseconds to wait
    * fn is the function to execute
    * o is the context and is optinal (defaults to this)
    * data is an argument or array of arguments passed to fn
    * periodic is a boolean that if true will run this timer continuously at the interval supplied by when
    * Uses Y.later internally.
    * @method _later
    * @protected
    * @param when {Number} the number of milliseconds to wait until the fn is executed.
    * @param fn {Function} the function to run when the time has elapsed
    * @param context {Object} the value of this in the function
    * @param data {Object} [Array] data that is provided to the function. This accepts either a single item or an array.
    *  If an array is provided, the function is executed with one parameter for each array item.
    *  If you need to pass a single array parameter, it needs to be wrapped in an array [myarray].
    *  Note: native methods in IE may not have the call and apply methods.
    *  In this case, it will work, but you are limited to four arguments.
    * @param periodic {Boolean} if true, executes continuously at supplied interval until canceled.
    * @return {Object} The timer object
    */
    _later: function (when, fn, o, data, periodic) {
      if (this._destroyed) {
        return;
      }
      o = o || this;
      var timer = Y.later(when, o, fn, data, periodic);
      this._timers.push(timer);
      return timer;
    },

    /**
    * The safe callback wrapper. It wraps a callback function with a check to make sure this Gizmo
    * has not already been destroyed. Wrap success and failure functions sent to
    * Y.Data in this.
    * @method _cb
    * @protected
    * @param fn {Function} The callback function you want to make child proof
    */
    _cb: function (fn) {
      var c = Y.bind(function () {
        if (!this._destroyed) {
          return fn.apply(this, arguments);
        }
      }, this);

      return c;
    } });



  Y.augment(Y.Squarespace.Gizmo, Y.EventTarget);


  /**
   * A Gizmo that resurrects after getting destroyed.
   * DON'T USE THIS. It's temporary and only for EditingDialog, which is
   * undead, as we all know.
   * @class ZombieGizmo
   * @namespace Squarespace
   * @constructor
   */
  Y.Squarespace.ZombieGizmo = Class.extend(Y.Squarespace.Gizmo, {

    _name: 'ZombieGizmo',

    _events: {
      'resurrect': {},
      'resurrected': {} },


    // Same as Gizmo's initialize
    initialize: function (params) {
      this._super(params);
    },

    // Same as Gizmo's destroy, but resurrect after the call
    destroy: function () {
      this._super();
      this.resurrect();
    },

    // Automatically gets called after a destroy() and resurrects this object.
    // Resets the state of the object to its initial state (like after a new Gizmo object is created).
    resurrect: function () {
      // BRAAAAAIIIINNNNSSSS!!!
      this.fire('resurrect');
      this._initState();
      this.fire('resurrected');
    } });


}, '1.0', { requires: [
  'array-extras',
  'event-custom',
  'node'] });