/**
 *
 *                  uuuuuuu
 *              uu$$$$$$$$$$$uu
 *           uu$$$$$$$$$$$$$$$$$uu
 *          u$$$$$$$$$$$$$$$$$$$$$u
 *         u$$$$$$$$$$$$$$$$$$$$$$$u
 *        u$$$$$$$$$$$$$$$$$$$$$$$$$u
 *        u$$$$$$$$$$$$$$$$$$$$$$$$$u
 *        u$$$$$$"   "$$$"   "$$$$$$u
 *        "$$$$"      u$u       $$$$"
 *         $$$u       u$u       u$$$
 *         $$$u      u$$$u      u$$$
 *          "$$$$uu$$$   $$$uu$$$$"
 *           "$$$$$$$"   "$$$$$$$"
 *             u$$$$$$$u$$$$$$$u
 *              u$"$"$"$"$"$"$u
 *   uuu        $$u$ $ $ $ $u$$       uuu
 *  u$$$$        $$$$$u$u$u$$$       u$$$$
 *   $$$$$uu      "$$$$$$$$$"     uu$$$$$$
 * u$$$$$$$$$$$uu    """""    uuuu$$$$$$$$$$
 * $$$$"""$$$$$$$$$$uuu   uu$$$$$$$$$"""$$$"
 *  """      ""$$$$$$$$$$$uu ""$"""
 *            uuuu ""$$$$$$$$$$uuu
 *   u$$$uuu$$$$$$$$$uu ""$$$$$$$$$$$uuu$$$
 *   $$$$$$$$$$""""           ""$$$$$$$$$$$"
 *    "$$$$$"                      ""$$$$""
 *      $$$"                         $$$$"
 *
 * Stay out!!! Turn back!
 **
 * @module squarespace-dialog-legacy-multi-frame
 */
YUI.add('squarespace-dialog-legacy-multi-frame', function(Y) {

  Y.namespace('Squarespace.DialogFieldGenerators');

  /**
      What do I do? Someone fill this documentation in
      @class multi-frame
      @namespace Squarespace.DialogFieldGenerators
      @extends Squarespace.DialogField
      @constructor
     */
  Y.Squarespace.DialogFieldGenerators['multi-frame'] = Class.extend(Y.Squarespace.DialogField, {

    initialize: function (config, initialData, panel) {

      this._super(config, initialData, panel);

      this.frames = { };
      this.activeEl = null;
      this.value = this.config.initialFrame;
      this.panel.bodyEvents.push(Y.on('click', this.onClick, this.html, this));

    },

    onClick: function (e) {

      this.panel.fire('datachange', this);

    },

    show: function (name) {

      var f = this.frames[name];
      if (!!f && f === this.activeEl) {
        return;
      }

      if (this.activeEl) {

        if (this.activeEl.a) {
          this.activeEl.a.stop();
        }

        var previouslyActiveFields = this.activeEl.fields;
        this.activeEl.a =
          this._anim({ node: this.activeEl.el, to: { height: 0 }, duration: 0.3, easing: Y.Easing.easeOutStrong });
        previouslyActiveFields.forEach(this.setInActiveFrame(false), this);
        this.activeEl.a.on('end', function (e) {
          e.target.get('node').addClass('hidden');
        }, this);
        this.activeEl.a.run();

      }

      if (f.a) {
        f.a.stop();
      }

      if (this.activeEl) {
        f.el.ancestor().insertBefore(f.el, this.activeEl.el.next());
      }

      f.el.removeClass('hidden');

      // evaluate the "real height"
      var originalHeight = f.el.getStyle('height');
      f.el.setStyles({ height: null });
      f.realHeight = f.el.get('offsetHeight');
      f.el.setStyles({ height: originalHeight });

      f.a = this._anim({ node: f.el, to: { height: f.realHeight }, duration: 0.3, easing: Y.Easing.easeOutStrong });
      f.a.on('end', function (e, f) { // eslint-disable-line no-shadow

        // fix inline title positions

        for (var i = 0; i < f.fields.length; ++i) {
          var fo = this.panel.fields[f.fields[i].name];
          if (fo && fo.updateInlineTitle) {
            fo.updateInlineTitle();
          }
        }

        // fix focus

        this.focusCurrentFrame();

      }, this, f);
      f.a.run();
      f.fields.forEach(this.setInActiveFrame(true), this);

      this.activeEl = f;
      this.value = name;

    },

    focusCurrentFrame: function () {
      var activeField;
      for (var i = 0; i < this.activeEl.fields.length; ++i) {
        activeField = this.activeEl.fields[i];

        var f = this.panel.fields[activeField.name || Y.Object.getValue(activeField, ['config', 'name'])];

        if (Y.Lang.isValue(f) && f.getNode) {
          f.getNode().focus();
          return;
        }
      }

    },

    setInActiveFrame: function(val) {
      return function(field) {
        if (field.name) {
          var _field = this.panel.getField(field.name);
          _field.inActiveFrame = val;
          _field.fire('mutli-frame-focus');
        }
      };
    },

    append: function (tabData, fields, appendTo, availableWidth) {


      for (var fname in this.config.frames) {

        var frameData = this.config.frames[fname];
        var frameDiv = Y.Node.create('<div class="frame-wrapper clear"></div>');

        appendTo.append(frameDiv);

        this.panel._renderFields(tabData, frameData.fields, frameDiv, availableWidth);

        this.frames[fname] = this.config.frames[fname];
        this.frames[fname].el = frameDiv;
        this.frames[fname].realHeight = frameDiv.get('offsetHeight');

        // hide?

        if (fname != this.config.initialFrame) { // eslint-disable-line eqeqeq

          frameDiv.setStyle('height', '0px');
          frameDiv.addClass('hidden');
          this.frames[fname].visible = false;
          this.config.frames[fname].fields.forEach(this.setInActiveFrame(false), this);

        } else {

          this.activeEl = this.frames[fname];
          this.frames[fname].visible = true;
          this.config.frames[fname].fields.forEach(this.setInActiveFrame(true), this);
        }

      }

    }

  });

}, '1.0', { requires: [
  'anim',
  'squarespace-dialog-fields',
  'squarespace-util',
  'widget'
] });
