/**
 * Setup localization from SQUARESPACE_CONTEXT
 */
import 'shared/i18n/bootstrap';

import lodashGet from 'lodash/get';
import React from 'react';
import ReactDOM from 'react-dom';
import { t } from 'shared/i18n';
import substituteString from 'shared/utils/formatting/substituteString';
import __legacyRouterHooks from 'shared/utils/__legacyRouterHooks';
import { mediaQueries } from '@sqs/universal-utils';
import { createUrlSafeString } from 'shared/utils/UrlUtils';
import TabbedHeader from 'apps/App/shared/components/TabbedHeader';
import { cancelEditCollectionItem } from 'shared/utils/CMSV7Events/collection';
import isAppleiPadOS13 from 'shared/utils/isAppleiPadOS13';
import { yuiDialogClassName, yuiDialogOverlayClassName } from 'shared/constants/Dialogs';

/**
* Squarespace's Core Dialog System, mainly contains the dialog class.
* @module squarespace-dialog
*/
YUI.add('squarespace-dialog', function (Y) {

  // Should this be an Enum/Const?
  // z-index notes
  //    screen-overlay: 29999
  //    dialog: 30000cancel
  //    tabs: 300
  //    field-text: 310
  //    errors: 400
  //    flyouts: 500
  //    saveOverlay: 10000
  //

  Y.namespace('Squarespace');

  /**
  * An array of open dialogs
  * @property OPEN_DIALOGS
  * @type [EditingDialog]
  * @namespace Squarespace
  */
  Y.Global.OPEN_DIALOGS = Y.Global.OPEN_DIALOGS || [];

  /**
  * Dialog state constants
  * @property DialogStates
  * @type Object
  * @namespace Squarespace
  */
  Y.Squarespace.DialogStates = {

    CLOSED: 1,
    EDITING: 2,
    LOADING: 3,
    CLOSING: 4,
    SAVING: 5,
    MOVING: 6 };



  /*
   The following is an example of a dialog that spawns a multi option.
  This is the minimum configuration required for a dialog.
   var dialog = new Y.Squarespace.EditingDialog({
    width: 400,
    title: 'Hello, world!',
    fields: [
      {
        ctor: Y.Squarespace.DialogFields.MultiOption,
        config: {
          options: [
            {
              title: 'One'
            },
            {
              title: 'Two'
            }
          ]
        }
      }
    ],
    buttons: [
      {
        title: 'Dismiss',
        type: 'cancel'
      }
    ]
  });
   dialog.show();
   */





  /**
  * The Squarespace Dialog System.
  *
  * Dialog was designed to provide a consistent UI for editing fields
  * in a modal view. The Squarespace Dialog System works in conjunction with the
  * construct of a DialogField (a Dialog built of DialogFields).
  *
  * There are wrappers build around the Squarespace Dialog System that make it easier to deal
  * with negotiations between a DialogField and Model (take a look at ModelEditor).
  *
  * @class EditingDialog
  * @namespace Squarespace
  * @constructor
  * @extends Squarespace.ZombieGizmo
  * @param {Object} Dialog parameters
  */
  Y.Squarespace.EditingDialog = Class.extend(Y.Squarespace.ZombieGizmo, {

    _name: 'EditingDialog',

    // SEE UTIL.JS FOR SUPPORTED DIALOG STATES

    _events: {
      /**
      * Fired when the dialog starts the show process
      * @event show
      */
      'show': {},

      /**
      * Fired when the dialog compeletes the show process
      * @event shown
      */
      'shown': {},

      /**
      * Fired when the dialog compeletes the show process
      * @event shown
      */
      'aftershowanim': {},

      /**
      * Fired when the dialog begins to align itself to an
      * anchor
      * @event align-to-anchor
      */
      'align-to-anchor': {},

      /**
      * Fired when the dialog completes aligning itself
      * to an anchor
      * @event aligned-to-anchor
      */
      'aligned-to-anchor': {},

      /**
      * UNCLEAR: PLEASE FILL THIS IN. WHAT DOES IT MEAN EXACTLY?
      * @event loading
      */
      'loading': {},

      /**
      * UNCLEAR: PLEASE FILL THIS IN. WHAT DOES IT MEAN EXACTLY?
      * @event cancel-loading
      */
      'cancel-loading': {},

      /**
      * UNCLEAR: PLEASE FILL THIS IN. WHAT DOES IT MEAN EXACTLY?
      * @event loading-ready
      */
      'loading-ready': {},

      /**
      * UNCLEAR: PLEASE FILL THIS IN. WHAT DOES IT MEAN EXACTLY?
      * @event ready
      */
      'ready': {}, // is this the same as shown or what?

      /**
      * Fires when the dialog is dragged
      * @event drag
      */
      'drag': {},

      /**
      * Fires when the dialog begins the hide process
      * Enable hiding logic needs cleanup (where this.params.hideable is)
      * @event hide
      */
      'hide': {},

      /**
      * Fires when the dialog completes hiding
      * @event hidden
      */
      'hidden': {},

      /**
      * Fires when the dialog starts to close
      * @event close
      */
      'close': {},

      /**
      * Fires when the dialog finished closing
      * @event closed
      */
      'closed': {},

      /**
      * Fires when the dialog completes cancelation (post dismissal animation)
      * (This doesn't actually seem to fire when expected, see 'dismiss' instead)
      * @event canceled
      */
      'canceled': {}, // when the dialog finishes the animation of canceling.

      /**
      * Fires when the cancel button is clicked.
      * the 'cancel' has other logic backed into it and does not always fire :(
      * @event cancel-clicked
      */
      'cancel-clicked': {}, // when the dialog finishes the animation of canceling.

      /**
       * Fires when the user clicks on the overlay (behind the dialog). Usually
       * for cancellation.
       *
       * @event overlay-click
       */
      'overlay-click': {},

      /**
      * UNCLEAR: PLEASE FILL THIS IN. WHAT DOES IT MEAN EXACTLY?
      * @event render-anchor
      */
      'render-anchor': {}, // What does this mean?

      /**
      * Fire this on a dialog to inform it something has changed so it will respond.
      * Its odd because its legacy...
      * @event datachange
      */
      'datachange': {},

      /**
      * Fired when the dialog is finished updating in response to a datachange event
      * @event datachange
      */
      'datachanged': {},

      /**
      * Fires when a data send is requested by the dialog (when the save/continue button
      * is clicked)
      * @event send-requested
      */
      'send-requested': { emitFacade: true },

      /**
      * Fires when a 'remove' typed button is clicked.
      * @event remove-requested
      */
      'remove-requested': {},

      /**
      * Fires when a dialog save is successful/completed
      * @event data-saved
      */
      'data-saved': {},

      /**
      * (this appears to be unhandled, look at auto-save-requested)
      * Fires when a dialog should autosave (should be auto-save-requested?)
      * @event auto-save
      */
      'auto-save': {},

      /**
      * Fires when a dialog should autosaved
      * @event auto-save-requested
      */
      'auto-save-requested': { emitFacade: true },

      /**
      * Fires when allow-editing process is beginning, to put the dialog back in an
      * editable state after a save was requested
      * @event allow-editing
      */
      'allow-editing': {},

      /**
      * Fires when allow-editing process is completed and the dialog is back in
      * an editable state
      * @event allow-editing
      */
      'editing-allowed': {},

      /**
      * Fired when showing form errors received from server
      * @event show-errors
      */
      'show-errors': {},

      /**
      * Fires when local validation errors are found before data send
      * @event local-errors
      */
      'local-errors': {}

      /**
      * Fires when a button in the button block is clicked.
      * @event 'button-' + buttonType
      */
      // can't be published here because of dynamic name
    },

    initialize: function (params) {

      this._super(params);
      this.setParams(params);

      this._setState('CLOSED');

      this.bodyEvents = [];
      this.buttonEvents = [];
      this.globalEvents = [];
      this.childDialogs = [];
      this.verticalFields = [];
      this.timers = [];
      this.fields = {};
      this.reactComponentContainers = [];
      this.reactEls = [];
      this.sections = {}; // sections are div groups within a dialog
      this._noNameFields = [];

      this._setEdited(false);

      this.NOTCH_WIDTH = 20;
      this.BUTTONS_BASE_IDX = 100;
      this.NOTCH_HEIGHT = 11;
      this.lastTabIndex = 1;

      this.on('datachange', this.onDataChange, this);

      this.on('duplicate', this.markDuplicateLifecycle, this);

      if (!this.__legacyRouterUnlisten) {
        this.__legacyRouterUnlisten = __legacyRouterHooks.onChange(this.close.bind(this), false);
      }

      if (!this.__legacyRouterUnlistenBefore) {
        this.__legacyRouterUnlistenBefore = __legacyRouterHooks.onBeforeChange(function (location, callback) {
          if (this.isVisible() && this.params.discardChangesConfirmation) {

            // a clear example of why we no longer use the callback pattern
            var canClose = this.canClose(
            // save
            function () {
              this.saveAndClose();
              callback();
            }.bind(this),

            // discard
            function () {
              this.cancel();
              callback();
            },

            // cancel
            function () {
              callback(false);
            });


            if (canClose) {
              callback();
            }
          } else {
            callback();
          }
        }.bind(this));
      }

      if (!this.__legacyRouterUnload) {
        this.__legacyRouterUnload = __legacyRouterHooks.onBeforeUnload(function () {
          if (this.isVisible() && this.params.discardChangesConfirmation) {
            if (this.edited || this.editedSinceLastSave) {
              return t("You have unsaved changes.");


            }
          }
        }.bind(this));
      }

      this._debug = new Y.Squarespace.Debugger({
        name: 'EditingDialog',
        output: false });


      // PUBLISHED EVENTS
      // These are here so you can hook into either "showing"
      // or "dismiss" (hiding) dialog.

      this.publish('show', {
        prefix: 'EditingDialog',
        broadcast: 2, // for Y.Global
        emitFacade: true // so that we get an `e` in the event handlers
      });

      this.publish('dismiss', {
        prefix: 'EditingDialog',
        broadcast: 2, // for Y.Global
        emitFacade: true // so that we get an `e` in the event handlers
      });

      this.publish('tab-shown', {
        prefix: 'EditingDialog',
        broadcast: 2, // for Y.Global
        emitFacade: true // so that we get an `e` in the event handlers
      });

      this.publish('button-click', {
        prefix: 'EditingDialog',
        broadcast: 2, // for Y.Global
        emitFacade: true, // so that we get an `e` in the event handlers
        preventable: false // the button-{type} events are preventable (except saveAndClose)
      });
    },

    /**
     * The content-item-types/base.js#duplicate lifecycle uses
     * dialog.cancel() to stop editing the original and start editing the
     * duplicate.
     * This lets us distinguish a user cancel from an API called cancel.
     */
    markDuplicateLifecycle: function () {
      this.isDuplicating = true;
      this.once('canceled', function onDialogCancelledFromDuplicate() {
        this.isDuplicating = false;
      });
    },

    markCancelLifecycle: function () {
      this.isCanceling = true;

      // this.isCanceling is probably already false since it gets set on
      // dialog close. This is just a safety precaution.
      this.once('canceled', function onDialogCancelled() {
        this.isCanceling = false;
      });
    },

    /**
    * Default options for all Dialogs
    * @property defaultOpts
    * @type Object
    * @protected
    */
    defaultOpts: {
      tabs: [],
      initialData: {},
      buttons: [],
      style: 'standard',
      colorScheme: 'light',
      buttonAlign: 'right',
      position: 'center',

      // if no height option is provided, and this option is set to "fit"
      // dialog will render at the best possible height (field rendered height vs. window height)
      // and render a scrollback when appropriate
      verticalHeight: 'fixed',

      flyoutPointerDirection: 'left',
      closingText: t("Canceled\u2026"),


      savingText: t("Saving\u2026"),


      loadingText: t("Loading\u2026"),


      top: 60,
      closeOthers: true,
      disableTips: true,
      closeable: true,
      autoFocus: true,
      discardChangesConfirmation: true, // show a 'changes' confirmation dialog, if there are any changes made.
      overlay: false,
      validateActiveTabOnly: false,
      edgeMargin: 11,
      initialDataByReference: false,
      constrain: false, // ensures dialog is contained within viewport

      // removes the `standard-dialog-wrapper` and adds in `frameless-dialog-wrapper` instead.
      // this visually removes the window appearance of the dialog
      //
      // @attribute frameless
      // @default false
      frameless: false },



    /**
    * Gets the name of the dialog passed in with the parameters
    * @method getName
    * @return {String} The name of the dialog
    */
    getName: function () {
      return this.params.name;
    },

    /**
    * Get the dialog's copy of the initial data passed in
    * @method getInitialData
    * @return Dialog's initial data
    */
    getInitialData: function () {
      return this.params.initialData;
    },

    /**
    * Use this method after creating a new dialog to set its parameters. Unecessary if you
    * passed params in with the constructor
    * @method setParams
    * @param params {Object} Parameters for this dialog
    */
    setParams: function (params) {

      if (!params) {
        this.params = Y.merge(this.defaultOpts, {});
        return;
      }

      // ensuring a fresh, shallow copy
      this.params = Y.merge(this.defaultOpts, params || {});

      // merge legacy tab structured to new 'tabs' array
      if (this.params.primaryTabs) {
        this.params.tabs = this.params.tabs.concat(this.params.primaryTabs);
        delete this.params.primaryTabs;
      }

      if (this.params.secondaryTabs) {
        this.params.tabs = this.params.tabs.concat(this.params.secondaryTabs);
        delete this.params.secondaryTabs;
      }

      // shortcut -- allow us to omit tab structures if we have defined fields: at the top level
      if ((!this.params.tabs || this.params.tabs.length === 0) && this.params.fields) {
        this.params.tabs = [
        { fields: this.params.fields }];

      }

      // default tab values -- breaks if tabs.length == 0.
      if (!this.params.tabs[0].tabTitle) {
        this.params.tabs[0].tabTitle = t("Item");


      }

      if (!this.params.tabs[0].name) {
        this.params.tabs[0].name = 'item';
      }

      // transparent dialogs defaults
      if (this.params.style === 'transparent') {
        this.params.disableSaveOverlay = true;
      }

      // if discardChangesConfirmation was not specified and there are no buttons,
      // don't show the changesConfirmation dialog
      if (!params.discardChangesConfirmation && this.params.buttons.length === 0) {
        this.params.discardChangesConfirmation = false;
      }

      this.definitionChanged = true;

    },

    /**
    * Renders the dialog and puts it on the screen. Don't use this when the dialog has been hidden,
    * instead call .temporaryShow() to unhide it.
    * @method show
    */
    show: function (showParams) {

      this.fire('show');

      this._debug.log('Showing', ['showParams', showParams], ['this.params', this.params]);

      // allow moving fast transitions
      if (this.moving) {

        this._setState('EDITING');

        if (this.animation) {
          this.animation.stop();
        }

        this.fire('cancel-loading');
      }

      // don't show if we're between states
      if (this.destroyTimer || this._isState('LOADING') || this._isState('CLOSING')) {
        return;
      }

      // disable tooltip system
      if (Y.Squarespace.ToolTipManager) {
        // only (re-)enable if this parameter is explicitly specified
        if (this.params.disableTips === false) {
          Y.Squarespace.ToolTipManager.enableTooltips();
        } else if (this.params.disableTips) {
          Y.Squarespace.ToolTipManager.disableTooltips();
        }
      }

      // remove class from previously selected anchor
      if (this.anchorEl) {
        this.anchorEl.removeClass('targeted');
      }

      // show-specific params
      if (showParams) {
        this._setShowParams(showParams);
      }

      // child dialogs
      if (this.params.parentDialog) {
        this.params.parentDialog.addChildDialog(this);
      }

      if (!this._isState('CLOSED')) {

        if (this.anchorEl) {

          // target the new anchor
          this.anchorEl.addClass('targeted');

        } else {

          // we can't move a dialog if it's not moving to a new anchor
          return;

        }

        // move the dialog without closing
        this._setState('LOADING');
        this.moving = true;

        this.updateTitle();

        // directly enter loading state
        this.fire('loading');

        // align to anchor
        this._updatePosition(false);

        // this.position doesn't always exist. Should it be checking if dialog is embedded instead?
        if (this.position) {

          this.animation = this._anim({
            node: this.el,
            to: {
              left: this.position.getX() + 'px',
              top: this.position.getY() + 'px' },

            duration: 0.25,
            easing: Y.Easing.easeOutStrong });


          this.animation.on('end', function () {
            this.fire('loading-ready');
            this.dataReady();
          }, this);
          this.animation.run();
        }

        return; // ---- STATE SWITCH COMPLETE ----

      }

      // ---------- RENDER DIALOG FOR THE FIRST TIME ----------

      this.definitionChanged = false; // first load only

      if (this.params.closeOthers) {
        for (var i = 0, maxIndex = Y.Global.OPEN_DIALOGS.length; i < maxIndex; ++i) {
          Y.Global.OPEN_DIALOGS[i].cancel();
        }
      }

      Y.Global.OPEN_DIALOGS.push(this);

      Y.one(document.body).addClass('dialog-open');

      this.fire('loading');

      // initialize
      this._setState('LOADING');

      // add global events
      this.timers.push(Y.later(100, this, function bindDialogGlobalEvents() {
        // avoid a click that is calling show() from closing the dialog itself
        if (this.params.closeable) {
          Y.Squarespace.EscManager.addTarget(this);
        }

        // this is in done on a timer to prevent odd close behavior under
        // circumstances when show() is issued from a click event
        this.globalEvents.push(Y.on('resize', this.onResize, Y.one(window), this));
      }));

      this._addTitleEl();
      this._addBodyEl();

      // assemble dialog
      this.mainEl = Y.Node.create('<div class="main-container"></div>').
      append(this.titleEl).
      append(this.bodyEl).
      append(this.controlsEl);

      var dialogClasses = yuiDialogClassName + ' ';

      if (this.params.frameless) {
        dialogClasses += 'frameless-dialog-wrapper ';
      } else if (!this.params.disableStandardDialogWrapperClass) {
        dialogClasses += 'standard-dialog-wrapper ';
      }

      dialogClasses += substituteString('{sub1} {sub2} buttons-{sub3}', {
        sub1: this.params.style,
        sub2: this.params.colorScheme,
        sub3: this.params.buttonAlign });


      if (this.params.name) {
        dialogClasses += ' dialog-' + createUrlSafeString(this.params.name);
      }

      this.el = Y.Node.create('<div class="' + dialogClasses + '"></div>').
      append(this.mainEl);

      // click to close child dialogs
      this.bodyEvents.push(this.el.on('click', function (e) {
        if (e.target.ancestor('.sqs-button', true)) {
          return;
        }

        this.cancelChildDialogs();
        this.fire('click', e); // why do you even happen.
      }, this));

      // establish z-index
      if (!this.params.zIndex) {
        Y.Squarespace.DIALOG_ZINDEX_BASE += 10;
        this.zIndex = Y.Squarespace.DIALOG_ZINDEX_BASE;
      } else {
        this.zIndex = this.params.zIndex;
      }

      this.el.setStyle('zIndex', this.zIndex);

      // enable dragging
      if (this.params.draggable) {
        this.enableDragging();
      }

      // enable hiding
      if (this.params.hidable) {
        this._addHideEl();
      }

      if (this.params.headerButton) {
        this._addHeaderButtonEl();

      }

      // render footer
      this.buttonHolder = Y.Node.create('<div>').addClass('button-holder');
      this.autosaveEl = Y.Node.create('<div>').addClass('autosave-state');
      this.buttonStateEl = Y.Node.create(
      '<div class="sqs-text button-state-label">' +
      '  <input type="text" readonly="readonly" value="' + (
      this.params.buttonStateMessage ? this.params.buttonStateMessage : '') +
      '"></div>');

      if (this.controlsEl) {
        this.controlsEl.append(this.buttonStateEl);
        this.controlsEl.append(this.autosaveEl);
        this.controlsEl.append(this.buttonHolder);

        if (this.params.tabs) {
          this._updateControlsForTab(this.params.tabs[0]);
        }
      }

      // position and show
      var xyCoords;

      if (this.anchorEl) {
        xyCoords = this._positionWithAnchorEl();
      } else {
        xyCoords = this._positionWithDefault();
      }

      // enter loading state / show dialog
      if (this.params.loadingState) {

        var animation = this._anim({
          node: this.el,
          to: xyCoords[1] ? { opacity: 0.9, top: xyCoords[1] } : { opacity: 0.9 },
          duration: 0.15,
          easing: Y.Easing.easeOutStrong });


        animation.on('end', function () {
          this.fire('loading-ready');
        }, this);

        animation.run();

      } else {

        this.dataReady();

      }

      // vertical position
      if (this.params.verticalHeight === 'full' || this.params.verticalHeight === 'fit') {

        this.el.setStyles({
          'top': this.params.edgeMargin + 'px',
          'bottom': this.params.edgeMargin + 'px' });


        this.params.top = this.params.edgeMargin;
      }

      if (this.params.overlay) {

        // create overlay
        this.overlayEl = Y.Node.create('<div>').addClass(yuiDialogOverlayClassName);
        this.overlayEl.setStyle('zIndex', this.zIndex - 1);

        // scrolllock
        // this.overlayEl.plug(Y.Squarespace.Plugin.ScrollLock);

        this.overlayEl.addClass('hidden');

        Y.one(document.body).append(this.overlayEl);

        Y.soon(function () {
          this.overlayEl.removeClass('hidden');
        }.bind(this));

        this.globalEvents.push(Y.on('click', this.onOverlayClick, this.overlayEl, this));

      }

      this.moveIntoView();

      Y.later(300, this, function () {
        this._shown = true;
        // Dialog show complete
        this.fire('shown', this);
      });
    },

    _setShowParams: function (showParams) {

      if (showParams.data) {
        this.data = showParams.data;
      }
      if (showParams.anchor) {
        this.anchorEl = showParams.anchor;
      }

      if (showParams.top) {
        this.params.top = showParams.top;
      }
      if (showParams.flyoutPointerDirection) {
        this.params.flyoutPointerDirection = showParams.flyoutPointerDirection;
      }

    },

    _addHeaderButtonEl: function () {
      var headerButton = Y.Node.create('<div class="header-button"></div>');

      var input = Y.Node.create('<input type="button" value="' + this.params.headerButton.title + '" />');
      input.setData('type', this.params.headerButton.type);

      Y.on('click', this.params.headerButton.onclick, input, this);

      this.el.append(headerButton.append(input));
    },

    _addBodyEl: function () {
      // create body
      this.bodyEl = Y.Node.create('<div class="body-block"></div>');

      if (this.params.buttons.length !== 0) {
        this.controlsEl = Y.Node.create('<div class="controls-block"></div>');
      } else {
        this.bodyEl.addClass('bottom');
      }

    },

    _addTitleEl: function () {

      // create title
      this.titleEl = Y.Node.create('<div class="title-block loading"></div>');
      this.titleEl.toggleClass('singleTab', this.params.tabs.length <= 1);
      this.titleTextEl = Y.Node.create('<div class="text-holder"></div>');
      this.titleEl.append(this.titleTextEl);

      //Layout the tabs
      this.tabContainer = Y.Node.create('<div class="tabbed-header-container"></div>');


      this.titleEl.append(this.tabContainer);
      this.updateTitle();
    },

    _addHideEl: function () {
      var hideEl = Y.Node.create('<div class="dialog-close"></div>');

      this.el.prepend(hideEl);
      hideEl.on('click', function () {
        // fires this event so that observers can know that it was closed by
        // the user and not closed by switching mode
        this.fire('user-close');

        // this should be close since it should not be mixed with
        // hide when you hide it when mouse goes off the screen, example tweak layer
        this.close();
      }, this);
    },

    mount: function () {
      var mountNode = this.params.mountNode || Y.config.doc.body;
      Y.one(mountNode).append(this.el);
    },

    _positionWithAnchorEl: function () {

      var x,
      y,
      w,
      h,
      l,
      top;
      var dialogWidth = this.params.width;

      // align to element within page
      this.el.setStyle('position', this.params.forcePosition || 'absolute');

      this.anchorEl.addClass('targeted');

      // align to anchor
      if (
      this.params.flyoutPointerDirection === 'left' ||
      this.params.flyoutPointerDirection === 'right')
      {

        // size flyout
        this.el.setStyle('width', dialogWidth + this.NOTCH_HEIGHT + 'px');
        this.mainEl.setStyle('width', dialogWidth + 'px');

        // add the class
        this.el.addClass('flyout');

        this.mount();

      } else if (this.params.flyoutPointerDirection === 'hidden') {

        // size flyout
        this.el.setStyle('width', dialogWidth + 'px');
        this.mainEl.setStyle('width', dialogWidth + 'px');
        this.mount();

      } else if (this.params.flyoutPointerDirection === 'top') {

        this._initNotchEl('top');

        this.el.insertBefore(this.notchEl, this.mainEl);

        w = this.anchorEl.get('offsetWidth');
        h = this.anchorEl.get('offsetHeight');

        x = this.anchorEl.getX();
        y = this.anchorEl.getY() + h;

        if (dialogWidth > w) {
          x -= (dialogWidth - w) / 2;
        }

        this.currentXY = [x, y - 3];

        this.notchEl.setStyle('marginLeft', (dialogWidth - 33) / 2 + 'px'); // center notch

        l = this.params.left || this.currentXY[0];
        top = this.params.top || this.currentXY[1];

        this.el.setStyles({
          'left': l + 'px',
          'top': top + 'px',
          'width': dialogWidth + 'px' });


        this.mainEl.setStyle('width', dialogWidth + 'px');

        this.mount();

      } else if (this.params.flyoutPointerDirection === 'bottom') {

        this._initNotchEl('top', 'bottom');

        this.el.append(this.notchEl);

        w = this.anchorEl.get('offsetWidth');
        h = this.anchorEl.get('offsetHeight');

        x = this.anchorEl.getX();
        y = this.anchorEl.getY() + h;

        if (dialogWidth > w) {
          x -= (dialogWidth - w) / 2;
        }

        this.currentXY = [x, y - 3];

        this.notchEl.setStyle('marginLeft', (dialogWidth - 33) / 2 + 'px'); // center notch

        l = this.params.left || this.currentXY[0];
        top = this.params.top || this.currentXY[1];

        this.el.setStyles({
          'left': l + 'px',
          'top': top + 'px',
          'width': dialogWidth + 'px' });


        this.mainEl.setStyle('width', dialogWidth + 'px');

        this.mount();

      } else {

        // CENTER against anchor
        this.currentXY = [
        this.anchorEl.getX() + (this.anchorEl.get('offsetWidth') - dialogWidth) / 2,
        this.anchorEl.getY() + (this.anchorEl.get('offsetHeight') - this.params.height) / 2 - 15];


        this.el.setStyles({
          'left': this.currentXY[0] + 'px',
          'top': this.currentXY[1] + 'px',
          'width': dialogWidth + 'px' });


        this.el.setStyle();
        this.mainEl.setStyle('width', dialogWidth + 'px');

        y = this.currentXY[1];

        this.mount();
      }

      return [x, y];
    },

    _positionWithDefault: function () {

      var x,
      y;
      var vw = Y.one(document).get('winWidth');

      // align to window
      this.el.setStyle('position', 'fixed');

      if (this.params.position === 'right') {

        // right hand side
        this.el.setStyles({
          'right': this.params.edgeMargin + 'px',
          'top': '0px' // prevent window scroll
        });

        // if we are touching the edge, remove all rounding
        if (this.params.edgeMargin === 0) {
          this.titleEl.setStyle('border-radius', '0px');
          this.controlsEl.setStyle('border-radius', '0px');
        }

      } else if (typeof this.params.left !== 'undefined') {

        // positioned
        this.currentXY = [this.params.left, this.params.top];

      } else {

        // center
        this.currentXY = [
        (vw - this.params.width) / 2,
        this.params.top];


        this.el.setStyle('position', 'fixed');
      }

      if (this.currentXY) {
        this.el.setStyles({
          'left': this.currentXY[0] + 'px',
          'top': this.currentXY[1] + 'px' });


        y = this.currentXY[1];
      }

      this.el.setStyle('width', this.params.width + 'px');
      this.mainEl.setStyle('width', this.params.width + 'px');

      this.mount();

      return [x, y];
    },

    isOpen: function () {
      return this._isState('LOADING') || this._isState('EDITING');
    },

    disableBodyScroll: function () {

      this.oldBodyScroll = Y.one('body').getStyle('overflow');
      Y.one('body').setStyle('overflow', 'hidden');

    },

    restoreBodyScroll: function () {

      if (this.hasOwnProperty('oldBodyScroll')) {

        if (this.oldBodyScroll) {
          Y.one('body').setStyle('overflow', this.oldBodyScroll);
        } else {
          Y.one('body').setStyle('overflow', null);
        }

      }

    },

    /**
    * Get the element this dialog is anchored to
    * @method getAnchorEl
    * @return {Node} The element this dialog is anchored to
    */
    getAnchorEl: function () {

      return this.anchorEl;

    },

    /**
     * Get the child dialogs
     */
    getChildDialogs: function () {
      return this.childDialogs;
    },

    /**
    * Set up a relationship between two dialogs. A child dialog will be closed
    * when the parent is closed
    * @method addChildDialog
    * @param {Squarespace.EditingDialog} d The child dialog
    */
    addChildDialog: function (d) {

      if (this.childDialogs.indexOf(d) < 0) {

        this.cancelChildDialogs();

        this._debug.log('addChildDialog', d);

        this.childDialogs.push(d);
      }

      // set parent relationship
      d.params.parentDialog = this;
    },

    /**
    * Remove a child dialog from a parent dialog
    * @method removeChildDialog
    * @param {Squarespace.EditingDialog} d The child dialog
    */
    removeChildDialog: function (d) {

      this.childDialogs = Y.Array.filter(this.childDialogs, function (dialog) {
        return dialog !== d;
      }, this);

    },

    /**
    * Enables dragging on the dialog
    * @method enableDragging
    **/
    enableDragging: function () {

      this.titleEl.setStyle('cursor', 'move');

      this.dd = new Y.DD.Drag({
        node: this.el,
        handles: this.titleEl && this.titleEl._node ? [this.titleEl] : null });


      this.dd.on('drag:start', this.cancelChildDialogs, this);

      this.dd.on('drag:drag', function (e) {

        this.fire('drag', e);

      }, this);

      this.dd.on('drag:end', function (e) {

        this.moveIntoView();

      }, this);

    },

    /**
    * Hide a dialog without destroying it or loosing any of its data
    *
    * @param {Boolean} noAnimation Whether to animate the hide.
    * @method temporaryHide
    */
    temporaryHide: function (noAnimation) {

      this.fire('hide');

      // hide children
      Y.Array.each(this.childDialogs, function (dialog) {
        dialog.temporaryHide();
      }, this);

      if (this.hideAnim) {
        this.hideAnim.stop();
      }
      if (this._hideAnimEvent) {
        this._hideAnimEvent.detach();
      }

      if (this.overlayHideAnim) {
        this.overlayHideAnim.stop();
      }
      if (this._overlayHideAnimEvent) {
        this._overlayHideAnimEvent.detach();
      }

      this.hideAnim = this._anim({
        node: this.el,
        to: {
          opacity: 0 },

        duration: 0.35,
        easing: Y.Easing.easeOutStrong });


      if (this.overlayEl) {

        this.overlayHideAnim = this._anim({
          node: this.overlayEl,
          to: {
            opacity: 0 },

          duration: 0.35,
          easing: Y.Easing.easeOutStrong });


        this._overlayHideAnimEvent = this._subscribe(this.overlayHideAnim, 'end', function () {
          this.overlayEl.setStyle('display', 'none');
        });
      }

      this._hideAnimEvent = this._subscribe(this.hideAnim, 'end', function () {
        this.el.setStyle('display', 'none');
        this.fire('hidden', this);
      });

      // hide without animating
      if (noAnimation) {

        this.el.setStyle('display', 'none');

        if (this.overlayEl) {
          this.overlayEl.setStyle('display', 'none');
        }

        this.fire('hidden', this);

      } else {// animate the hide

        this.hideAnim.run();

        if (this.overlayEl) {
          this.overlayHideAnim.run();
        }
      }

    },

    /**
    * Undoes temporary hide, i.e. it reshows the dialog if it has been hidden
    * If you pass in show params, you can update its data and anchor element for when it reappears,
    * the same as you would have before, using show()
    *
    * @method temporaryShow
    * @param {Object} showParams Show parameters (data, anchorEl)
    */
    temporaryShow: function (showParams) {

      this.fire('show', this);

      // show-specific params
      if (showParams) {
        this._setShowParams(showParams);
      }

      if (this.hideAnim) {
        this.hideAnim.stop();
      }

      if (this._hideAnimEvent) {
        this._hideAnimEvent.detach();
      }

      if (this.overlayHideAnim) {
        this.overlayHideAnim.stop();
      }

      if (this.overlayEl) {

        this.overlayEl.setStyle('display', 'block');
        this.overlayHideAnim = this._anim({
          node: this.overlayEl,
          to: {
            opacity: this.params.overlay },

          duration: 0.35,
          easing: Y.Easing.easeOutStrong });

        this.overlayHideAnim.run();

      }

      this.el.setStyle('display', 'block');

      this.hideAnim = this._anim({
        node: this.el,
        to: {
          opacity: 1 },

        duration: 0.35,
        easing: Y.Easing.easeOutStrong });


      this._hideAnimEvent = this._subscribeOnce(this.hideAnim, 'end', function () {
        this.fire('shown', this);
      });

      this.hideAnim.run();

      // show children
      this._showChildren();

      this.moveIntoView();

    },

    /**
     * Accepts a method name and arbitrary number of parameters and appliest them
     * to each child dialog.
     *
     * @private
     * @method  _applyMethodToChildren
     * @params {String} method      The name of the method to apply to all the childresn
     * @params {Mixed} [arguments]  Any number of arguments to apply to the dialog
     */
    _applyMethodToChildren: function () {

      var method = arguments[0];
      var args = Array.prototype.slice.call(arguments, 1);

      Y.Array.each(this.childDialogs, function (dialog) {
        dialog[method].apply(dialog, args);
      }, this);
    },

    _showChildren: function () {
      this._applyMethodToChildren('temporaryShow');
    },

    _hideChildren: function () {
      this._applyMethodToChildren('temporaryHide');
    },

    cancelChildDialogs: function () {

      this._applyMethodToChildren('cancel');

      this.childDialogs = [];

    },

    /**
    * Find out if this dialog is hidden/onscreen
    * @method isVisible
    * @return {Boolean} Whether the dialog is on screen
    */
    isVisible: function () {
      return !this._isState('CLOSED');
    },

    /**
    * Update the dialog's internal position store, orienting on the screen and
    * aligning its notch based on the anchor elements, passed in X/Y, etc.
    * @method _updatePosition
    * @private
    * @protected
    * @param move {What?} Fill me in
    */
    _updatePosition: function (move) {

      if (this.el && this.anchorEl) {

        var actualHeight = this.el.get('offsetHeight');
        var pointerDirection = this.params.flyoutPointerDirection;

        if (pointerDirection === 'top' || pointerDirection === 'bottom') {
          return;
        }

        // position object
        if (pointerDirection === 'centered') {

          var anchorXY = this.anchorEl.getXY();

          this.position = new Y.Squarespace.Position({
            x: anchorXY[0] + (this.anchorEl.get('offsetWidth') - this.params.width) / 2,
            y: anchorXY[1] + (this.anchorEl.get('offsetHeight') - actualHeight) / 2,
            w: this.params.width,
            h: actualHeight });


          this.position.nudgeFix();

        } else {

          this.position = new Y.Squarespace.Position({
            avoidElX: this.anchorEl,
            avoidElY: this.anchorEl,
            xdir: 'right',
            ydir: 'bottom',
            x: this.anchorEl.getX(),
            y: this.anchorEl.getY(),
            xo: 2,
            yo: 0,
            w: this.params.width + this.NOTCH_HEIGHT,
            h: actualHeight });


          this.position.reflectFix();
        }

        if (pointerDirection !== 'hidden' && pointerDirection !== 'centered') {
          this._reattachNotchEl();
        }

        if (move) {

          // Important to set XY here, instead of style top & left,
          // or fixed position dialogs don't position correctly
          this.el.setXY(this.position.getXY());
          this._alignNotch(this.anchorEl);

        }

      }

    },

    /**
     * This method makes sure that a notchElement is removed from the DOM and
     * then reattached in the appropriate place based on the current positioning
     * of the dialog.
     *
     * @method  _reattachNotchEl
     */
    _reattachNotchEl: function () {

      var notchDir = this.position.xdir === 'right' ? 'left' : 'right';

      if (!this.notchEl) {

        this._initNotchEl(notchDir);

      } else {

        this.notchEl.set('className', 'flyout-notch-' + notchDir);

        if (this.notchEl.inDoc()) {
          this.notchEl.remove();
        }
      }

      if (this.position.xdir === 'right') {
        this.el.insertBefore(this.notchEl, this.mainEl);
      } else {
        this.el.append(this.notchEl);
      }

    },

    /**
     * Initialize the node for the notch.
     *
     * @method  _initNotchEl
     * @param  {String} direction         The direction that the notch is flying out.
     * @param  {String} [extraClasses=''] Extra classes to append to the node.
     */
    _initNotchEl: function (direction, extraClasses) {

      this.notchEl = Y.Node.create('<div class="flyout-notch-' + direction + '">&nbsp;</div>');

      if (Y.Lang.isString(extraClasses)) {
        this.notchEl.addClass(extraClasses);
      }
    },

    /**
    * Aligns the notch (arrow pointer thing on the side of the dialog)
    * @method _alignNotch
    * @private
    * @param anchorNode {Node} The node the flyout should point to
    **/
    _alignNotch: function (anchorNode) {

      if (!this.notchEl) {
        return;
      }

      var dialogHeight = this.el.get('offsetHeight');

      // hooray magic number, a holdover from old code
      var NOTCH_MAX = Math.max(dialogHeight - 36, 220);
      var NOTCH_MIN = 12;
      var NOTCH_ADJUSTMENT = 11; // subtract half the notch height
      var notchOffset = NOTCH_MIN;

      var dialogRegion = this.el.get('region');
      var anchorRegion = anchorNode.get('region');
      var anchorCenterlineY = anchorRegion.top + anchorRegion.height / 2;

      // figure out if the notch must come down
      if (anchorRegion.top > dialogRegion.top) {
        notchOffset = anchorCenterlineY - dialogRegion.top - NOTCH_ADJUSTMENT;
        notchOffset = Math.min(NOTCH_MAX, Math.max(NOTCH_MIN, notchOffset));
      }

      this.notchEl.setStyle('marginTop', notchOffset + 'px');

    },

    /**
    * Sets editing flags and fires data-changed events
    * @method onDataChange
    * @protected
    */
    onDataChange: function (f) {

      this.fire('render-anchor', this.getData());

      this.setEdited();

      this._debug.log('onDataChange');
      this.fire('datachanged');

    },

    onOverlayClick: function (e) {
      e.halt();
      this.fire('overlay-click');
      if (!this.params.disableOverlayCancel) {
        this.isCanceling = true;
        this.close();
      }
    },

    setEdited: function () {

      // if we are saving right now, ignore fields reporting editing status
      // layout fields are sometimes slow and report datachange after we're
      // already in a close routine
      if (this._isState('SAVING')) {
        return;
      }

      this._setEdited(true);

    },

    clearEdited: function () {

      this._setEdited(false);
    },

    _setEdited: function (value) {

      this.edited = !!value;
      this.editedSinceLastSave = !!value;

    },

    getEditedSinceLastSave: function () {

      return this.editedSinceLastSave;

    },

    getNextTabIndex: function () {

      return this.lastTabIndex++;

    },

    getEdited: function () {

      return this.edited;

    },

    isState: function (state) {
      return this.getState() === state;
    },

    getState: function () {

      return this.state;

    },

    setInitialData: function (initialData) {
      if (!this.params.initialDataByReference) {
        this.params.initialData = Y.clone(initialData, true); // new copy
      } else {
        this.params.initialData = initialData;
      }

      // HACK: force data update of DialogField2 based fields
      if (this.fields) {
        Y.Object.each(this.fields, function setInitialDataOnField(field, fieldName) {
          if (
          this._isDialogField2(field) && field.get('name') &&
          !Y.Lang.isUndefined(this.params.initialData[field.get('name')]))
          {
            field.set('data', this.params.initialData[field.get('name')], {
              source: 'setInitialData' });

            field.setCurrentDataAsInitial();
          }
        }, this);
      }
    },

    getBodyHeight: function () {

      if (this.params.height) {
        // fixed
        return this.params.height;
      }

      return this._getFullHeight();

    },

    setActiveFlyout: function (params) {

      if (this.activeFlyout) {

        // flyout switched while one was already open
        this.activeFlyout.field.closeFlyout();
      }

      // do this to prevent 'click' events that show flyouts from closing themselves
      Y.later(10, this, function () {
        this.activeFlyout = params;
      });

    },

    clearActiveFlyout: function () {

      this.activeFlyout = null;

    },

    onResize: function () {

      var vw = Y.one(document).get('winWidth');

      if (!this.anchorEl && !this.params.draggable) {

        var newPos = {};

        if (this.params.verticalHeight === 'full' || this.params.verticalHeight === 'fit') {

          // resize body
          this.bodyHeight = this.getBodyHeight();

          if (this.params.verticalHeight === 'fit' && this.bodyHeight > this.observedHeight) {
            this.bodyHeight = this.observedHeight;
          }

          this.bodyEl.setStyle('height', this.bodyHeight + 'px');

          // resize all tabs
          this.bodyEl.all('.scrollable-body').each(function (node) {

            node.setStyles({
              height: this.bodyHeight - 33 + 'px',
              paddingBottom: '33px' });


          }, this);

          // and all vertical fields
          this.resizeVerticalFields();

        }

        if (this.params.position !== 'right') {

          // clear errors
          this.hideErrors();

          // not anchored -- re-center
          newPos.left = (vw - this.params.width) / 2;
          newPos.top = this.params.top;

          if (this.params.left) {
            newPos.left = this.params.left;
          }

        }

        // save new position

        this.currentXY = [newPos.left, newPos.top];

        if (this.moveAnim) {
          this.moveAnim.stop();
        }

        this.moveAnim = this._anim({
          node: this.el,
          to: newPos,
          duration: 0.15,
          easing: Y.Easing.easeOutStrong });


        this.moveAnim.run();

      }

      // move dialog into view
      this.moveIntoView();

    },

    /**
    * Reposition the element so that it's visible in the viewport
    * @method moveIntoView
    * @param attachToAnchor {Boolean} Move the el to its anchorEl
    */
    moveIntoView: function (attachToAnchor) {

      if (!this.params.draggable && !this.params.constrain) {
        return;
      }

      if (attachToAnchor && !this.anchorEl && !this.position) {
        attachToAnchor = false;
      }

      var params = {};
      var scrollX = this.el.get('docScrollX');
      var scrollY = this.el.get('docScrollY');
      var vw = this.el.get('winWidth') + scrollX;
      var trueVh = this.el.get('winHeight');
      var vh = trueVh + scrollY;
      var elY = this.el.getY();
      var elX = this.el.getX();

      if (elY < scrollY) {
        params.top = scrollY + this.params.edgeMargin;
      }

      if (elY + this.el.get('offsetHeight') > vh) {
        params.top = vh - this.el.get('offsetHeight') - this.params.edgeMargin;
      }

      if (elX + this.el.get('offsetWidth') > vw) {
        params.left = vw - this.el.get('offsetWidth') - this.params.edgeMargin;
      }

      if (elX < scrollX) {
        params.left = scrollX + this.params.edgeMargin;
      }

      if (attachToAnchor) {
        params.top = params.top ? Math.max(params.top, this.position.getY()) : this.position.getY();
      }

      //Fixed positioned dialogs shouldn't show up below the fold!
      if (this.params.forcePosition === 'fixed') {
        if (Y.Lang.isValue(params.top) && params.top > this.el.get('winHeight')) {
          params.top = Math.max(0, trueVh - this.el.get('offsetHeight'));
        }
      }

      var needsToMove = Y.Object.size(params) !== 0;

      if (needsToMove) {
        this._anim({
          node: this.el,
          to: params,
          duration: 0.3,
          easing: Y.Easing.easeOutStrong }).
        run();
      }

      // size appropriately for the viewport
      if (this.preferredHeight + 120 > trueVh) {
        // shrink
        this.bodyHeight = trueVh - 120;
        this.bodyEl.setHeight(this.bodyHeight);
      } else if (this.bodyHeight !== this.preferredHeight) {
        // restore
        this.bodyHeight = this.preferredHeight;
        this.bodyEl.setHeight(this.preferredHeight);
      }
    },

    scrollIntoView: function (top) {

      this.el.scrollIntoView(top);

    },

    showErrors: function (errors) {

      this.fire('show-errors', errors);

      this.allowEditing();

      this.currentErrors = errors;
      this.errorCount = Y.Object.size(errors);
      this.errorsByTab = {};

      Y.Object.each(errors, function (value, key) {

        var field = this.getField(key);

        if (!field) {
          if (__DEV__) {
            console.error('Server error returned for a missing dialog field: ' + key);
          }
          return;
        }

        if (!this.errorsByTab[field.tab.name]) {
          this.errorsByTab[field.tab.name] = 1;
        } else {
          this.errorsByTab[field.tab.name]++;
        }

      }, this);

      this.renderNavigationTabs(this.currentErrors);
      this.activateErrors();

    },

    clearError: function (field) {

      if (this.currentErrors && this.currentErrors[field.getName()]) {

        this.errorsByTab[field.tab.name]--;

        delete this.currentErrors[field.getName()];
        this.errorCount--;

        if (this.errorsByTab[field.tab.name] === 0) {
          this.renderNavigationTabs(this.currentErrors);
        }
      }

    },

    activateErrors: function () {

      if (this.errorCount) {

        var fields = this.currentTab.tabFields;

        // show errors on current tab, save errors for other tabs
        var firstField = null;

        Y.Array.each(fields, function (field) {

          if (this.currentErrors[field.getName()]) {
            if (!firstField) {
              firstField = field;
              firstField.scrollIntoView();
            }
            field.showError(this.currentErrors[field.getName()]);
          }

        }, this);

        if (firstField && Y.Lang.isFunction(firstField.focus)) {
          firstField.focus();
        }

      }

    },

    hideErrors: function () {

      if (!this.currentTab) {
        return;
      }

      var fields = this.currentTab.tabFields || [];

      Y.Array.each(fields, function (field) {
        if (Y.Lang.isFunction(field.hideError)) {
          field.hideError();
        }
      }, this);

      this.renderNavigationTabs();
    },

    setAndUpdateTitle: function (title) {
      this.title = title;
      this.updateTitle();
    },

    updateTitle: function (desiredState) {

      // re-evaluate the current title
      var currentTabParams = this.params.tabs[this.currentTabIndex];

      if (currentTabParams && this.currentTab && currentTabParams.title !== this.currentTab.title) {
        this.currentTab.title = currentTabParams.title;
      }


      if (Y.Lang.isUndefined(desiredState)) {
        desiredState = this.state;
      }

      var initialTitle;

      if (Y.Lang.isString(this.title)) {
        initialTitle = this.title;
      } else if (this.currentTab && this.currentTab.title) {
        initialTitle = this.currentTab.title;
      } else {
        initialTitle = this.params.title;
      }

      var subtext = '';
      if (this.params.subtext) {
        subtext = '<div class="title-subtext">' + this.params.subtext + '</div>';
      }

      switch (desiredState) {

        case Y.Squarespace.DialogStates.LOADING:
          if (this.params.loadingText) {
            this.setTitleHtml('<div class="title-text">' + this.params.loadingText + '</div>' + subtext);
          }
          break;

        case Y.Squarespace.DialogStates.EDITING:
          if (initialTitle) {
            this.setTitleHtml('<div class="title-text">' + initialTitle + '</div>' + subtext);
          }
          break;

        case Y.Squarespace.DialogStates.SAVING:
          if (this.params.savingText) {
            this.setTitleHtml('<div class="title-text">' + this.params.savingText + '</div>' + subtext);
          }
          break;

        default:
          // nothing
          break;}


    },

    setTitleHtml: function (html) {
      this.titleTextEl.setHTML(html);
    },

    setData: function (data, options) {
      if (!this.currentTab) {
        return;
      }

      options = options || {};
      var silent = options.silent;
      var ignoreUndefinedKeysInData = options.ignoreUndefinedKeysInData;

      Y.Array.each(this.currentTab.tabFields, function (field, i) {

        if (field.setValue) {

          var datum = data[field.getName()];
          if (!data.hasOwnProperty(field.getName())) {
            // setting this option to true will only update a field in
            // the dialog if that field is defined in the passed in data
            if (ignoreUndefinedKeysInData) {
              return;
            }
            datum = lodashGet(data, field.getName());
          }

          field.setValue(Y.Lang.isValue(datum) ? datum : null);

          if (!silent) {
            this.fire('datachange', field);
          }

        }

      }, this);

    },

    focusTab: function () {
      if (!this.currentTab) {
        return;
      }

      this.fire('tab-focused', {
        tabName: this.currentTab.name });


      if (Y.Lang.isArray(this.currentTab.tabFields)) {
        if (isAppleiPadOS13()) {
          return;
        }

        Y.Array.some(this.currentTab.tabFields, function (field, i) {

          var isFocusableDF1 = !!(field.setValue && field.focus);
          var isDF2 = this._isDialogField2(field);
          var isFocusableDF2 = !!(isDF2 && field.get('focusable'));

          if (isFocusableDF1 && !isDF2 || isFocusableDF2) {
            field.focus();
            return true;
          }
        }, this);
      }
    },

    _getFullHeight: function () {
      return Y.one(document).get('winHeight') - 2 * this.params.edgeMargin -
      this.controlsHeight - this.titleEl.get('offsetHeight');
    },

    dataReady: function () {

      var animation;

      this.clearEdited();

      this._setState('EDITING');

      this.titleEl.removeClass('loading');

      // hopefully we've loaded some data
      if (!this.params.initialData) {
        this.params.initialData = {};
      }

      // body height
      this.bodyHeight = this.params.height;

      if (this.params.verticalHeight === 'full' || this.params.verticalHeight === 'fit') {
        this.bodyHeight = this._getFullHeight();
      }

      this.preferredHeight = this.bodyHeight;

      // set width
      this.bodyEl.setStyle('width', this.params.width + 'px');

      // disable body scrolling
      if (this.params.overlay && !this.params.doNotDisableBodyScroll) {
        this.disableBodyScroll();
      }

      // init the dialog
      if (this.moving) {

        this.moving = false;
        this.updateTitle();

        // do we need to reset the internal structure?
        if (this.definitionChanged) {

          this.definitionChanged = false;

          // expand / show and re-render the whole thing
          this.destroyBody();
          this.destroyButtons();
          this.render();

          animation = this._anim({
            node: this.bodyEl,
            duration: 0.25,
            easing: Y.Easing.easeOutStrong,
            to: {
              height: this.bodyHeight } });



          animation.on('end', function () {
            this.fire('ready');
          }, this);
          animation.run();

        } else {

          // re-load all field data from initialData
          if (this.rendered) {

            this.setData(this.params.initialData);
            if (this.params.autoFocus) {this.focusTab();}

          } else {

            this.once('rendered', function () {
              this.setData(this.params.initialData);
              if (this.params.autoFocus) {this.focusTab();}
            }, this);

          }

          this.fire('ready');

        }

        // mark the dialog as not editing, cause we moved to a new anchor.
        this.clearEdited();

      } else {

        // setup
        this.currentTabIndex = 0;
        this.currentTab = this.params.tabs[0];

        this.updateTitle();

        if (this.params.loadingState) {

          // normal opacity
          animation = this._anim({
            node: this.el,
            to: { opacity: 1 },
            duration: 0.15,
            easing: Y.Easing.easeOutStrong });


          animation.run();

          // move controls
          if (this.controlsEl) {

            animation = this._anim({
              node: this.controlsEl,
              to: { height: this.controlsHeight },
              duration: 0.15,
              easing: Y.Easing.easeOutStrong });


            animation.run();

          }

          // expand / show
          animation = this._anim({
            node: this.bodyEl,
            to: { height: this.bodyHeight },
            duration: 0.25,
            easing: Y.Easing.easeOutStrong });


          animation.on('end', function () {
            this.render();
            this.fire('ready');
          }, this);

          animation.run();

        } else {

          this.render();

          // snapshot current data
          this.currentData = this.params.initialData;

          var dialogContext = this;
          var removeAntiAliasing = function () {
            if (dialogContext.el) {
              dialogContext.el.setStyle('transform', null);
            }
          };

          switch (this.params.showAnim) {

            case 'custom':
              this.params.customShowAnim(this.el, function () {
                dialogContext.fire('ready');
              });
              break;

            case 'fade':
              this.el.transition({
                opacity: {
                  duration: 0.2,
                  value: 1 },

                easing: 'ease-out' },
              function () {// on transition end callback
                dialogContext.fire('ready');
                removeAntiAliasing();
              });
              break;

            case 'noshow':
              this.el.setStyles({
                display: 'none' });

              dialogContext.fire('ready');
              break;

            default:
              dialogContext.fire('ready');
              this.el.addClass('visible');
              break;}

        }

      }

    },

    containsNode: function (node) {
      return this.bodyEl && this.bodyEl.contains(node);
    },

    activateTab: function (target) {

      this.hideErrors();

      var targetTabIndex = parseInt(target.index, 10);
      if (this.tabbedHeader && targetTabIndex !== this.tabbedHeader.state.active) {
        this.tabbedHeader.setState({ active: targetTabIndex });
      }

      // check if the current tab isn't set, and set it to the currenttab
      if (!this.currentTab) {
        this.currentTab = target;
      }

      Y.Array.each(this.params.tabs, function (tabObj, index) {

        if (index === targetTabIndex) {
          this._showPanelByIndex(targetTabIndex);
        }

      }, this);

      var reactData = this.currentTab.reactData;
      var tabNode = this.currentTab.tabPanelObj.getDOMNode();

      if (reactData && this.reactComponentContainers.indexOf(tabNode) === -1) {
        this._renderReactComponent(reactData, tabNode);
      }
    },

    _showField: function (field) {
      if (
      this._isDialogField2(field) && field.get('visible') ||
      !this._isDialogField2(field) && !field.config.hidden)
      {
        field.show(true);
      }

    },

    _updateControlsForTab: function (tab) {
      if (this.controlsElButtonDisplayClass) {
        this.controlsEl.removeClass(this.controlsElButtonDisplayClass);
      }

      // TODO: controlsHeight usually defaults to 65, which doesn't make sense, as it ends up adding an additional 22px
      // margin to the bottom of the dialog. It would be much better to use the actual height of the controls/button bar
      // and then add additional margin to the dialog as desired.
      if (tab.hasOwnProperty('buttonDisplayClass')) {
        this.buttonStateEl.setStyle('display', 'block');
        this.controlsHeight = 87 + 22;
        this.controlsEl.addClass(tab.buttonDisplayClass);
        this.controlsElButtonDisplayClass = tab.buttonDisplayClass;
      } else {
        this.buttonStateEl.setStyle('display', 'none');
        this.controlsHeight = this.params.style === 'standard' ? 65 : 60;
        this.controlsElButtonDisplayClass = null;
      }

      if (this._shown) {
        this.onResize();
      }
    },

    _showNewPanel: function (targetTab, index, fields, animation, duration, easing) {
      // show new panel
      this.currentTab = targetTab;
      this.currentTabIndex = index;

      this.updateTitle();

      targetTab.tabPanelObj.removeClass('hidden');

      this._updateControlsForTab(targetTab);

      if (!this.params.noTabAnim) {
        animation = this._anim({
          node: targetTab.tabPanelObj,
          to: {
            left: 0 },

          duration: duration,
          easing: easing });

        animation.on('end', function () {
          // show fields
          var tabFields = this.currentTab.tabFields;
          Y.Array.forEach(tabFields, function (field) {
            this._showField(field);
          }, this);

          // focus
          this.focusTab();
          this.activateErrors();

        }, this);
        animation.run();
        targetTab.tabPanelObj.animation = animation;

      } else {
        // show fields
        fields = this.currentTab.tabFields;

        Y.Array.forEach(fields, function (field) {
          this._showField(field);
        }, this);

        // focus
        this.focusTab();
        this.activateErrors();
      }

      // adjust height?

      if (this.currentTab.height) {

        this.currentTab.tabPanelObj.setStyle('height', this.currentTab.height - 50);

        this._anim({
          node: this.bodyEl,
          to: {
            height: this.currentTab.height },

          duration: duration,
          easing: easing }).
        run();

      }

      // scrollable?
      this.bodyEl.toggleClass('scrollable', !!this.currentTab.scroll);

      if (this.currentTab.scroll) {
        this.bodyEl.plug(Y.Squarespace.Plugin.ScrollLock);
      } else {
        this.bodyEl.unplug(Y.Squarespace.Plugin.ScrollLock);
      }

      this.fire('tab-shown', {
        name: this.currentTab.name,
        title: this.currentTab.title });

    },

    _showPanelByIndex: function (index) {
      if (index === this.currentTabIndex) {
        return;
      }

      var animation;
      var duration = .5;
      var easing = Y.Easing.easeBothStrong;
      var targetTab = this.params.tabs[index];
      var currentTab = this.params.tabs[this.currentTabIndex];

      if (!this.params.noTabAnim) {
        // stop animations
        if (targetTab.tabPanelObj.animation) {targetTab.tabPanelObj.animation.stop(true);}
        if (currentTab.tabPanelObj.animation) {currentTab.tabPanelObj.animation.stop(true);}
      }

      // hide current fields
      var fields = currentTab.tabFields;

      // hide the old panel
      if (!this.params.noTabAnim) {

        targetTab.tabPanelObj.setStyles({
          left: (targetTab.index > currentTab.index ? 1 : -1) * this.params.width + 'px',
          opacity: 1,
          zIndex: '300' });


        currentTab.tabPanelObj.setStyle('zIndex', '0');

        var newWidth = (targetTab.index < currentTab.index ? 1 : -1) * this.params.width;

        animation = this._anim({
          node: currentTab.tabPanelObj,
          to: {
            left: newWidth + 'px' },

          duration: duration,
          easing: easing });


        var boundShowNewPanel = this._showNewPanel.bind(this);
        animation.on('end', function () {
          this.tab.addClass('hidden');
          if (mediaQueries.isSubDesktop()) {
            boundShowNewPanel(targetTab, index, fields, animation, .4, easing);
          }
        }, { tab: currentTab.tabPanelObj });

        animation.run();

        currentTab.tabPanelObj.animation = animation;

      } else {

        currentTab.tabPanelObj.setStyles({
          opacity: 1,
          zIndex: '300' });

        currentTab.tabPanelObj.addClass('hidden');

      }

      if (mediaQueries.isDesktop()) {
        this._showNewPanel(targetTab, index, fields, animation, duration, easing);
      }
    },

    renderNavigationTabs: function (errors) {
      var minTabWidth = 106;
      var calculatedTabWidth = this.tabContainer.width() / this.params.tabs.length;
      var tabWidth = Math.max(minTabWidth, calculatedTabWidth);
      var tabElements = [];
      Y.Array.each(this.params.tabs, function (currentTabObj, tabIndex) {
        currentTabObj.index = tabIndex;

        tabElements.push({
          tabTitle: currentTabObj.tabTitle,
          onClick: this.params.tabs.length > 1 ? this.activateTab.bind(this, currentTabObj) : null });


        if (!this.rendered) {
          // We still need to iterate over this array to make sure we render default tab content
          var isActiveTab = !this.params.defaultTab && tabIndex === 0 ||
          this.params.defaultTab && this.params.defaultTab === currentTabObj.name;
          if (isActiveTab) {
            this.currentTab = currentTabObj;
            this.currentTabIndex = tabIndex;
          }

          this.renderTab(currentTabObj, isActiveTab);
        }
      }, this);

      Y.Object.each(errors, function (value, key) {
        var field = this.getField(key);

        if (!field) {
          if (__DEV__) {
            console.error('Server error returned for a missing dialog field: ' + key);
          }
          return;
        }

        if (field.tab) {
          tabElements[field.tab.index].error = true;
        }
      }, this);

      // Only showing tabs if we have more than one
      if (tabElements.length > 1) {
        this.tabbedHeader = ReactDOM.render(
        React.createElement(TabbedHeader, {
          defaultTabIndex: this.currentTabIndex,
          tabs: tabElements,
          tabWidth: tabWidth }),
        this.tabContainer.getDOMNode());

      }
    },

    render: function () {
      if (this.rendered) {
        return;
      }

      this.observedHeight = 0;

      this.renderNavigationTabs();

      this.renderButtons();

      if (!this.bodyHeight) {
        this.bodyHeight = this.observedHeight + 14;
        this.preferredHeight = this.bodyHeight;
      }

      switch (this.params.verticalHeight) {
        case 'fit':
          if (this.bodyHeight > this.observedHeight) {
            this.bodyHeight = this.observedHeight;
          }
          break;
        case 'fixed':
        case 'full':
          // if dialog doesn't fit within the current browser height
          var titleHeight = this.titleEl ? this.titleEl.height() : 0;
          var controlsHeight = this.controlsEl ? this.controlsEl.height() : 0;

          if (window.innerHeight < titleHeight + controlsHeight + this.bodyHeight + this.params.top) {
            // get us a bit more space by setting a thinner top margin
            this.el.setStyles({
              'top': this.params.edgeMargin + 'px' });


            this.params.top = this.params.edgeMargin;
            this.bodyHeight = window.innerHeight - this.params.top * 2 - titleHeight - controlsHeight;
          }
          break;}


      this.fire('rendered');
      this.rendered = true;

      this.updateTitle(Y.Squarespace.DialogStates.EDITING);
      this.bodyEl.setStyle('height', this.bodyHeight + 'px'); // pad bottom of observed height slightly

      this._updatePosition(true);

      if (this.params.autoFocus) {
        this.focusTab();
      }

      this.onResize();
    },

    getButtons: function () {
      return this.params.buttons;
    },

    /**
     * Set the buttons!
     *
     * @method  setButtons
     * @param {*Node|Array} Takes all args as array or first array argument.
     */
    setButtons: function () {

      var newButtons = arguments;
      // check if the first argument is an array
      if (arguments.length === 1 && Y.Lang.isArray(arguments[0])) {
        newButtons = arguments[0];
      }

      this.params.buttons = [];
      this.destroyButtons();

      newButtons.forEach(function (button) {
        this.params.buttons.push(button);
      }, this);

      if (this.isVisible()) {
        this.renderButtons();
      }

    },

    // this conflicts with the removeButton below, which should be called removeButtonEl - anon
    // lol -ja
    _removeButton: function (button) {

      var filteredButtons = Y.Array.filter(this.getButtons(), function (item, index) {
        return item !== button && item.type !== button;
      }, this);

      this.setButtons(filteredButtons);

    },

    /**
     * Helper function; each call gets an increasingly higher tab index.
     *
     * @method  _getNextTabIndex
     * @return {Number} Tab index to get.
     */
    _getNextTabIndex: function () {
      return this.BUTTONS_BASE_IDX + this.lastTabIndex++;
    },

    disableButton: function (buttonElement) {
      buttonElement.set('disabled', 'disabled');
      buttonElement.ancestor().addClass('disabled');
    },

    enableButton: function (buttonElement) {
      buttonElement.removeAttribute('disabled');
      buttonElement.ancestor().removeClass('disabled');
    },

    renderButtons: function () {
      var jsNoop = 'javascript:noop();'; // eslint-disable-line no-script-url

      this.saveAndCloseButton = Y.Node.create(Y.Lang.sub(
      '<input class="saveAndClose" tabIndex="{tabIndex}" type="button" ' +
      'data-test="dialog-saveAndClose" value="' + t("Save &amp; Close") +

      '" />', {
        tabIndex: this._getNextTabIndex() }));


      this.saveButton = Y.Node.create(Y.Lang.sub(
      '<input class="save" tabIndex="{tabIndex}" type="button" value="' + t("Save") +


      '" data-test="dialog-save"  />', {
        tabIndex: this._getNextTabIndex() }));


      // href with a jsnoop for the tabIndex
      // div's don't support tabIndex
      //
      // @todo Figure out if we need to use <a> anymore
      this.cancelButton = Y.Node.create(Y.Lang.sub(
      '<a class="cancel" href={href} tabIndex="{tabIndex}" data-test="dialog-cancel" >' + t("Cancel") +


      '</a>', {
        tabIndex: this._getNextTabIndex(),
        href: jsNoop }));


      this.removeButton = Y.Node.create(Y.Lang.sub(
      '<input class="remove" tabIndex="{tabIndex}" type="button" value="' + t("Remove") +


      '"  data-test="dialog-remove" />', {
        tabIndex: this._getNextTabIndex() }));


      this.buttonEvents.push(
      Y.on('click', function (e) {
        e.halt();

        this._getButtonClickHandler('saveAndClose', this.saveAndClose)(e);
      }, this.saveAndCloseButton, this),

      Y.on('click', this._getButtonClickHandler('save', this.save), this.saveButton),

      Y.on('click', this._getButtonClickHandler('remove', this.remove), this.removeButton),

      // ORDER MATTERS
      Y.on('click', this._getButtonClickHandler('cancel', this.markCancelLifecycle), this.cancelButton),

      Y.on('click', this._getButtonClickHandler('close', this.close), this.cancelButton),

      Y.on('click', this._getButtonClickHandler('cancel', this.cancelClick), this.cancelButton));


      var button;
      var buttons = Y.clone(this.params.buttons);
      var i = this.params.buttons.length;

      if (i > 0) {
        // reverse is in-place, which is why buttons are cloned above
        buttons.reverse();
      }

      while (--i >= 0) {
        var buttonBlockNode;
        button = buttons[i];

        if (!button) {
          continue;
        }

        switch (button.type) {

          case 'cancel':
            this.cancelButton.set('innerHTML', button.title);
            buttonBlockNode = Y.Node.create('<div class="cancel-block"></div>');
            buttonBlockNode.append(this.cancelButton);
            break;

          case 'save':
            this.saveButton.set('value', button.title);
            buttonBlockNode = Y.Node.create('<div class="button-block"></div>');
            buttonBlockNode.append(this.saveButton);
            break;

          case 'remove':
            this.removeButton.set('value', button.title);
            buttonBlockNode = Y.Node.create('<div class="button-block"></div>');
            buttonBlockNode.append(this.removeButton);
            break;

          case 'saveAndClose':
            this.saveAndCloseButton.set('value', button.title);
            buttonBlockNode = Y.Node.create('<div class="button-block"></div>');
            buttonBlockNode.append(this.saveAndCloseButton);
            break;

          default:
            var el;

            if (button.style === 'text') {

              el = '<a href="' + jsNoop + '">' + button.title + '</a>';

              buttonBlockNode = Y.Node.create('<div class="cancel-block"></div>');
              buttonBlockNode.append(el);
            } else {
              var dataTest = 'dialog-' + button.className;
              el = Y.Node.create('<input type="button" value="' + button.title + '" data-test="' + dataTest + '" />');

              if (button.className) {
                el.addClass(button.className);
              }

              buttonBlockNode = Y.Node.create('<div class="button-block"></div>');
              buttonBlockNode.append(el);
            }

            this.publish('button-' + button.type, {
              emitFacade: true,
              prefix: 'EditingDialog',
              broadcast: 2 });


            this.buttonEvents.push(
            Y.on('click', this._getButtonClickHandler(button.type), el));}



        this.buttonHolder.append(buttonBlockNode);

        if (button.disabled) {
          this.disableButton(el);
        }
      }
    },

    cancelClick: function (e) {
      this.fire('cancel-click');
    },

    /**
     * Get a button click handler for a given type.
     *
     * A handlerFn can also be passed in, as an extra handler for this
     * event.
     *
     * @method _getButtonClickhandler
     * @param {String} type The type of the button
     * @param {Function} handlerFn An extra click handler.
     * @private
     */
    _getButtonClickHandler: function (type, handlerFn) {
      return Y.bind(function (e) {
        var halted = !this.fire('button-' + type);

        if (halted) {
          e.halt();
        } else if (Y.Lang.isFunction(handlerFn)) {
          handlerFn.call(this, e);
        }

        // we fire this event as a generic button event
        this.fire('button-click', {
          type: type });

      }, this);
    },

    showSaveOverlay: function (fadeTitle) {

      if (this.params.disableSaveOverlay) {
        return;
      }

      if (this.saveOverlay) {
        this.saveOverlay.remove();
        this.saveOverlay = null;
      }

      var titleHeight = this.titleEl.get('offsetHeight');
      var bodyOffset = this.bodyEl ? this.bodyEl.get('offsetWidth') : 0;

      this.saveOverlay = Y.Node.create('<div class="save-overlay">&nbsp;</div>');
      this.saveOverlay.on('mousedown', function (e) {
        e.halt();
      });

      // height and margin are such that the title and rounded corners (top and bottom)
      // are not covered, but the content and buttons are.
      this.saveOverlay.setStyles({
        height: this.mainEl.get('offsetHeight') - titleHeight - 10 + 'px',
        marginTop: titleHeight + 5 + 'px',
        width: bodyOffset + 'px' });


      this.mainEl.append(this.saveOverlay);

      this.tabContainer.addClass('hidden');

      this.saveOverlay.addClass('visible');

    },

    hideSaveOverlay: function () {

      if (this.saveOverlay) {
        // Fade out the saveOverlay
        this.saveOverlay.addClass('hidden');

        // Fade in the tabs
        this.tabContainer.removeClass('hidden');

        Y.later(300, this, function hideSaveOverlayTimeout() {
          if (this.saveOverlay) {
            this.saveOverlay.remove();
          }
          this.saveOverlay = null;
        });

        this._setState('EDITING');
        this.updateTitle();

      } else {

        this._setState('EDITING');

      }

    },

    allowEditing: function () {

      this.hideSaveOverlay();
      this.clearEdited();

      // should fire when all the animations are finished, since thats the only time
      // this method gets called
      this.fire('editing-allowed');

      // restore editing state
      this._setState('EDITING');

    },

    save: function () {

      if (!this._showLocalErrors()) {
        this._debug.log('save');

        this.closeOnSend = false;
        this._saveData();
      }

    },

    /**
     * Defers to dialog fields to show any locally validated errors.
     *
     * @return True if any errors were shown, False otherwise.
     * @method _showLocalErrors
     * @private
     */
    _showLocalErrors: function () {
      /**
       * Returns true if a dialog is in the active frame, in a multi-frame field.
       *
       * This is kind of a hack that works around the fact that the dialog does not
       * maintain any kind of structural information related to multi-frame fields
       * so validation would otherwise run on fields that are not in the currently
       * active (see: visible) frame.
       *
       * This is defaulted to TRUE for fields that are not in a multi-frame field.
       */

      // initially hide all errors
      this.hideErrors();

      var isInActiveFrame = function (field) {
        return field.inActiveFrame;
      };

      var currentTab = this.currentTab;
      var isInCurrentTab = function (field) {
        return field.tab.name === currentTab.name;
      };

      var inCurrentTabAndActiveFrame = function (f) {
        return isInActiveFrame(f) && isInCurrentTab(f);
      };

      var getErrors = function (field) {
        return {
          field: field,
          errors: field.getErrors() };

      };

      var fields = Y.Object.values(this.fields);

      if (this.params.validateActiveTabOnly) {
        fields = fields.filter(inCurrentTabAndActiveFrame, this);
      } else {
        fields = fields.filter(isInActiveFrame, this);
      }

      var validationResults = Y.Array.map(fields, getErrors);

      var anyInvalid = false;
      var errorMap = {};

      validationResults.forEach(function (result) {

        var errors = result.errors;

        var lastError = errors[errors.length - 1];
        if (lastError) {
          errorMap[result.field.getName()] = errors[errors.length - 1];
          anyInvalid = true;
        }

      }, this);

      if (anyInvalid) {
        this.showErrors(errorMap);
      }

      return anyInvalid;
    },

    saveAndShow: function () {

      if (!this._showLocalErrors()) {
        this._debug.log('saveAndShow');

        this.closeOnSend = false;
        this._saveData();
        this.show();
      }
    },

    saveAndClose: function () {

      if (!this._showLocalErrors()) {
        // fires a preClose event to signal it's about to close
        // if any subscribers to preClose return false, we prevent saveData from executing
        // any dialog fields that have unsaved data should send datachanged
        // this is useful if a data field is throtteling it's datachange event fire
        var isSaveAllowed = this.fire('preClose');
        if (!isSaveAllowed) {
          this._debug.log('preClose returned false, save prevented');
          return;
        }

        this._debug.log('saveAndClose');

        if (this.params.closeable) {
          this.closeOnSend = true;
        }

        this._saveData();
      }

    },

    _saveData: function () {

      this._debug.log('_saveData');

      if (this._isState('SAVING')) {
        this._debug.log('_saveData', t("Exiting because dialog state is in SAVING"));


        return;
      }

      // mark the dialog as saved.
      this.clearEdited();

      // animation of dialog to save state, currently no event is fired when they are all complete
      this.hideErrors();
      this._setState('SAVING');
      this.updateTitle();
      this.showSaveOverlay();

      this._debug.log('_saveData', 'fire', 'send-requested');
      this.fire('send-requested');

    },

    remove: function (e) {

      if (e) {
        e.halt();
      }

      this.fire('remove-requested');

    },

    canClose: function (successCallback, rejectCallback, cancelCallback /* lol */) {
      if (!this._shown) {
        return false;
      }

      var allChildrenCanClose = Y.Array.every(this.childDialogs, function (dialog) {
        return dialog.canClose(this.saveAndClose, this.cancel);
      }, this);

      if (!allChildrenCanClose) {
        return false;
      }

      var reactDataEdited = this._isReactDataDirty(this);

      if (
      (reactDataEdited || this.edited || this.editedSinceLastSave) &&
      this.params.discardChangesConfirmation &&
      !this._isState('SAVING'))
      {
        // Check if we actually changed something--we just checked the react data,
        // so we don't need to do that again, but do check other fields
        var edited = reactDataEdited;

        for (var fieldName in this.fields) {

          var field = this.fields[fieldName];

          if (field.didDataChange()) {
            edited = true;
          }
        }

        if (edited) {

          var confirm = new Y.Squarespace.Widgets.Confirmation({
            render: this.el.ancestor('body') || true,
            style: Y.Squarespace.Widgets.Confirmation.TYPE.CONFIRM_OR_REJECT,
            showOverlay: true,
            'strings.confirm': t("Save"),



            'strings.reject': t("Discard"),



            'strings.title': t("Review Changes"),


            'strings.message': t("You have made changes. Do you want to save or discard them?") });




          confirm.on('confirm', function () {

            this.clearEdited();
            this._debug.log('canClose', 'calling onSuccess()', successCallback);
            successCallback.call(this);

          }, this);

          confirm.on('reject', function () {

            this.clearEdited();
            this._debug.log('canClose', 'calling onReject()', rejectCallback);
            rejectCallback.call(this);

          }, this);

          confirm.on('cancel', function () {
            // on cancel, add the target back in, cause we already used it.
            Y.Squarespace.EscManager.addTarget(this);
            this._debug.log('canClose', 'onCancel');
            if (cancelCallback) {
              cancelCallback.call(this);
            }

            // cancel the close attempt
            this.fire('cancel-close');
          }, this);


          this._debug.log('canClose', false, 'Showing confirmation dialog');

          return false;

        }

      }

      this._debug.log('canClose', true);

      return true;

    },

    /**
     * if force is true, the dialog closes without checking canClose
     * this is useful if the close action has already been confirmed previously
     *
     * @method  close
     * @param  {Event} e
     * @param  {Boolean} force
     */
    close: function (e, force) {
      this._debug.log('close', e);

      if (!this._isState('EDITING') && !this._isState('SAVING')) {
        this._debug.log('close', 'Exiting because state is in Editing or Saving.');
        this.isCanceling = false;
        return;
      }

      // check if we can close this dialog
      if (typeof force === 'boolean' && force) {
        this._debug.log('canClose skipped due to force = true');
      } else if (this.canClose(this.saveAndClose, this.cancel) === false) {
        this._debug.log('close', 'Exiting because canClose came back false');
        this.isCanceling = false;
        return;
      }

      // for the case when you click overlay and nothing has been edited
      // also on ESC key when nothign has been edited
      // -> nothing to save or promt about
      this.trackCancel();
      this.isCanceling = false;
      this.fire('close');
      this.dismiss(true);
    },

    trackCancel: function () {
      /**
       * The content-item-types/base.js#duplicate lifecycle uses
       * dialog.cancel() to stop editing the original and start editing the
       * duplicate.
       * Check the events fired during product duplication AND events/blog
       * duplication if you try to change this.
       */
      if (this.isDuplicating || !this.isCanceling) {
        return;
      }

      var item = this.currentData;
      if (!item || !item.collectionId) {
        return;
      }

      try {
        Y.Squarespace.ContentCollectionCache.getCollectionById(
        item.collectionId,
        function withCollection(collection) {
          if (!collection) {
            return;
          }

          var typeName = collection.get('typeName') || '';
          var collectionType;
          if (typeName.indexOf('blog') > -1) {
            collectionType = 'blog';
          } else if (typeName.indexOf('event') > -1) {
            collectionType = 'events';
          }
          if (collectionType) {
            cancelEditCollectionItem({
              itemId: item.id,
              collectionType: collectionType,
              collectionId: item.collectionId });

          }
        });

      } catch (error) {
        if (window.SQUARESPACE_SENTRY) {
          window.SQUARESPACE_SENTRY.captureMessage('track cancel edit collection failed');
        }
      }
    },

    /**
     * @param  {Event} e
     */
    cancel: function (e) {
      this._debug.log('cancel', e);
      if (!this._isState('EDITING') && !this._isState('SAVING')) {
        return;
      }

      this.clearEdited();
      this.fire('cancel');
      this.dismiss(false);
    },

    dismiss: function (saved) {
      var finishCancelation = saved ? Y.bind(this.destroy, this) :
      Y.bind(this._finishCancelation, this);

      this.fire('dismiss');

      this._setState('CLOSING');

      this.updateTitle();

      // to remove window unload handler
      this.clearEdited();

      // clear resources
      this._preDestroy();
      this.hideErrors();

      // restore body scroll
      if (this.params.overlay) {
        this.restoreBodyScroll();
      }

      // hide the dialog
      switch (this.params.hideAnim) {

        case 'noHideAnimation':
          finishCancelation();
          break;

        case 'custom':
          this.params.customHideAnim(this.el, finishCancelation);
          break;

        default:
          if (this.el) {
            this.el.removeClass('visible');
          }
          Y.later(500, this, finishCancelation);
          break;}


      if (this.__legacyRouterUnlisten) {
        this.__legacyRouterUnlisten();
        this.__legacyRouterUnlisten = null;
      }

      if (this.__legacyRouterUnlistenBefore) {
        this.__legacyRouterUnlistenBefore();
        this.__legacyRouterUnlistenBefore = null;
      }

      // hide the overlay
      if (this.params.overlay && this.overlayEl) {
        this.overlayEl.destroying = true;
        this.overlayEl.addClass('hidden');

        Y.later(300, this, function hideOverlayTimeout() {
          if (this.overlayEl) {
            this.overlayEl.remove();
          }
          this.overlayEl = null;
        });
      }

    },

    /**
     * The destroy part of cancel, separated out so it could be called from
     * the multiple locations in the cancel function
     *
     * @method  _finishCancelation
     */
    _finishCancelation: function finishCancellation() {
      this.fire('render-anchor', this.getInitialData());
      this.destroy();
      this._setState('CLOSED');
      this.fire('canceled');
    },

    _preDestroy: function () {

      var i = 0;
      var maxIndex;

      this._preDestroyCalled = true;

      // clear OPEN_DIALOG
      var openDialogIndex = Y.Global.OPEN_DIALOGS.indexOf(this);
      if (openDialogIndex !== -1) {
        Y.Global.OPEN_DIALOGS.splice(openDialogIndex, 1);
      }

      // dialogs open?
      if (Y.Global.OPEN_DIALOGS.length === 0) {
        Y.one(document.body).removeClass('dialog-open');
      }

      // close child dialogs
      this._applyMethodToChildren('close');

      // stop timers
      for (i = 0, maxIndex = this.timers.length; i < maxIndex; ++i) {
        this.timers[i].cancel();
      }
      this.timers = [];

      // remove esc binding
      if (Y.Squarespace.EscManager) {
        Y.Squarespace.EscManager.removeTarget(this);
      }

      // close active flyouts
      if (this.activeFlyout) {
        this.activeFlyout.field.closeFlyout();
      }

      // interact with tooltip system
      if (Y.Squarespace.ToolTipManager && this.params.disableTips) {
        Y.Squarespace.ToolTipManager.enableTooltips();
      }

      // cancel show events
      if (this.firstShowEvent) {
        this.firstShowEvent.detach();
        this.firstShowEvent = null;
      }

      // let the anchor know we're closed
      if (this.anchorEl && this.anchorEl._node) {
        this.anchorEl.removeClass('targeted');
      }

    },

    getErrors: function () {

      var errors = 0;
      var errorSet = {};

      for (var fn in this.fields) {
        var f = this.fields[fn];
        var required = false;

        if (this._isDialogField2(f)) {
          required = f.get('required') && f.isEmpty();
        } else {
          required = f.config.required && (f.getValue() === '' || f.getValue() === 0);
        }

        if (required) {
          errorSet[f.getName()] = t("This is a required field.");


          errors++;
        }
      }

      return {
        errors: errors,
        errorSet: errorSet };

    },

    send: function () {

      var errorData = this.getErrors();
      var errors = errorData.errors;
      var errorSet = errorData.errorSet;

      if (errors) {

        this.showErrors(errorSet);
        this.fire('local-errors');

      } else {

        // no local errors -- fire event
        if (this.closeOnSend) {
          this.saved = true;
          this.closeOnSend = false;
          this.close();
        }

        this.fire('sent');

      }

    },

    updateAutoSave: function (message, error) {

      if (!this.autosaveEl.inDoc()) {// verify that the node is in the document
        this.editedSinceLastSave = false;
        return;
      }

      this.autosaveEl.setStyle('display', 'block');

      var hide = this._anim({
        node: this.autosaveEl,
        from: {
          opacity: 1 },

        to: {
          opacity: 0.5 },

        duration: 0.25,
        easing: Y.Easing.easeOutStrong });


      hide.on('end', function () {

        if (message) {
          this.autosaveEl.set('innerHTML', message);
        } else {
          this.autosaveEl.set('innerHTML', t("Last saved <span class=\"time\"></span>."));


          this.autosaveEl.one('.time').plug(Y.Squarespace.RelativeTimeDisplay);
        }

        if (error) {
          this.autosaveEl.addClass('error');
        } else {
          this.autosaveEl.removeClass('error');
        }

        if (this.autosaveEl.ancestor('body')) {// verify that the node is in the document
          this._anim({
            node: this.autosaveEl,
            from: {
              opacity: 0.5 },

            to: {
              opacity: 1 },

            duration: 0.25,
            easing: Y.Easing.easeOutStrong }).
          run();
        }
      }, this);

      hide.run();

      this.editedSinceLastSave = false;

    },

    _recordFieldData: function (data, o) {

      Y.Object.each(o.fields, function (field) {

        if (Y.Lang.isFunction(field.getValue)) {

          var value = field.getValue();

          data[field.getName()] = value;

          if (field.getAssociatedVars !== undefined) {
            var vars = field.getAssociatedVars();
            for (var i = 0; i < vars.length; i++) {
              data[vars[i].name] = vars[i].value;
            }
          }
        }

        if (field.fields) {// this field has fields
          this._recordFieldData(data, field);
        }

      }, this);

    },

    _isReactDataDirty: function (context) {
      var isDirty = false;

      Y.Object.each(context.reactEls, function (reactEl) {
        var reactData = reactEl.props.getData();
        isDirty = isDirty || reactData.isDirty;
      }, this);

      return isDirty;
    },

    _recordReactData: function (data, context) {
      Y.Object.each(context.reactEls, function (reactEl) {
        var reactData = reactEl.props.getData();
        data = Y.merge(data, reactData);
      }, this);
      return data;
    },


    getData: function () {

      var data = {};

      // populate with defaults
      for (var k in this.params.initialData) {
        data[k] = this.params.initialData[k];
      }

      // overwrite with current data
      // recursively record field values
      this._recordFieldData(data, this);

      // update data with react data as well
      return this._recordReactData(data, this);
    },

    /**
     * Get a field from the dialog using the name as a key.
     *
     * @method  getField
     * @param  {string} name       Name of the field.
     * @return {Object|undefined} The field, or undefined if not found (to preserve existing functionality :-( -ja)
     */
    getField: function (name) {
      var field = this.fields[name];

      if (Y.Lang.isValue(field)) {
        return field;
      }

      for (var key in this.fields) {
        if (this.fields[key].getField) {
          field = this.fields[key].getField(name);
          if (Y.Lang.isValue(field)) {
            return field;
          }
        }
      }

      return undefined;

    },

    getSection: function (name) {

      return this.sections[name];

    },

    /**
     * Render fields take a list of fields and then renders it, yo. This is a
     * long and complicated method which just got broken up into a million peices.
     *
     * @see  _renderSpliter
     * @see  _renderSection
     * @see  _renderStack
     * @see  _renderDF2MultiFrame
     * @see  _renderField
     *
     * @method  _renderFields
     * @param  {Object} tabData        Tracks tab data
     * @param  {Array}  fields         List of fields to render
     * @param  {Node}   appendTo       Node to append to
     * @param  {Number} availableWidth Remaining width to give.
     */
    _renderFields: function (tabData, fields, appendTo, availableWidth) {

      var margin = 33;

      Y.Array.each(fields, function (fieldConfig, i) {

        var width;
        var actualWidth;

        if (!fieldConfig) {
          return;
        }

        var fieldType = fieldConfig.type;

        if (!availableWidth && !this.params.dontSetWidthOnFields) {
          availableWidth = this.params.width - margin * 2;
        }

        if (availableWidth && !isNaN(availableWidth)) {

          this._debug.log('availableWidth: ', availableWidth);
          this._debug.log('fieldConfig.width: ', fieldConfig.width);

          width = (fieldConfig.ctor && fieldConfig.config ? fieldConfig.config.width : fieldConfig.width) || 1;

          actualWidth = width <= 1 ? Math.floor(width * availableWidth) : width;
        }

        // Route the field to the appropriate handler method.
        if (fieldType === 'splitter') {

          this._renderSplitter(fieldConfig, i, fields, tabData, appendTo, availableWidth, actualWidth);

        } else if (fieldType === 'multi-frame') {

          this._renderMultiFrame(fieldConfig, i, fields, tabData, appendTo, availableWidth, actualWidth);

        } else if (fieldType === 'section') {

          this._renderSection(fieldConfig, i, fields, tabData, appendTo, availableWidth, actualWidth);

        } else if (fieldType === 'stack') {

          this._renderStack(fieldConfig, i, fields, tabData, appendTo, availableWidth, actualWidth);

        } else if (fieldConfig.ctor && fieldConfig.ctor === Y.Squarespace.DialogFields.MultiFrame) {

          this._renderDF2MultiFrame(fieldConfig, i, fields, tabData, appendTo, availableWidth, actualWidth);

        } else {

          this._renderField(fieldConfig, i, fields, tabData, appendTo, availableWidth, actualWidth);
        }

      }, this);

    },

    _renderReactComponent: function (reactData, container) {
      var router = window.CONFIG_PANEL.get('router');

      // object builder returns an instance instead of a plain object,
      // and react complains about it.
      var props = Y.merge({
        router: router,
        location: router.getCurrentLocation() },
      reactData.props);

      var reactEl = React.createElement(reactData.component, props);

      ReactDOM.render(reactEl, container);

      this.reactComponentContainers.push(container);
      this.reactEls.push(reactEl);
    },

    _renderSplitter: function (fieldConfig, i, fieldList, tabData, appendTo, availableWidth, actualWidth) {

      var useWidth;
      var margin = 33;
      var splitField = Y.Node.create('<div class="split-field clear ' + (
      fieldConfig.fields.length > 1 ? 'padding-adjust' : '') + '">' +
      '</div>');

      if (!fieldConfig.width) {
        fieldConfig.width = 1;
      }

      if (availableWidth && !isNaN(availableWidth)) {
        // add a margin unit to the overall container
        splitField.setStyle('width', Math.round(fieldConfig.width * availableWidth) + margin + 'px');

        // we don't have that margin when calculating inner fields
        useWidth = availableWidth - margin * (fieldConfig.fields.length - 1);
      } else {
        useWidth = appendTo.get('offsetWidth') - margin * (fieldConfig.fields.length - 1) - margin * 2;
      }

      appendTo.append(splitField);

      this._renderFields(tabData, fieldConfig.fields, splitField, useWidth);

      if (fieldConfig.countHeight) {
        this._takenHeight += splitField.get('offsetHeight');
      }

      if (fieldConfig.hidden) {
        splitField.hide();
      }
    },

    _renderMultiFrame: function (fieldConfig, i, fieldList, tabData, appendTo, availableWidth, actualWidth) {

      var field = new Y.Squarespace.DialogFieldGenerators['multi-frame'](
      fieldConfig, this.params.initialData, this);


      field.type = fieldConfig.type;

      field.append(tabData, fieldList, appendTo, availableWidth);

      if (field.getName()) {
        this.fields[field.getName()] = field;
      } else {
        this._noNameFields.push(field);
      }

    },

    _renderDF2MultiFrame: function (fieldConfig, i, fieldList, tabData, appendTo, availableWidth, actualWidth) {

      var field;

      var mergedFieldConfig = Y.merge(fieldConfig, fieldConfig.config, {
        dialog: this,
        data: this.params.initialData[fieldConfig.config.name],
        render: appendTo });


      field = new fieldConfig.ctor(mergedFieldConfig);

      if (availableWidth) {
        field.get('boundingBox').setStyle('width', actualWidth + 'px');
      }

      var fieldName = field.getName() || field.get('name');

      if (fieldName) {
        this.fields[fieldName] = field;
      } else {
        this._noNameFields.push(field);
      }

      field.each(function (childField) {

        var childFieldName = childField.getName() || childField.get('name');

        if (childFieldName) {
          this.fields[childFieldName] = childField;
        } else {
          this._noNameFields.push(childField);
        }

        tabData.tabFields.push(childField);
        childField.tab = tabData;
      }, this);

    },

    _renderSection: function (fieldConfig, i, fieldList, tabData, appendTo, availableWidth, actualWidth) {

      var w;
      var useWidth;

      var inner = Y.Node.create('<div class="section-inner clear"></div>');
      var sectionClasses = 'section-field container-field-wrapper field-wrapper clear ';

      if (fieldConfig.style !== undefined) {
        sectionClasses += fieldConfig.style;
      }

      var sectionField = Y.Node.create('<div class="' + sectionClasses + '"></div>');

      sectionField.append(inner);

      appendTo.append(sectionField);

      if (!fieldConfig.width) {
        fieldConfig.width = 1;
      }

      useWidth = availableWidth;
      var width = Math.round(fieldConfig.width * useWidth);

      if (width) {
        sectionField.setStyle('width', width + 'px');
      }


      w = Math.round(fieldConfig.width * useWidth);

      this._renderFields(tabData, fieldConfig.fields, inner, w);

      if (fieldConfig.hidden) {
        sectionField.hide();
      }

      if (fieldConfig.name) {
        this.sections[fieldConfig.name] = sectionField;
      }
    },

    _renderStack: function (fieldConfig, i, fieldList, tabData, appendTo, availableWidth, actualWidth) {

      var w;
      var margin = 33;

      var useWidth = availableWidth;
      if (!availableWidth) {
        useWidth = this.params.width - margin * 2;
      }

      var splitField = Y.Node.create('<div class="stack-field stack-field-wrapper clear"></div>');

      appendTo.append(splitField);

      if (!fieldConfig.width) {
        fieldConfig.width = 1;
      }

      if (fieldConfig.float) {
        splitField.setStyle('float', fieldConfig.float);
      }

      splitField.setStyle('width', Math.round(fieldConfig.width * useWidth) + 'px');

      if (i !== fieldList.length - 1) {
        splitField.setStyle('paddingRight', margin + 'px');
      }

      w = Math.round(fieldConfig.width * useWidth);

      this._renderFields(tabData, fieldConfig.fields, splitField, w);

      if (fieldConfig.hidden) {
        splitField.hide();
      }

      if (fieldConfig.name) {
        this.sections[fieldConfig.name] = splitField;
      }
    },

    /**
     * Posterity writes:
     *  Over time, support has been added for the new YUI3 Widget based dialog
     *  fields, all currently found in Y.Squarespace.DialogFields. This includes
     *  support for a more sensible field description objects in standard YUI-like
     *  format, specifying a contructor and config, etc. i.e.
     *
     *  { ctor: Y.Squarespace.DialogFields.Hidden, config: {name: 'someName'} }
     *
     *  or
     *
     *  {
     *    ctor: Y.Squarespace.DialogFields.Hidden,
     *    config: {
     *      name: 'someName',
     *      data: 'some data that might get overridden by initial data',
     *      anotherAttribute: 10,
     *      plugins: {
     *        Y.Squarespace.Animations.Fadeable
     *      }
     *      widgets: {
     *        Y.Squarespace.Widgets.Toggle
     *      }
     *    }
     *  }
     *
     * @method  _renderField
     * @private
     * @param  {Object} fieldConfig    Actual config for field.
     * @param  {Number} i              The fields cardnality.
     * @param  {Object} fieldList      List of fields
     * @param  {Object} tabData        Object containing dialog tabData
     * @param  {Node}   appendTo       Node to append to
     * @param  {Number} availableWidth How much space is left
     * @param  {Number} actualWidth    Actual width of Dialog.
     */
    _renderField: function (fieldConfig, i, fieldList, tabData, appendTo, availableWidth, actualWidth) {

      var field;
      var widgetBasedFieldConfig = Y.merge(
      // for old style field defs
      fieldConfig,
      // support for yui3 format field defs
      fieldConfig.config || {},
      // + additional attrs req. by DialogField2s
      {
        dialog: this,
        render: appendTo });



      // adding 'initial data'
      var fieldName = widgetBasedFieldConfig.name || fieldConfig.name;

      if (this._debug.isTimingEnabled()) {
        this._debug.time('render field: ' + fieldName);
      }

      if (
      Y.Lang.isUndefined(widgetBasedFieldConfig.data) &&
      this.params.initialData &&
      !Y.Lang.isUndefined(fieldName))
      {

        widgetBasedFieldConfig.data = this.params.initialData[fieldName];
        widgetBasedFieldConfig.panel = this; // need this so we can fire legacy datachange event

      }

      if (fieldConfig.ctor) {

        // field config specifies constructor, assume its standard YUI3 format
        field = new fieldConfig.ctor(widgetBasedFieldConfig);

      } else {

        // in this case is most likely a constructor misspelling or missing
        // dependency
        if (!fieldConfig.type) {
          if (__DEV__) {
            console.error('dialog: field type was', fieldConfig.type, ', and constructor was', fieldConfig.ctor);
            console.error('dialog: config was', fieldConfig);
          }
          throw new Error('Could not find field constructor or field type.');
        }

        // go on like its old style field definition
        var newClassName = this._convertToDialogField2Name(fieldConfig.type);
        // lets see if a Widget based version exists in DialogFields first
        var widgetBasedClass = Y.namespace('Squarespace.DialogFields')[newClassName];

        if (
        !widgetBasedClass &&
        !Y.Squarespace.DialogFieldGenerators[fieldConfig.type])
        {
          throw new Error('Unknown field type: ' + fieldConfig.type);
        }

        this._debug.log('Generating field type: ', fieldConfig.type);

        if (widgetBasedClass) {
          // new widget based version exists, use that one
          field = new widgetBasedClass(widgetBasedFieldConfig);

        } else {
          if (__DEV__) {
            console.log('Attempting to use DFG: ', fieldConfig.type);
          }
          // no dice, fall back to looking in DialogFieldGenerators
          var fieldCtor = Y.Squarespace.DialogFieldGenerators[fieldConfig.type];

          field = new fieldCtor(fieldConfig, this.params.initialData, this);
          field.type = fieldConfig.type;

          field.append(appendTo);

        }
      }

      if (fieldConfig.verticalSpan) {
        this._verticalEl = field;
      }

      if (fieldConfig.countHeight && !fieldConfig.verticalSpan) {
        if (field.getTakenHeight) {
          this._takenHeight += field.getTakenHeight();
        } else {
          this._takenHeight += field.get('boundingBox').get('offsetHeight');
          this._takenHeight += parseInt(field.get('boundingBox').getStyle('marginTop'), 10);
          this._takenHeight += parseInt(field.get('boundingBox').getStyle('marginBottom'), 10);
        }
      }

      // this bit only runs when it's any field inside of a splitter. hopefully. -naz
      if (availableWidth) {

        if (this._isDialogField2(field)) {

          // old style field width
          this._debug.log('setting width', actualWidth);
          field.get('boundingBox').setStyle('width', actualWidth + 'px');

        } else if (field.html) {

          // Widget based field width
          this._debug.log('setting width', actualWidth);
          field.html.setStyle('width', actualWidth + 'px');

        }
      }

      // should potentially be called field.fit
      // this will ensure the field is rendered right after dom insertion
      if (field.resize) {
        field.resize();
      }

      // if the field is hidden, hide it quickly
      if (fieldConfig.hidden) {
        field.temporaryHide(true);
      }

      // a hacky event for when fields report they are loading data
      if (this._isDialogField2(field)) {

        this.bodyEvents.push(field.on('dataStateChange', function (e) {
          var dataState = e.newVal;

          this.fire('field-loading-change', {
            field: field,
            loading: dataState === dataState.LOADING });

        }, this));

      } else {

        this.bodyEvents.push(field.on('loadingChange', function (e) {
          this.fire('field-loading-change', {
            field: field,
            loading: e.newVal });

        }, this));

      }

      if (field.getName()) {
        this.fields[field.getName()] = field;
      } else {
        this._noNameFields.push(field);
      }

      tabData.tabFields.push(field);

      field.tab = tabData;

      if (this._debug.isTimingEnabled()) {
        this._debug.timeEnd(t("render field: {fieldName}", {
          fieldName: fieldName }));



      }

    },

    /**
     * Returns true if a DialogField2, false otherwise.
     *
     * @method  _isDialogField2
     * @param  {Object}  field  A Dialog field.
     * @return {Boolean}        True, if it is an instance of DF2, false otherwise.
     */
    _isDialogField2: function (field) {
      return (
        Y.Lang.isValue(field) &&
        Y.Lang.isValue(Y.Squarespace.DialogField2) &&
        field instanceof Y.Squarespace.DialogField2);

    },

    /**
     * Set the state of the dialog via a key.
     *
     * @param {String} state  name of the state to set, ie "LOADING"
     */
    _setState: function (state) {
      if (Y.Lang.isValue(Y.Squarespace.DialogStates[state])) {
        this.state = Y.Squarespace.DialogStates[state];
      } else {
        console.warn('[Dialog] Invalid state.');
      }
    },

    /**
     * Compare the string to the current state of the dialog.
     *
     * @param  {Number|String}  state  A number if you used the enum, string for enum key
     * @return {Boolean}               Whether or not the state matches.
     */
    _isState: function (state) {

      if (Y.Lang.isString(state)) {
        return this.state === Y.Squarespace.DialogStates[state];
      }

      return this.state === state;
    },

    resizeVerticalFields: function () {

      Y.Array.each(this.verticalFields, function (field, i) {
        var height = this.getBodyHeight() - field._takenHeight;

        if (this._isDialogField2(field)) {
          height -= parseInt(field.get('boundingBox').getStyle('marginTop'), 10);
          height -= parseInt(field.get('boundingBox').getStyle('marginBottom'), 10);

          field.set('height', height);
        } else {
          field.setHeight(height);
        }
      }, this);
    },

    renderTab: function (data, isActiveTab) {
      if (!this.bodyEl) {
        return;
      }

      var tabWrapper = Y.Node.create('<div class="tab-wrapper"></div>');

      if (Y.Lang.isValue(data.name)) {
        tabWrapper.addClass('dialog-tab-' + data.name);
      }

      tabWrapper.setStyles({
        'width': this.params.width + 'px',
        'left': isActiveTab ? '0px' : this.params.width + 'px' });


      // speed tradeoff -- we do this here so we can measure elements and properly calculate vertical span height
      this.bodyEl.append(tabWrapper);

      data.tabFields = [];
      // this is some straight up fdml right here. The vertical el is set in another method conditionally. ugh.
      this._verticalEl = null;
      this._takenHeight = 0; // HACK -- create a little extra space at the bottom
      // when vertical spanning
      // THIS IS ONLY IN PLACE TO DEAL WITH VSPAN ON WYSIWYG FIELDS

      this._renderFields(data, data.fields || [], tabWrapper);
      if (isActiveTab && data.reactData) {// only render if tab's active
        this._renderReactComponent(data.reactData, tabWrapper.getDOMNode());
      }

      if (data.noTabTitle) {
        tabWrapper.addClass('noTabTitle');
      }

      if (this._verticalEl) {

        var topMargin = parseInt(tabWrapper.getStyle('paddingTop'), 10); // wat. (legacy...) -ja

        if (!isNaN(parseInt(tabWrapper.getStyle('marginTop'), 10))) {
          topMargin += parseInt(tabWrapper.getStyle('marginTop'), 10); // slightly less wat. -ja
        }

        this._takenHeight += topMargin; // top of the tab

        var isDF2 = this._isDialogField2(this._verticalEl);

        if (!isDF2 && !this._verticalEl.setHeight && __DEV__) {
          console.error('No setHeight for vertical el: ', this._verticalEl);
        }

        var verticalHeight = this.getBodyHeight() - this._takenHeight;
        if (isDF2) {
          var verticalBB = this._verticalEl.get('boundingBox');
          verticalHeight -= parseInt(verticalBB.getStyle('marginTop'), 10);
          verticalHeight -= parseInt(verticalBB.getStyle('marginBottom'), 10);
          this._verticalEl.set('height', verticalHeight);
        } else {
          this._verticalEl.setHeight(verticalHeight);
          // if you are still using a DF1 you are better off spending your time
          // turning the DF1 into a Dialogfield2 than trying to fix this. -jake
        }

        this._verticalEl._takenHeight = this._takenHeight;

        this.verticalFields.push(this._verticalEl);
      }

      data.tabPanelObj = tabWrapper;

      var tabContentHeight = tabWrapper.get('offsetHeight');

      // get the tallest tab height
      if (!this.observedHeight || tabContentHeight > this.observedHeight) {
        this.observedHeight = tabContentHeight;
      }

      // show all fields in this tab
      if (isActiveTab && data.tabFields) {
        Y.Array.forEach(data.tabFields, function (field) {
          this._showField(field);
        }, this);
      }

      // scrollable?
      this.bodyEl.toggleClass('scrollable', !!data.scroll);

      if (!isActiveTab) {
        tabWrapper.addClass('hidden');
      }

      this.updateTitle();
      return tabWrapper;
    },

    /**
    * Converts a dialog field 'type' to the class name of a dialogField2 class
    * @method _convertToDialogField2Name
    * @private
    * @param oldTypeName {String} The old dialog field type string
    * @return {String} The class name of the corresponding DialogField2 in
    *   Y.Squarespace.DialogFields
    **/
    _convertToDialogField2Name: function (oldTypeName) {

      var words = createUrlSafeString(oldTypeName).split('-');

      for (var i = words.length - 1; i >= 0; i--) {
        if (words[i].capitalize) {
          words[i] = words[i].capitalize();
        }
      }

      return words.join('') + 'Field';
    },

    _destroyFields: function () {

      if (this.fields) {

        Y.Object.each(this.fields, function (field, key) {
          field.destroy();
        }, this);

        this._destroyNoNameFields();
      }

      this.fields = {};
    },

    _destroyReactComponents: function () {
      Y.Object.each(this.reactEls, function (reactEl) {
        if (reactEl.props.onDestroy) {
          reactEl.props.onDestroy();
        }
      }, this);

      if (this.reactComponentContainers.length) {
        Y.Object.each(this.reactComponentContainers, ReactDOM.unmountComponentAtNode);
      }

      this.reactComponents = [];
    },

    _destroyNoNameFields: function () {

      Y.Array.each(this._noNameFields, function (field) {
        if (field) {
          field.destroy();
        }
      }, this);

      this._noNameFields = [];
    },

    destroyBody: function () {

      this.rendered = false;

      // destroy fields
      this._destroyFields();

      // destroy react components
      this._destroyReactComponents();

      if (this.bodyEl && this.bodyEl._node) {
        this.bodyEl.empty();
      }

      // remove tab structures
      Y.Array.each(this.params.tabs, function (tab) {
        tab.tabPanelObj = null;
        tab.tabFields = null;
      });

      // remove events
      this._detachEventArray(this.bodyEvents);

      this.bodyEvents = [];

    },

    _detachEventArray: function (arr) {
      Y.Array.each(arr, function (eventObject) {
        eventObject.detach();
      });
    },

    destroyButtons: function () {

      this._detachEventArray(this.buttonEvents);

      this.buttonEvents = [];

      if (this.buttonHolder && this.buttonHolder._node) {
        this.buttonHolder.set('innerHTML', '');
      }

    },

    _destroy: function () {

      if (!this.preDestroyCalled) {
        this._preDestroy();
      }

      this.destroyTimer = null;
      this.currentErrors = null;
      this.bodyHeight = null;

      this.destroyBody();
      this.destroyButtons();

      if (this.overlayEl && !this.overlayEl.destroying) {

        // overlays manage their own destruction -- just ensure one is underway

        this.overlayEl.remove();
        this.overlayEl = null;

      }

      if (this._onBeforeUnloadEvt) {
        this._onBeforeUnloadEvt.detach();
      }

      this._detachEventArray(this.globalEvents);

      if (this.dd) {
        this.dd.destroy();
        this.dd = null;
      }

      this.globalEvents = [];

      if (this.el) {
        this.el.remove(true);
        this.el = null;
        this.bodyEl = null;
      }

      if (this.params.parentDialog) {
        this.params.parentDialog.removeChildDialog(this);
        this.params.parentDialog = null;
      }

      this._setState('CLOSED');

      this.position = null;
      this.anchorEl = null;

      if (this.__legacyRouterUnlisten) {
        this.__legacyRouterUnlisten();
        this.__legacyRouterUnlisten = null;
      }

      if (this.__legacyRouterUnlistenBefore) {
        this.__legacyRouterUnlistenBefore();
        this.__legacyRouterUnlistenBefore = null;
      }

      if (this.__legacyRouterUnload) {
        this.__legacyRouterUnload();
        this.__legacyRouterUnload = null;
      }

    } });




}, '1.0', { requires: [
  'anim',
  'attribute',
  'datatype-date',
  'dd',
  'json',
  'node',
  'node-event-simulate',
  'node-focusmanager',
  'squarespace-debugger',
  'squarespace-dialog-field-2',
  'squarespace-dialog-fields',
  'squarespace-dialog-legacy-multi-frame',
  'squarespace-escmanager',
  'squarespace-gizmo',
  'squarespace-plugin-scroll-lock',
  'squarespace-ui-base',
  'squarespace-util',
  'squarespace-widgets-confirmation',
  'transition'] });

require('../../styles-compressed/legacy/dialog.css');