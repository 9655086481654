/**
  Home of the new, widget based dialog field, subclass
  @module squarespace-dialog-field-2
*/
YUI.add('squarespace-dialog-field-2', function(Y) {

  var ERROR_CLASS = 'error';

  /**
    The new widget-based dialog field base class that defines standard behaviors
    for all new, SSWidget-based dialog fields. Fields based off of this subclass
    should all be able to function independently, outside of a dialog.

    Checklist for writing a new field:
      * Set the CSS_PREFIX static property to the format 'sqs-your-class-name'
      * Define didDataChange() if your field data is an object
      * Set the ASYNC_DATA static property to true if your field loads its own
        data
      * Set the readOnly {{#crossLink "DialogField2/errorFlyoutAnchor:attribute}}{{/croddLink}}
        attribute at render time if the flyout anchor should not be the contentBox
      * Define _getErrors() (returns an array of string errors) if your field
        can have local validation errors it should display to the user
      * define the `data` attribute containing data
      * override {{#crossLink "DialogField2/isEmpty:method"}}{{/crossLink}} if applicable

    @class DialogField2
    @namespace Squarespace
    @constructor
    @extends Squarespace.Widgets.DataWidget
    @uses DialogFieldLegacyInterface
    @uses WidgetParent
    @uses WidgetChild
    @uses Plugin.WidgetAnim
  */
  Y.namespace('Squarespace.Widgets').DialogField2 = Y.namespace('Squarespace').DialogField2 =
  Y.Base.create('dialogField', Y.Squarespace.Widgets.DataWidget,
    [
      Y.Squarespace.DialogFieldLegacyInterface,
      Y.WidgetParent,
      Y.WidgetChild
    ],
    {
      initializer: function(config) {
        // Stores an untethered copy of the widget's data at the time of
        // initialization (initialData, if you will)
        this._saveInitialData(config ? config.data : null);

        this.publish('error-shown', {
          broadcast: 2
        });

        /**
         * Fires after an element is added or removed from the content item grid
         *
         * @event edited
         */
        this.publish('edited');
      },

      /**
        Destroy lifecycle stuff
        @method destructor
        @private
      */
      destructor: function() {

        this._destroyError();
        this.unplug(Y.Plugin.WidgetAnim);
        this._initialData = undefined;

        if (this._errorFlyoutSub) {
          this._errorFlyoutSub.detach();
        }

      },

      _destroyError: function() {
        if (this._errorNode) {
          this._errorNode.remove(true);
          this._errorNode = null;
        }
      },

      /**
        Saves the widgets current data as its initial data.
        @method _saveInitialData
        @private
      */
      _saveInitialData: function(data) {
        data = !Y.Lang.isValue(data) ? this.get('data') : data;
        if (Y.Lang.isArray(data) && this.get('cloneInitialData')) {
          this._initialData = data.slice();
        } else if (Y.Lang.isObject(data) && this.get('cloneInitialData')) {
          this._initialData = Y.clone(data, true);
        } else {
          this._initialData = data;
        }
      },

      /**
        Takes the current value of this widget's 'data' attribute and saves it
        as initial data to compare against in the didDataChange method
        @method setCurrentDataAsInitial
      */
      setCurrentDataAsInitial: function() {
        this._saveInitialData();
      },

      /**
        This method is responsible for creating and adding the nodes which
        the widget needs into the document (or modifying existing nodes,
        in the case of progressive enhancement). It is usually the point at
        which the DOM is first modified by the widget.
        @method renderUI
      */
      renderUI: function() {
        Y.Squarespace.DialogField2.superclass.renderUI.call(this);
        var boundingBox = this.get('boundingBox');
        var name = this.get('name');
        var testAttribute = this.get('testAttribute');

        if (name) {
          boundingBox.addClass('name-' + name);
          boundingBox.setAttribute('data-testValue', name);
        }

        if (testAttribute) {
          boundingBox.setAttribute('data-test', testAttribute);
        }
      },

      /**
        Scrolls this field into view. Generally used for when validation
        on long dialogs requires you to scroll to the offending field.
        @method scrollIntoView
      */
      scrollIntoView: function() {
        this.get('contentBox').scrollIntoView();
      },

      toggleError: function(message) {
        var boundingBox = this.get('boundingBox');

        if (this._errorHoverAnchor) {
          boundingBox.removeClass('has-error');

          this._errorHoverAnchor.remove();
          this._errorHoverAnchor.destroy(true);
          this._errorHoverEvent.detach();
          this._errorHoverEvent = null;
          this._errorHoverAnchor = null;
        }

        if (message) {
          boundingBox.addClass('has-error');

          this._errorHoverAnchor = Y.Node.create('<div class="error-hover">&#33;</div>');
          this._errorHoverAnchor.appendTo(boundingBox);
          this._errorHoverEvent = this._errorHoverAnchor.on('mouseenter', function() {
            boundingBox.addClass('error-is-hovering');
            this.showError(message);
          }, this);
        } else {
          this.hideError();
        }
      },

      /**
        If a YUI node is set as this.control, this method shows an error message by that
        control, sliding and color changing included. Useful for form validation errors.
        @method showError
        @param message {String} The error message to show by the field
      */
      showError: function (message) {

        if (!message || !this.get('rendered')) {
          return;
        }

        var boundingBox = this.get('boundingBox');
        var contentBox = this.get('contentBox');
        var errorFlyoutAnchor = this.get('errorFlyoutAnchor') || contentBox;
        var flyoutAnimationSpeed = this.get('errorFlyoutAnimationTime');

        // plug in the flyout functionality if its not there already
        if (!errorFlyoutAnchor.hasPlugin('flyoutPlugin')) {
          errorFlyoutAnchor.plug(Y.Squarespace.Animations.Flyout, {
            duration: flyoutAnimationSpeed,
            renderTarget: this.get('errorFlyoutRenderTarget')
          });
        }

        var flyout = errorFlyoutAnchor.flyoutPlugin;

        var doShowErrorFlyout = Y.bind(function() {

          boundingBox.addClass(ERROR_CLASS);

          this._destroyError(); // clear the shit.

          this._errorNode = Y.Node.create('<div class="sqs-flyout-error-message">' + message + '</div>');

          var dialog = this.get('dialog');
          if (dialog) {
            this._errorNode.setStyle('zIndex', dialog.zIndex + 10);
          }

          // trap clicks and hide on click
          this._errorNode.on(this.get('id') + '|click', function(e) {
            e.halt();
            this.hideError();
          }, this);

          var windowRegion = Y.one(window).get('region');
          var flyoutWidth = Y.Squarespace.NodeUtils.measureNode(this._errorNode).width;
          var anchorRegion = errorFlyoutAnchor.get('region');

          var alignment;
          if (anchorRegion.right + flyoutWidth <= windowRegion.right) {
            alignment = 'rt';
            this._errorNode.addClass('out-from-right');
          } else if (anchorRegion.left - flyoutWidth > windowRegion.left) {
            alignment = 'lt';
            this._errorNode.addClass('out-from-left');
          } else {
            alignment = 'bl';
            this._errorNode.addClass('out-from-bottom');
            this._errorNode.setStyle('width', anchorRegion.width);
          }

          flyout.setAttrs({
            node: this._errorNode,
            alignment: alignment
          });

          // hide the error when clicking somewhere else
          this._clearErrorSub = Y.on(this.get('id') + '|click', this.hideError, this);

          var id = this.get('id');
          flyout.once(id + '|shown', function(e) {
            /**
              Fires when the error is hidden
              @event error-shown
            */
            this.fire('error-shown', { message: message });
          }, this);

          // hack around webkit bug where animation over width does not trigger
          // redraw, cause the error message to wrapp and not all is shown.
          this._errorFlyoutSub = errorFlyoutAnchor.once('flyout-shown', function(e) {
            this._errorNode.setStyle('width', '100%');
          }, this);

          flyout.show();

        }, this);

        if (flyout.get('visible')) {
          this._isHiding = true;
          // flyout showing, hide it and replace it with a new one when its done hiding
          if (this._showErrorSub) { this._showErrorSub.detach(); }
          this._showErrorSub = flyout.once(this.get('id') + '|hidden', function() {
            this._showErrorSub = null;
            this._isHiding = false;
            doShowErrorFlyout();
          }, this);

          flyout.hide();
        } else {
          doShowErrorFlyout();
        }
      },

      /**
        Remove's a field error put on screen by showError
        This just updates the ui, use this.clearError to clear an error
        from the field and update the ui at once
        @method hideError
        @private
      */
      hideError: function() {

        if (!this.get('rendered') || this._isHiding ||
          !this.get('boundingBox').getDOMNode()) {
          return;
        }

        var boundingBox = this.get('boundingBox');

        if (boundingBox) {
          boundingBox.removeClass('error-is-hovering');
        }

        boundingBox = this.get('boundingBox');
        var contentBox = this.get('contentBox');
        var errorFlyoutAnchor = this.get('errorFlyoutAnchor') || contentBox;

        if (
          !errorFlyoutAnchor.hasPlugin('flyoutPlugin') ||
          !errorFlyoutAnchor.flyoutPlugin.get('visible')
        ) {
          // no error showing
          return;
        }

        if (this._clearErrorSub) {
          this._clearErrorSub.detach();
          this._clearErrorSub = null;
        }

        var flyout = errorFlyoutAnchor.flyoutPlugin;

        flyout.once(this.get('id') + '|hidden', function(e) {
          boundingBox.removeClass(ERROR_CLASS);
          /**
            Fires when the error is hidden
            @event error-hidden
          */
          this.fire('error-hidden');

          this._destroyError(); // also clear that shit.
        }, this);

        flyout.hide();
      },

      /**
        Tells you if the field has changed based on a comparison to the initial data
        passed into the field when it was initialized
        @method didDataChange
        @return {Boolean} Whether the current data is different than the initial data
      */
      didDataChange: function() {

        var data = this.get('data');

        if (this.get('readOnly')) {
          return false;
        }

        // array compare
        if (Y.Lang.isArray(this._initialData)) {
          return Y.JSON.stringify(this._initialData) !== Y.JSON.stringify(data);
        }

        // object compare, won't do it
        if (Y.Lang.isObject(this._initialData)) {
          throw new Error('DialogField base class will not compare objects. Define didDataChange for this field.');
        }

        // everything else
        return this._initialData !== data;
      },

      /**
       * @method isEmpty
       * @return {Boolean}
       */
      isEmpty: function() {
        var data = this.get('data');

        return data === '' || data === 0; // pulled from original dialog.js logic. this should be looked over again
      },

      /**
        Sets the data in this field back to the initial data
        @method revert
      */
      revert: function() {
        this.set('data', this._initialData, { revert: true });
      },

      // custom getters and setters
      /**
        No errors by default, implement this in your class if it is possible to
        have errors. Your validator functions would be called from this method,
        and would each add a message to the array of errors if the data did not
        validate.
        @method _getErrors
        @private
      */
      _getErrors: function() {
        return [];
      },

      /**
       * convenience
       * @method isValid
       * @return {Boolean}
       */
      isValid: function() {
        return !Y.Lang.isArray(this.get('errors')) || this.get('errors').length === 0;
      }

    },

    // Static properties
    {
      CSS_PREFIX: 'sqs-dialog-field',

      ATTRS: {

        /**
          @attribute cloneInitialData
          @description By default, the initial data gets cloned.
          @type Boolean
          @default true
        */
        cloneInitialData: {
          value: true
        },

        /**
          @attribute strings
          @description All the copy used in the interface for this dialog field
          @type Object
          @default SEE SUB ATTRIBUTES
        */
        strings: {},

        /**
          @attribute name
          @description The name given to this field by the field config
            passed to the dialog
          @type String
          @default
        */
        name: {
          value: null,
          validator: Y.Squarespace.AttrValidators.isNullOrString
        },

        /**
          @attribute dialog
          @description The parent dialog for this field, if it has one
          @type Object
          @default null
        */
        dialog: {
          value: null,
          validator: function(val) {
            if (Y.Lang.isNull(val)) {
              return true;
            }

            // bizarre stuff happens in our Class.extend
            if (
              !(val instanceof Y.Squarespace.EditingDialog ||
              val.constructor instanceof Y.Squarespace.EditingDialog.constructor)
            ) {
              if (__DEV__) {
                console.warn(this.name + ': Not an EditingDialog');
              }
              return false;
            }
            return true;

          }
        },


        /**
         @attribute readOnly
         @description Whether or not this is a read only field.
         @type Boolean
         @default false
         */
        readOnly: {
          value: false,
          validator: Y.Squarespace.AttrValidators.isBoolean
        },

        /**
          @attribute required
          @description Whether having data in this field is required for the parent
            form (if any) to submit
          @type Boolean
          @default false
        */
        required: {
          value: false,
          validator: Y.Squarespace.AttrValidators.isBoolean
        },

        /**
          @attribute errors
          @description An array of error messages belonging to the data in this
            field.
          @type [String]
          @default []
        */
        errors: {
          value: [],
          readOnly: true,
          getter: '_getErrors',
          validator: Y.Squarespace.AttrValidators.isArray
        },

        /**
          @attribute errorFlyoutAnchor
          @description The node that the error flyout should anchor itself to and
            fly out of.
          @type Node
          @default null
        */
        errorFlyoutAnchor: {
          value: null,
          readOnly: true,
          validator: Y.Squarespace.AttrValidators.isNullOrInstanceOf(Y.Node)
        },

        /**
         * @description The node that the flyout should be rendered onto. By
         * default undefined, which tells nodeFlyout to use the body node.
         * @attribute errorFlyoutRenderTarget
         * @default body node
         * @type {Object}
         */
        errorFlyoutRenderTarget: {
          // By default, nodeFlyout uses the body as the target,  change this
          // when you need to do weird node interleaving to get things working.
          value: undefined
        },

        /**
         * If you ever have to use this I am so sorry.
         * @type {Number}
         * @default 0.3
         */
        errorFlyoutAnimationTime: {
          value: 0.3
        },

        /*
          @attribute focusable
          @description Whether or not the field should be focused on in the
                       for input. This exists because _all_ widgets have a focus
                       method which gives a focus class to the widgets boundingBox.
                       However, in dialog.js, the focus method is intended to mean
                       that this is a valid target to auto-focusing.
          @default true
         */
        focusable: {
          value: true,
          validator: Y.Lang.isBoolean
        },

        /**
         * Value to pass to data-test attribute to identify node from selenium
         * @attribute testAttribute
         */
        testAttribute: {
          value: null,
          validator: Y.Squarespace.AttrValidators.isNullOrString
        }
      } /// end ATTRS
    } /// end static properties
  ); /// end base.create

}, '1.0', { requires: [
  'base',
  'json-stringify',
  'squarespace-animations',
  'squarespace-attr-validators',
  'squarespace-dialog-field-legacy-interface',
  'squarespace-flyout-error-message-template',
  'squarespace-node-flyout',
  'squarespace-node-utils',
  'squarespace-widgets-data-widget',
  'widget-anim',
  'widget-child',
  'widget-parent'
] });
