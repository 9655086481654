let router;

export default {
  onChange(...args) {
    if (!router) {
      return;
    }

    return router.onChange(...args);
  },

  onBeforeChange(callback) {
    if (!router) {
      return;
    }

    return router.onBeforeChange(callback);
  },

  onBeforeUnload(callback) {
    if (!router) {
      return;
    }

    return router.onBeforeUnload(callback);
  },

  setRouter(r) {
    router = r;
  }
};
