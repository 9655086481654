import isString from 'lodash/isString';
import TemplateVersionUtils from 'shared/utils/TemplateVersionUtils';
import EventTrackerBase from '../events/TrackEventsBase';
import type Router from '@sqs/universal-router';
import { PRODUCT, ACTOR, ACTION } from './constants';
import { getEventMetaFromReduxMirror } from 'shared/utils/CMSV7Events/EventMetaReduxMirror';

const DESIGN_PATH = '/config/design';
const COLORS_V3_PRODUCT_DESIGN_IDENTIFIER = 'color-themes-v3';

interface TrackProps {
  selected_template_identifier?: string;
  [field: string]: string|number|boolean|undefined;
}

/**
 * CMSV7Events tracking class
 *
 * This class is the interface all tracking methods use to interact with the @sqs/track-events
 * library. All tracking methods live in other files in the `shared/utils/CMSV7Events` directory.
 * These methods use the `.track()` method of the singleton of this class exported by
 * `shared/utils/CMSV7Events/index.ts`
 *
 * ### DO NOT ADD INDIVIDUAL TRACKING METHODS TO THIS CLASS
 *
 * Adding individual tracking methods bloats bundle sizes, as the entire class code is included
 * wherever CMSV7Events gets imported.
 */
class CMSV7Events extends EventTrackerBase {
  private frameUrl?: string;

  constructor() {
    super({
      customSchemaName: 'cms_v7',
      eventOwnerTeam: 'cms',
    });
  }

  assignTest(flagName: string) {
    this.track({
      actor: 'system',
      action: 'assign',
      object_type: 'ab_test',
      object_identifier: flagName
    });
  }

  registerRouter(router: Router) {
    this.frameUrl = router.getCurrentLocation().state.frameUrl;

    router.onBeforeChange(() => {
      this.frameUrl = router.getCurrentLocation().state.frameUrl;
    });
  }

  track(props: TrackProps) {
    return super.track({
      ...props,
      ...(this.getAdditionalProperties(props)),
      frame_url: this.frameUrl,
      effective_connection_speed: navigator?.connection?.effectiveType,
      selected_template_identifier: props?.selected_template_identifier ? props.selected_template_identifier :
        window.Static?.SQUARESPACE_CONTEXT?.templateInstallation?.templateWebsite?.identifier,
      device_orientation: window.matchMedia('(orientation: landscape)').matches ?
        'landscape' :
        'portrait'
    });
  }

  /**
   * Gets additional proerties for Design Panel
   *
   * @TODO DP-3584: move this logic to live with Design Panel events
   */
  getAdditionalProperties(props: TrackProps) {
    const additionalProps: Record<string, string> = getEventMetaFromReduxMirror();

    if (!TemplateVersionUtils.isSevenOne()) {
      return additionalProps;
    }

    if (
      props.product_area === PRODUCT.AREA_DESIGN_EDITOR ||
      (
        props.actor === ACTOR.USER &&
        props.action === ACTION.VIEW &&
        isString(props.destination_url) &&
        props.destination_url.indexOf(DESIGN_PATH) === 0
      )
    ) {
      additionalProps.product_design_identifier = COLORS_V3_PRODUCT_DESIGN_IDENTIFIER;
    }

    return additionalProps;
  }
}

export default new CMSV7Events();
