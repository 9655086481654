/*
 * This file was generated by the `create-manifests` gulp task.
 * Run `npm run gulp create-manifests` to regenerate this file.
 */
import 'src/main/webapp/universal/scripts-v6/dialog/dialog.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/datatype-date-parse/datatype-date-parse.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/datatype-date-format/datatype-date-format.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/datatype-date-math/datatype-date-math.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/dd-ddm-base/dd-ddm-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/dd-ddm/dd-ddm.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/dd-ddm-drop/dd-ddm-drop.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/dd-drag/dd-drag.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/dd-proxy/dd-proxy.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/dd-constrain/dd-constrain.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/dd-drop/dd-drop.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/dd-scroll/dd-scroll.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/dd-delegate/dd-delegate.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/dd-drop-plugin/dd-drop-plugin.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/node-focusmanager/node-focusmanager.js';
import 'src/main/webapp/universal/scripts-v6/debugger.js';
import 'src/main/webapp/universal/scripts-v6/dialog/dialog-field-2.js';
import 'src/main/webapp/universal/scripts-v6/dialog/dialog-field-legacy-interface.js';
import 'src/main/webapp/templates-v6/handlebars/flyout-error-message.html';
import 'src/main/webapp/universal/node_modules/@sqs/yui/handlebars-base/handlebars-base.js';
import 'src/main/webapp/universal/scripts-v6/node-flyout.js';
import 'src/main/webapp/universal/scripts-v6/node-utils.js';
import 'src/main/webapp/universal/scripts-v6/widgets/data-widget.js';
import 'src/main/webapp/universal/scripts-v6/ss-widget.js';
import 'src/main/webapp/universal/scripts-v6/template-helpers.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/substitute/substitute.js';
import 'src/main/webapp/universal/scripts-v6/ui-templates.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/widget-anim/widget-anim.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/widget-child/widget-child.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/widget-parent/widget-parent.js';
import 'src/main/webapp/universal/scripts-v6/dialog/dialog-fields.js';
import 'src/main/webapp/universal/scripts-v6/gizmo.js';
import 'src/main/webapp/universal/scripts-v6/dialog/legacy/multi-frame.js';