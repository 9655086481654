/* eslint-disable */
// Generated from seed-templates/handlebars-module-template.js
YUI.add('squarespace-flyout-error-message-template', function(Y) {
  var Handlebars = Y.Handlebars;

  (function() { var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {}; templates["flyout-error-message.html"] = template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, functionType="function";


  buffer += "<div class=\"sqs-flyout-error-message\">";
  if (stack1 = helpers.message) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.message; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</div>";
  return buffer;
  });})()

  var filename = 'flyout-error-message.html';
  Y.Handlebars.registerPartial(filename.replace('/', '.'), Handlebars.templates[filename]);

}, '1.0', {
  requires: [
    "handlebars-base"
  ]
});
